import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import {
  IPatientsSelectTableProps,
  PatientsSelectTable
} from 'src/components/Patients/PatientsSelectTable';

interface AttachPatientDialogueParams {
  open: boolean;
  handleClose(): void;
  tableProps: IPatientsSelectTableProps;
}

const AttachPatientDialogue = (props: AttachPatientDialogueParams) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title">Adjuntar Nuevo Paciente</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Debes crear un paciente antes de agregarlo aquí.
        </DialogContentText>
        <PatientsSelectTable
          previousPatients={props.tableProps.previousPatients}
          addingPatients={props.tableProps.addingPatients}
          onAddPatients={props.tableProps.onAddPatients}
          finishedCallback={() => {
            props.handleClose();
            props.tableProps.finishedCallback &&
              props.tableProps.finishedCallback();
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AttachPatientDialogue;
