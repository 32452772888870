import firebase from 'firebase';
import { useState } from 'react';
import { useFirebaseApp } from 'reactfire';
import { v4 as uuidv4 } from 'uuid';
import { useUploadResultsMutation } from 'src/generated/graphql';

export const useStoreResults = () => {
  const [submitPatientResults] = useUploadResultsMutation({
    refetchQueries: ['fetchIndividualPatient', 'fetchIndividualAppointment']
  });
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<firebase.storage.FirebaseStorageError>();
  const [urls, setUrls] = useState<string[]>([]);
  const [uploading, setUploading] = useState(false);

  const projectStorage = useFirebaseApp().storage();

  const uploadFiles = (
    files: File[],
    patient_id: string,
    appointment_id: string
  ) => {
    const uuid = uuidv4();
    setUploading(true);
    for (let file of files) {
      // storage ref
      const storageRef = projectStorage
        .ref()
        .child(`results/${uuid}/${file.name}`);

      storageRef.put(file).on(
        'state_changed',
        snap => {
          // track the upload progress
          let percentage = Math.round(
            (snap.bytesTransferred / snap.totalBytes) * 100
          );
          setProgress(percentage);
        },
        err => {
          setError(err);
        },
        async () => {
          // get the public download img url
          const downloadUrl: string = await storageRef.getDownloadURL();
          submitPatientResults({
            variables: {
              results: {
                appointment_id: appointment_id,
                patient_id: patient_id,
                file_url: downloadUrl
              }
            }
          });
          // save the url to local state
          let tempUrlArray = urls;
          tempUrlArray.push(downloadUrl);
          setUrls(tempUrlArray);
        }
      );
    }
    setUploading(false);
  };

  return { progress, urls, error, uploadFiles, uploading };
};
