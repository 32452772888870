import React from 'react';
import { Container, Box } from '@material-ui/core';

import Page from 'src/components/Page';
import { makeStyles } from '@material-ui/styles';
import { BulkUploadPatientsComponent } from 'src/components/Patients/BulkUploadPatients';

const useStyles = makeStyles({
  root: {}
});

export const PatientBulkUploadView = () => {
  /* ------------------------------ General Hooks ----------------------------- */

  const classes = useStyles();

  /* -------------------------------------------------------------------------- */

  return (
    <Page className={classes.root} title={`Subida Masiva Pacientes`}>
      <Container maxWidth={false}>
        <Box mt={3}>
          <BulkUploadPatientsComponent />
        </Box>
      </Container>
    </Page>
  );
};
