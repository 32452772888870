import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Appointment = {
  __typename?: 'Appointment';
  id: Scalars['ID'];
  deleted: Scalars['Boolean'];
  datetime: Scalars['DateTime'];
  created_at?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  comuna?: Maybe<Scalars['String']>;
  amount_paid?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['String']>;
  mercadopago_url?: Maybe<Scalars['String']>;
  patients: Array<Maybe<Patient>>;
  results: Array<Maybe<Result>>;
  facturas: Array<Factura>;
  staff?: Maybe<User>;
  status?: Maybe<AppointmentStatus>;
  payment_status?: Maybe<PaymentStatus>;
  number_of_people?: Maybe<Scalars['Int']>;
  zoho_id?: Maybe<Scalars['ID']>;
  is_institution?: Maybe<Scalars['Boolean']>;
  institution?: Maybe<Scalars['String']>;
  institution_email?: Maybe<Scalars['String']>;
  appointment_type?: Maybe<AppointmentType>;
  product: Product;
  confirmation_code?: Maybe<Scalars['String']>;
};


export type AppointmentFacturasArgs = {
  show_hidden?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateInput = {
  datetime: Scalars['DateTime'];
  address: Scalars['String'];
  comuna: Scalars['String'];
  number_of_people: Scalars['Int'];
  patients: Array<Scalars['ID']>;
  product: Scalars['ID'];
  amount_paid?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  status?: Maybe<AppointmentStatus>;
  payment_status?: Maybe<PaymentStatus>;
  staff_email?: Maybe<Scalars['String']>;
  is_institution?: Maybe<Scalars['Boolean']>;
  institution?: Maybe<Scalars['String']>;
  institution_email?: Maybe<Scalars['String']>;
  appointment_type?: Maybe<AppointmentType>;
  confirmation_code?: Maybe<Scalars['String']>;
};

export type AppointmentCreateResponse = {
  __typename?: 'AppointmentCreateResponse';
  message: Scalars['String'];
  appointment?: Maybe<Appointment>;
};

export enum AppointmentStatus {
  Completed = 'completed',
  Processing = 'processing',
  ResultsDelivered = 'results_delivered',
  Cancelled = 'cancelled'
}

export enum AppointmentType {
  Antigen = 'antigen',
  Pcr = 'PCR'
}

export type AppointmentUpdateInput = {
  datetime?: Maybe<Scalars['DateTime']>;
  created_at?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  comuna?: Maybe<Scalars['String']>;
  amount_paid?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  patient_exposed_to_covid?: Maybe<Scalars['Boolean']>;
  symptoms?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  epivigila_code?: Maybe<Scalars['String']>;
  sample_id?: Maybe<Scalars['String']>;
  mercadopago_url?: Maybe<Scalars['String']>;
  number_of_people?: Maybe<Scalars['Int']>;
  patients?: Maybe<Array<Scalars['ID']>>;
  status?: Maybe<AppointmentStatus>;
  payment_status?: Maybe<PaymentStatus>;
  staff_email?: Maybe<Scalars['String']>;
  is_institution?: Maybe<Scalars['Boolean']>;
  institution?: Maybe<Scalars['String']>;
  institution_email?: Maybe<Scalars['String']>;
  appointment_type?: Maybe<AppointmentType>;
  product?: Maybe<Scalars['String']>;
  confirmation_code?: Maybe<Scalars['String']>;
};

export type AppointmentUpdateResponse = {
  __typename?: 'AppointmentUpdateResponse';
  message: Scalars['String'];
  appointment?: Maybe<Appointment>;
};

export type Boleta = {
  __typename?: 'Boleta';
  id: Scalars['ID'];
  patient_id: Scalars['ID'];
  appointment_id: Scalars['ID'];
  file_url: Scalars['String'];
  created_at: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  storage_location?: Maybe<Scalars['String']>;
};

export type BoletaFileInput = {
  file_url: Scalars['String'];
  storage_location: Scalars['String'];
  patient_id: Scalars['ID'];
  appointment_id: Scalars['ID'];
};

export type BoletaInput = {
  patient_id: Scalars['ID'];
  appointment_id: Scalars['ID'];
  product_id: Scalars['ID'];
  amount: Scalars['Int'];
  anonymous: Scalars['Boolean'];
  include_laboratory: Scalars['Boolean'];
  is_domicilio: Scalars['Boolean'];
};

export enum BusinessLine {
  Covid = 'covid',
  Lab = 'lab'
}

export type CompressedBoletas = {
  __typename?: 'CompressedBoletas';
  file_url: Scalars['String'];
};

export enum Dni_Type {
  Rut = 'RUT',
  Passport = 'passport'
}


export type Factura = {
  __typename?: 'Factura';
  id: Scalars['ID'];
  appointment_id: Scalars['ID'];
  file_url: Scalars['String'];
  created_at: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  storage_location?: Maybe<Scalars['String']>;
};

export type FacturaFileInput = {
  file_url: Scalars['String'];
  storage_location: Scalars['String'];
  appointment_id: Scalars['ID'];
};

export type GenericResponse = {
  __typename?: 'GenericResponse';
  message: Scalars['String'];
};

export type GenericResponseArray = {
  __typename?: 'GenericResponseArray';
  message: Array<Scalars['String']>;
};

export enum Laboratories {
  UChile = 'UChile',
  Irp = 'IRP',
  Redlab = 'Redlab'
}

export type Mutation = {
  __typename?: 'Mutation';
  createAppointment?: Maybe<AppointmentCreateResponse>;
  updateAppointment?: Maybe<AppointmentUpdateResponse>;
  emailPaymentRequest?: Maybe<GenericResponse>;
  emailConfirmation?: Maybe<GenericResponse>;
  requestEpivigila?: Maybe<GenericResponse>;
  regenerateMercadoPagoLink?: Maybe<GenericResponse>;
  calculatePriceBasedOnPrestaciones?: Maybe<GenericResponse>;
  submitBoleta?: Maybe<GenericResponse>;
  emailBoletas?: Maybe<GenericResponse>;
  emitirBoletaPCR?: Maybe<GenericResponseArray>;
  compressBoletas?: Maybe<CompressedBoletas>;
  deleteBoleta?: Maybe<GenericResponse>;
  submitFactura?: Maybe<GenericResponse>;
  emailFacturas?: Maybe<GenericResponse>;
  deleteFactura?: Maybe<GenericResponse>;
  submitOrden?: Maybe<GenericResponse>;
  generateOrden?: Maybe<GenericResponse>;
  createPatient?: Maybe<PatientCreateResponse>;
  updatePatient?: Maybe<PatientUpdateResponse>;
  deletePatient?: Maybe<GenericResponse>;
  submitResults?: Maybe<ResultUploadResponse>;
  requestResult?: Maybe<ResultRequestResponse>;
  emailResults?: Maybe<GenericResponse>;
  emailResultsGroup?: Maybe<GenericResponse>;
  deleteResult?: Maybe<GenericResponse>;
  submitSample?: Maybe<GenericResponse>;
  updateSample?: Maybe<GenericResponse>;
  deleteSample?: Maybe<GenericResponse>;
};


export type MutationCreateAppointmentArgs = {
  appointment: AppointmentCreateInput;
};


export type MutationUpdateAppointmentArgs = {
  id: Scalars['ID'];
  appointment: AppointmentUpdateInput;
};


export type MutationEmailPaymentRequestArgs = {
  patient_id: Scalars['ID'];
  appointment_id: Scalars['ID'];
};


export type MutationEmailConfirmationArgs = {
  patient_id: Scalars['ID'];
  appointment_id: Scalars['ID'];
};


export type MutationRequestEpivigilaArgs = {
  appointment_id: Scalars['ID'];
};


export type MutationRegenerateMercadoPagoLinkArgs = {
  appointment_id: Scalars['ID'];
};


export type MutationCalculatePriceBasedOnPrestacionesArgs = {
  appointment_id: Scalars['ID'];
  prestaciones: Array<PrestacionesPriceDetermination>;
};


export type MutationSubmitBoletaArgs = {
  boleta: BoletaFileInput;
};


export type MutationEmailBoletasArgs = {
  patient_id: Scalars['ID'];
  appointment_id: Scalars['ID'];
};


export type MutationEmitirBoletaPcrArgs = {
  boletas: Array<BoletaInput>;
};


export type MutationCompressBoletasArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteBoletaArgs = {
  id: Scalars['ID'];
};


export type MutationSubmitFacturaArgs = {
  factura: FacturaFileInput;
};


export type MutationEmailFacturasArgs = {
  appointment_id: Scalars['ID'];
};


export type MutationDeleteFacturaArgs = {
  id: Scalars['ID'];
};


export type MutationSubmitOrdenArgs = {
  orden: OrdenInput;
};


export type MutationGenerateOrdenArgs = {
  orden: OrdenGeneratorInput;
};


export type MutationCreatePatientArgs = {
  patients: Array<PatientCreateInput>;
  check_rut_duplicate?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdatePatientArgs = {
  id: Scalars['ID'];
  patient: PatientUpdateInput;
};


export type MutationDeletePatientArgs = {
  id: Scalars['ID'];
};


export type MutationSubmitResultsArgs = {
  result: ResultInput;
};


export type MutationRequestResultArgs = {
  samples: Array<Scalars['ID']>;
};


export type MutationEmailResultsArgs = {
  patient_id: Scalars['ID'];
  appointment_id: Scalars['ID'];
};


export type MutationEmailResultsGroupArgs = {
  appointment_id: Scalars['ID'];
};


export type MutationDeleteResultArgs = {
  id: Scalars['ID'];
};


export type MutationSubmitSampleArgs = {
  sample: SampleInput;
};


export type MutationUpdateSampleArgs = {
  id: Scalars['ID'];
  sample: SampleInput;
};


export type MutationDeleteSampleArgs = {
  id: Scalars['ID'];
};

export type Orden = {
  __typename?: 'Orden';
  id: Scalars['ID'];
  patient_id: Scalars['ID'];
  appointment_id: Scalars['ID'];
  file_url: Scalars['String'];
  created_at: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  storage_location?: Maybe<Scalars['String']>;
};

export type OrdenGeneratorInput = {
  patient_id: Scalars['ID'];
  appointment_id: Scalars['ID'];
};

export type OrdenInput = {
  file_url: Scalars['String'];
  storage_location: Scalars['String'];
  patient_id: Scalars['ID'];
  appointment_id: Scalars['ID'];
};

export type Patient = {
  __typename?: 'Patient';
  id: Scalars['ID'];
  deleted: Scalars['Boolean'];
  given_name: Scalars['String'];
  family_name: Scalars['String'];
  email: Scalars['String'];
  phone_number?: Maybe<Scalars['String']>;
  dni?: Maybe<Scalars['String']>;
  dni_type?: Maybe<Dni_Type>;
  sex?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['DateTime']>;
  comuna?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  pueblo_originario?: Maybe<Scalars['String']>;
  diseases?: Maybe<Scalars['String']>;
  workplace?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  institution?: Maybe<Scalars['String']>;
  appointments: Array<Appointment>;
  boletas: Array<Boleta>;
  ordenes: Array<Orden>;
  results: Array<Result>;
  samples: Array<Sample>;
};


export type PatientBoletasArgs = {
  appointment_id?: Maybe<Scalars['ID']>;
  show_hidden?: Maybe<Scalars['Boolean']>;
};


export type PatientOrdenesArgs = {
  appointment_id?: Maybe<Scalars['ID']>;
  show_hidden?: Maybe<Scalars['Boolean']>;
};


export type PatientResultsArgs = {
  appointment_id?: Maybe<Scalars['ID']>;
};


export type PatientSamplesArgs = {
  appointment_id?: Maybe<Scalars['ID']>;
};

export type PatientCreateInput = {
  given_name: Scalars['String'];
  family_name: Scalars['String'];
  email: Scalars['String'];
  phone_number?: Maybe<Scalars['String']>;
  dni?: Maybe<Scalars['String']>;
  dni_type?: Maybe<Dni_Type>;
  sex?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['DateTime']>;
  comuna?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  pueblo_originario?: Maybe<Scalars['String']>;
  diseases?: Maybe<Scalars['String']>;
  workplace?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  institution?: Maybe<Scalars['String']>;
};

export type PatientCreateResponse = {
  __typename?: 'PatientCreateResponse';
  message?: Maybe<Scalars['String']>;
  patients?: Maybe<Array<Maybe<Patient>>>;
};

export type PatientUpdateInput = {
  given_name?: Maybe<Scalars['String']>;
  family_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  dni?: Maybe<Scalars['String']>;
  dni_type?: Maybe<Dni_Type>;
  sex?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['DateTime']>;
  comuna?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  pueblo_originario?: Maybe<Scalars['String']>;
  diseases?: Maybe<Scalars['String']>;
  workplace?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  institution?: Maybe<Scalars['String']>;
};

export type PatientUpdateResponse = {
  __typename?: 'PatientUpdateResponse';
  message?: Maybe<Scalars['String']>;
  patient?: Maybe<Patient>;
};

export enum PaymentStatus {
  Completed = 'completed',
  Pending = 'pending',
  Partial = 'partial',
  Cancelled = 'cancelled'
}

export type Prestacion = {
  __typename?: 'Prestacion';
  id: Scalars['ID'];
  created_at: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  fonasa_code: Scalars['String'];
  description: Scalars['String'];
  division: ProductDivisions;
  valor_particular: Scalars['Int'];
  valor_fonasa?: Maybe<Scalars['Int']>;
  aporte_benef?: Maybe<Scalars['Int']>;
  only_particular: Scalars['Boolean'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  created_at: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  description: Scalars['String'];
  price: Scalars['Int'];
  prestaciones: Array<Prestacion>;
  domicilio: Scalars['Boolean'];
  division: ProductDivisions;
  sample_taking_price: Scalars['Int'];
  instruction: Scalars['String'];
  zoho_id?: Maybe<Scalars['String']>;
};

export enum ProductDivisions {
  Laboratorio = 'laboratorio',
  Covid = 'covid',
  Imagenes = 'imagenes'
}

export type Query = {
  __typename?: 'Query';
  appointment?: Maybe<Appointment>;
  appointments: Array<Appointment>;
  boleta?: Maybe<Boleta>;
  boletas: Array<Boleta>;
  factura?: Maybe<Factura>;
  facturas: Array<Factura>;
  patient?: Maybe<Patient>;
  patients: Array<Patient>;
  availablePrestaciones: Array<Prestacion>;
  availableProducts: Array<Product>;
  samples: Array<Sample>;
  users: Array<User>;
};


export type QueryAppointmentArgs = {
  id: Scalars['ID'];
};


export type QueryAppointmentsArgs = {
  begin_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  show_cancelled?: Maybe<Scalars['Boolean']>;
  business_line?: Maybe<BusinessLine>;
  show_institution_only?: Maybe<Scalars['Boolean']>;
};


export type QueryBoletaArgs = {
  id: Scalars['ID'];
};


export type QueryBoletasArgs = {
  begin_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  show_hidden?: Maybe<Scalars['Boolean']>;
};


export type QueryFacturaArgs = {
  id: Scalars['ID'];
};


export type QueryFacturasArgs = {
  begin_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  show_hidden?: Maybe<Scalars['Boolean']>;
};


export type QueryPatientArgs = {
  id: Scalars['ID'];
};


export type QuerySamplesArgs = {
  begin_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
};


export type QueryUsersArgs = {
  epivigila?: Maybe<Scalars['Boolean']>;
  role?: Maybe<UserRole>;
};

export type Result = {
  __typename?: 'Result';
  id: Scalars['ID'];
  deleted: Scalars['Boolean'];
  created_at: Scalars['DateTime'];
  patient_id: Scalars['ID'];
  appointment_id: Scalars['ID'];
  file_url: Scalars['String'];
};

export type ResultInput = {
  file_url: Scalars['String'];
  patient_id: Scalars['ID'];
  appointment_id: Scalars['ID'];
  sample_id?: Maybe<Scalars['ID']>;
};

export type ResultRequestResponse = {
  __typename?: 'ResultRequestResponse';
  message: Scalars['String'];
};

export type ResultUploadResponse = {
  __typename?: 'ResultUploadResponse';
  message: Scalars['String'];
  result?: Maybe<Result>;
};

export enum Sampletypes {
  Pcr = 'PCR',
  Antigen = 'antigen'
}

export enum Sample_Status {
  Processing = 'processing',
  NeedsAttention = 'needs_attention',
  RutIssue = 'rut_issue',
  NeedsPayment = 'needs_payment',
  Completed = 'completed'
}

export type Sample = {
  __typename?: 'Sample';
  id: Scalars['ID'];
  created_at: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  appointment_id: Scalars['ID'];
  patient_id: Scalars['ID'];
  epivigila_code?: Maybe<Scalars['String']>;
  sample_code?: Maybe<Scalars['String']>;
  laboratory?: Maybe<Laboratories>;
  staff?: Maybe<User>;
  product?: Maybe<Product>;
  sample_type?: Maybe<Sampletypes>;
  result?: Maybe<Scalars['String']>;
  status?: Maybe<Sample_Status>;
};

export type SampleInput = {
  appointment_id: Scalars['ID'];
  patient_id: Scalars['ID'];
  epivigila_code?: Maybe<Scalars['String']>;
  sample_code?: Maybe<Scalars['String']>;
  laboratory?: Maybe<Laboratories>;
  staff_email?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['ID']>;
  sample_type?: Maybe<Sampletypes>;
  result?: Maybe<Scalars['String']>;
  status?: Maybe<Sample_Status>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  deleted: Scalars['Boolean'];
  email: Scalars['String'];
  given_name: Scalars['String'];
  family_name: Scalars['String'];
  role?: Maybe<Array<UserRole>>;
  epivigila: Scalars['Boolean'];
  zoho_id?: Maybe<Scalars['String']>;
};

export enum UserRole {
  Staff = 'staff',
  Doctor = 'doctor',
  Admin = 'admin'
}

export type PrestacionesPriceDetermination = {
  id: Scalars['ID'];
  qty: Scalars['Int'];
};

export type FetchAppointmentsQueryVariables = Exact<{
  begin_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  show_cancelled?: Maybe<Scalars['Boolean']>;
  business_line?: Maybe<BusinessLine>;
  show_institution_only?: Maybe<Scalars['Boolean']>;
}>;


export type FetchAppointmentsQuery = (
  { __typename?: 'Query' }
  & { appointments: Array<(
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'datetime' | 'status' | 'payment_status' | 'address' | 'comuna' | 'number_of_people' | 'institution'>
    & { product: (
      { __typename?: 'Product' }
      & Pick<Product, 'description'>
    ), staff?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'given_name' | 'family_name'>
    )>, patients: Array<Maybe<(
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'given_name' | 'family_name' | 'date_of_birth' | 'email' | 'phone_number' | 'dni'>
    )>> }
  )> }
);

export type FetchIndividualAppointmentQueryVariables = Exact<{
  id: Scalars['ID'];
  appointment_id?: Maybe<Scalars['ID']>;
}>;


export type FetchIndividualAppointmentQuery = (
  { __typename?: 'Query' }
  & { appointment?: Maybe<(
    { __typename?: 'Appointment' }
    & Pick<Appointment, 'id' | 'datetime' | 'status' | 'payment_status' | 'address' | 'comuna' | 'amount_paid' | 'cost' | 'discount' | 'comments' | 'confirmation_code' | 'number_of_people' | 'institution' | 'is_institution' | 'institution_email' | 'appointment_type'>
    & { product: (
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'description'>
    ), staff?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email'>
    )>, facturas: Array<(
      { __typename?: 'Factura' }
      & Pick<Factura, 'id' | 'file_url' | 'created_at'>
    )>, patients: Array<Maybe<(
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'given_name' | 'family_name' | 'email' | 'phone_number' | 'dni' | 'dni_type' | 'date_of_birth' | 'comments' | 'institution'>
      & { results: Array<(
        { __typename?: 'Result' }
        & Pick<Result, 'id' | 'file_url' | 'created_at'>
      )>, boletas: Array<(
        { __typename?: 'Boleta' }
        & Pick<Boleta, 'id' | 'file_url' | 'created_at'>
      )>, ordenes: Array<(
        { __typename?: 'Orden' }
        & Pick<Orden, 'file_url' | 'created_at'>
      )>, samples: Array<(
        { __typename?: 'Sample' }
        & Pick<Sample, 'id' | 'epivigila_code' | 'sample_code' | 'laboratory' | 'appointment_id' | 'patient_id' | 'status'>
        & { product?: Maybe<(
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'description'>
        )>, staff?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'given_name' | 'family_name' | 'email'>
        )> }
      )> }
    )>> }
  )> }
);

export type FetchPatientsQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchPatientsQuery = (
  { __typename?: 'Query' }
  & { patients: Array<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'given_name' | 'family_name' | 'date_of_birth' | 'email' | 'phone_number' | 'dni' | 'dni_type' | 'comuna' | 'address' | 'institution'>
  )> }
);

export type FetchIndividualPatientQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FetchIndividualPatientQuery = (
  { __typename?: 'Query' }
  & { patient?: Maybe<(
    { __typename?: 'Patient' }
    & Pick<Patient, 'id' | 'given_name' | 'family_name' | 'email' | 'phone_number' | 'dni' | 'dni_type' | 'sex' | 'comuna' | 'address' | 'workplace' | 'diseases' | 'comments' | 'institution'>
    & { appointments: Array<(
      { __typename?: 'Appointment' }
      & Pick<Appointment, 'id' | 'datetime' | 'comuna' | 'address'>
    )>, samples: Array<(
      { __typename?: 'Sample' }
      & Pick<Sample, 'id' | 'epivigila_code' | 'sample_code' | 'laboratory' | 'sample_type' | 'result'>
      & { staff?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'given_name' | 'family_name' | 'email'>
      )>, product?: Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'description'>
      )> }
    )>, boletas: Array<(
      { __typename?: 'Boleta' }
      & Pick<Boleta, 'id' | 'file_url' | 'created_at'>
    )>, ordenes: Array<(
      { __typename?: 'Orden' }
      & Pick<Orden, 'file_url' | 'created_at'>
    )>, results: Array<(
      { __typename?: 'Result' }
      & Pick<Result, 'id' | 'file_url' | 'created_at'>
    )> }
  )> }
);

export type FetchStaffQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchStaffQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'given_name' | 'family_name' | 'email'>
  )> }
);

export type FetchProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchProductsQuery = (
  { __typename?: 'Query' }
  & { availableProducts: Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'description'>
  )> }
);

export type FetchPrestacionesQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchPrestacionesQuery = (
  { __typename?: 'Query' }
  & { availablePrestaciones: Array<(
    { __typename?: 'Prestacion' }
    & Pick<Prestacion, 'id' | 'description' | 'fonasa_code' | 'division' | 'valor_particular' | 'valor_fonasa' | 'aporte_benef' | 'only_particular'>
  )> }
);

export type CreateAppointmentMutationVariables = Exact<{
  appointment: AppointmentCreateInput;
}>;


export type CreateAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { createAppointment?: Maybe<(
    { __typename?: 'AppointmentCreateResponse' }
    & Pick<AppointmentCreateResponse, 'message'>
    & { appointment?: Maybe<(
      { __typename?: 'Appointment' }
      & Pick<Appointment, 'id' | 'datetime' | 'status' | 'address' | 'comuna' | 'amount_paid' | 'cost' | 'comments'>
      & { patients: Array<Maybe<(
        { __typename?: 'Patient' }
        & Pick<Patient, 'id' | 'given_name' | 'family_name' | 'date_of_birth' | 'email' | 'phone_number' | 'dni' | 'dni_type' | 'sex' | 'nationality' | 'comuna' | 'address' | 'workplace' | 'diseases' | 'comments' | 'institution'>
      )>> }
    )> }
  )> }
);

export type UpdateIndividualAppointmentMutationVariables = Exact<{
  id: Scalars['ID'];
  appointment: AppointmentUpdateInput;
}>;


export type UpdateIndividualAppointmentMutation = (
  { __typename?: 'Mutation' }
  & { updateAppointment?: Maybe<(
    { __typename?: 'AppointmentUpdateResponse' }
    & Pick<AppointmentUpdateResponse, 'message'>
    & { appointment?: Maybe<(
      { __typename?: 'Appointment' }
      & Pick<Appointment, 'id' | 'datetime' | 'status' | 'address' | 'comuna' | 'amount_paid' | 'cost' | 'comments'>
      & { patients: Array<Maybe<(
        { __typename?: 'Patient' }
        & Pick<Patient, 'id' | 'given_name' | 'family_name' | 'date_of_birth' | 'email' | 'phone_number' | 'dni' | 'dni_type' | 'sex' | 'nationality' | 'comuna' | 'address' | 'workplace' | 'diseases' | 'comments' | 'institution'>
      )>>, staff?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email'>
      )> }
    )> }
  )> }
);

export type EmailConfirmationMutationVariables = Exact<{
  patient_id: Scalars['ID'];
  appointment_id: Scalars['ID'];
}>;


export type EmailConfirmationMutation = (
  { __typename?: 'Mutation' }
  & { emailConfirmation?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'message'>
  )> }
);

export type EmailPaymentRequestMutationVariables = Exact<{
  patient_id: Scalars['ID'];
  appointment_id: Scalars['ID'];
}>;


export type EmailPaymentRequestMutation = (
  { __typename?: 'Mutation' }
  & { emailPaymentRequest?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'message'>
  )> }
);

export type RegenerateMercadoPagoLinkMutationVariables = Exact<{
  appointment_id: Scalars['ID'];
}>;


export type RegenerateMercadoPagoLinkMutation = (
  { __typename?: 'Mutation' }
  & { regenerateMercadoPagoLink?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'message'>
  )> }
);

export type RequestEpivigilaMutationVariables = Exact<{
  appointment_id: Scalars['ID'];
}>;


export type RequestEpivigilaMutation = (
  { __typename?: 'Mutation' }
  & { requestEpivigila?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'message'>
  )> }
);

export type CalculatePriceBasedOnPrestacionesMutationVariables = Exact<{
  appointment_id: Scalars['ID'];
  prestaciones: Array<PrestacionesPriceDetermination> | PrestacionesPriceDetermination;
}>;


export type CalculatePriceBasedOnPrestacionesMutation = (
  { __typename?: 'Mutation' }
  & { calculatePriceBasedOnPrestaciones?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'message'>
  )> }
);

export type CreatePatientMutationVariables = Exact<{
  patients: Array<PatientCreateInput> | PatientCreateInput;
  check_rut_duplicate?: Maybe<Scalars['Boolean']>;
}>;


export type CreatePatientMutation = (
  { __typename?: 'Mutation' }
  & { createPatient?: Maybe<(
    { __typename?: 'PatientCreateResponse' }
    & Pick<PatientCreateResponse, 'message'>
  )> }
);

export type UpdateIndividualPatientMutationVariables = Exact<{
  id: Scalars['ID'];
  patient: PatientUpdateInput;
}>;


export type UpdateIndividualPatientMutation = (
  { __typename?: 'Mutation' }
  & { updatePatient?: Maybe<(
    { __typename?: 'PatientUpdateResponse' }
    & Pick<PatientUpdateResponse, 'message'>
    & { patient?: Maybe<(
      { __typename?: 'Patient' }
      & Pick<Patient, 'id' | 'given_name' | 'family_name' | 'date_of_birth' | 'email' | 'phone_number' | 'dni' | 'dni_type' | 'sex' | 'nationality' | 'comuna' | 'address' | 'workplace' | 'diseases' | 'comments'>
    )> }
  )> }
);

export type DeletePatientMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePatientMutation = (
  { __typename?: 'Mutation' }
  & { deletePatient?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'message'>
  )> }
);

export type BoletaQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BoletaQuery = (
  { __typename?: 'Query' }
  & { boleta?: Maybe<(
    { __typename?: 'Boleta' }
    & Pick<Boleta, 'id' | 'file_url' | 'appointment_id' | 'patient_id' | 'created_at' | 'storage_location'>
  )> }
);

export type BoletasQueryVariables = Exact<{
  begin_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
}>;


export type BoletasQuery = (
  { __typename?: 'Query' }
  & { boletas: Array<(
    { __typename?: 'Boleta' }
    & Pick<Boleta, 'id' | 'file_url' | 'appointment_id' | 'patient_id' | 'created_at' | 'storage_location'>
  )> }
);

export type EmailBoletasMutationVariables = Exact<{
  patient_id: Scalars['ID'];
  appointment_id: Scalars['ID'];
}>;


export type EmailBoletasMutation = (
  { __typename?: 'Mutation' }
  & { emailBoletas?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'message'>
  )> }
);

export type UploadBoletaMutationVariables = Exact<{
  boleta: BoletaFileInput;
}>;


export type UploadBoletaMutation = (
  { __typename?: 'Mutation' }
  & { submitBoleta?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'message'>
  )> }
);

export type EmitirBoletaPcrMutationVariables = Exact<{
  boletas: Array<BoletaInput> | BoletaInput;
}>;


export type EmitirBoletaPcrMutation = (
  { __typename?: 'Mutation' }
  & { emitirBoletaPCR?: Maybe<(
    { __typename?: 'GenericResponseArray' }
    & Pick<GenericResponseArray, 'message'>
  )> }
);

export type CompressBoletasMutationVariables = Exact<{
  boletas: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type CompressBoletasMutation = (
  { __typename?: 'Mutation' }
  & { compressBoletas?: Maybe<(
    { __typename?: 'CompressedBoletas' }
    & Pick<CompressedBoletas, 'file_url'>
  )> }
);

export type DeleteBoletaMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBoletaMutation = (
  { __typename?: 'Mutation' }
  & { deleteBoleta?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'message'>
  )> }
);

export type FacturaQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FacturaQuery = (
  { __typename?: 'Query' }
  & { factura?: Maybe<(
    { __typename?: 'Factura' }
    & Pick<Factura, 'id' | 'file_url' | 'appointment_id' | 'created_at' | 'storage_location'>
  )> }
);

export type FacturasQueryVariables = Exact<{
  begin_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
}>;


export type FacturasQuery = (
  { __typename?: 'Query' }
  & { facturas: Array<(
    { __typename?: 'Factura' }
    & Pick<Factura, 'id' | 'file_url' | 'appointment_id' | 'created_at' | 'storage_location'>
  )> }
);

export type EmailFacturasMutationVariables = Exact<{
  appointment_id: Scalars['ID'];
}>;


export type EmailFacturasMutation = (
  { __typename?: 'Mutation' }
  & { emailFacturas?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'message'>
  )> }
);

export type UploadFacturaMutationVariables = Exact<{
  factura: FacturaFileInput;
}>;


export type UploadFacturaMutation = (
  { __typename?: 'Mutation' }
  & { submitFactura?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'message'>
  )> }
);

export type DeleteFacturaMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteFacturaMutation = (
  { __typename?: 'Mutation' }
  & { deleteFactura?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'message'>
  )> }
);

export type UploadOrdenMutationVariables = Exact<{
  orden: OrdenInput;
}>;


export type UploadOrdenMutation = (
  { __typename?: 'Mutation' }
  & { submitOrden?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'message'>
  )> }
);

export type GenerateOrdenMutationVariables = Exact<{
  orden: OrdenGeneratorInput;
}>;


export type GenerateOrdenMutation = (
  { __typename?: 'Mutation' }
  & { generateOrden?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'message'>
  )> }
);

export type UploadResultsMutationVariables = Exact<{
  results: ResultInput;
}>;


export type UploadResultsMutation = (
  { __typename?: 'Mutation' }
  & { submitResults?: Maybe<(
    { __typename?: 'ResultUploadResponse' }
    & Pick<ResultUploadResponse, 'message'>
    & { result?: Maybe<(
      { __typename?: 'Result' }
      & Pick<Result, 'file_url'>
    )> }
  )> }
);

export type EmailResultsMutationVariables = Exact<{
  patient_id: Scalars['ID'];
  appointment_id: Scalars['ID'];
}>;


export type EmailResultsMutation = (
  { __typename?: 'Mutation' }
  & { emailResults?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'message'>
  )> }
);

export type RequestResultMutationVariables = Exact<{
  samples: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type RequestResultMutation = (
  { __typename?: 'Mutation' }
  & { requestResult?: Maybe<(
    { __typename?: 'ResultRequestResponse' }
    & Pick<ResultRequestResponse, 'message'>
  )> }
);

export type EmailResultsGroupMutationVariables = Exact<{
  appointment_id: Scalars['ID'];
}>;


export type EmailResultsGroupMutation = (
  { __typename?: 'Mutation' }
  & { emailResultsGroup?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'message'>
  )> }
);

export type DeleteResultMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteResultMutation = (
  { __typename?: 'Mutation' }
  & { deleteResult?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'message'>
  )> }
);

export type SubmitSampleMutationVariables = Exact<{
  sample: SampleInput;
}>;


export type SubmitSampleMutation = (
  { __typename?: 'Mutation' }
  & { submitSample?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'message'>
  )> }
);

export type UpdateSampleMutationVariables = Exact<{
  id: Scalars['ID'];
  sample: SampleInput;
}>;


export type UpdateSampleMutation = (
  { __typename?: 'Mutation' }
  & { updateSample?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'message'>
  )> }
);

export type DeleteSampleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSampleMutation = (
  { __typename?: 'Mutation' }
  & { deleteSample?: Maybe<(
    { __typename?: 'GenericResponse' }
    & Pick<GenericResponse, 'message'>
  )> }
);


export const FetchAppointmentsDocument = gql`
    query fetchAppointments($begin_date: String, $end_date: String, $show_cancelled: Boolean, $business_line: BusinessLine, $show_institution_only: Boolean) {
  appointments(
    begin_date: $begin_date
    end_date: $end_date
    show_cancelled: $show_cancelled
    business_line: $business_line
    show_institution_only: $show_institution_only
  ) {
    id
    datetime
    status
    payment_status
    address
    comuna
    number_of_people
    institution
    product {
      description
    }
    staff {
      id
      email
      given_name
      family_name
    }
    patients {
      id
      given_name
      family_name
      date_of_birth
      email
      phone_number
      dni
    }
  }
}
    `;

/**
 * __useFetchAppointmentsQuery__
 *
 * To run a query within a React component, call `useFetchAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAppointmentsQuery({
 *   variables: {
 *      begin_date: // value for 'begin_date'
 *      end_date: // value for 'end_date'
 *      show_cancelled: // value for 'show_cancelled'
 *      business_line: // value for 'business_line'
 *      show_institution_only: // value for 'show_institution_only'
 *   },
 * });
 */
export function useFetchAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<FetchAppointmentsQuery, FetchAppointmentsQueryVariables>) {
        return Apollo.useQuery<FetchAppointmentsQuery, FetchAppointmentsQueryVariables>(FetchAppointmentsDocument, baseOptions);
      }
export function useFetchAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAppointmentsQuery, FetchAppointmentsQueryVariables>) {
          return Apollo.useLazyQuery<FetchAppointmentsQuery, FetchAppointmentsQueryVariables>(FetchAppointmentsDocument, baseOptions);
        }
export type FetchAppointmentsQueryHookResult = ReturnType<typeof useFetchAppointmentsQuery>;
export type FetchAppointmentsLazyQueryHookResult = ReturnType<typeof useFetchAppointmentsLazyQuery>;
export type FetchAppointmentsQueryResult = Apollo.QueryResult<FetchAppointmentsQuery, FetchAppointmentsQueryVariables>;
export const FetchIndividualAppointmentDocument = gql`
    query fetchIndividualAppointment($id: ID!, $appointment_id: ID) {
  appointment(id: $id) {
    id
    datetime
    status
    payment_status
    address
    comuna
    amount_paid
    cost
    discount
    comments
    confirmation_code
    number_of_people
    institution
    is_institution
    institution_email
    appointment_type
    product {
      id
      description
    }
    staff {
      id
      email
    }
    facturas {
      id
      file_url
      created_at
    }
    patients {
      id
      given_name
      family_name
      email
      phone_number
      dni
      dni_type
      date_of_birth
      comments
      institution
      results(appointment_id: $appointment_id) {
        id
        file_url
        created_at
      }
      boletas(appointment_id: $appointment_id) {
        id
        file_url
        created_at
      }
      ordenes(appointment_id: $appointment_id) {
        file_url
        created_at
      }
      samples(appointment_id: $appointment_id) {
        id
        epivigila_code
        sample_code
        laboratory
        appointment_id
        patient_id
        product {
          id
          description
        }
        staff {
          given_name
          family_name
          email
        }
        status
      }
    }
  }
}
    `;

/**
 * __useFetchIndividualAppointmentQuery__
 *
 * To run a query within a React component, call `useFetchIndividualAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchIndividualAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchIndividualAppointmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      appointment_id: // value for 'appointment_id'
 *   },
 * });
 */
export function useFetchIndividualAppointmentQuery(baseOptions: Apollo.QueryHookOptions<FetchIndividualAppointmentQuery, FetchIndividualAppointmentQueryVariables>) {
        return Apollo.useQuery<FetchIndividualAppointmentQuery, FetchIndividualAppointmentQueryVariables>(FetchIndividualAppointmentDocument, baseOptions);
      }
export function useFetchIndividualAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchIndividualAppointmentQuery, FetchIndividualAppointmentQueryVariables>) {
          return Apollo.useLazyQuery<FetchIndividualAppointmentQuery, FetchIndividualAppointmentQueryVariables>(FetchIndividualAppointmentDocument, baseOptions);
        }
export type FetchIndividualAppointmentQueryHookResult = ReturnType<typeof useFetchIndividualAppointmentQuery>;
export type FetchIndividualAppointmentLazyQueryHookResult = ReturnType<typeof useFetchIndividualAppointmentLazyQuery>;
export type FetchIndividualAppointmentQueryResult = Apollo.QueryResult<FetchIndividualAppointmentQuery, FetchIndividualAppointmentQueryVariables>;
export const FetchPatientsDocument = gql`
    query fetchPatients {
  patients {
    id
    given_name
    family_name
    date_of_birth
    email
    phone_number
    dni
    dni_type
    comuna
    address
    institution
  }
}
    `;

/**
 * __useFetchPatientsQuery__
 *
 * To run a query within a React component, call `useFetchPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPatientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchPatientsQuery(baseOptions?: Apollo.QueryHookOptions<FetchPatientsQuery, FetchPatientsQueryVariables>) {
        return Apollo.useQuery<FetchPatientsQuery, FetchPatientsQueryVariables>(FetchPatientsDocument, baseOptions);
      }
export function useFetchPatientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchPatientsQuery, FetchPatientsQueryVariables>) {
          return Apollo.useLazyQuery<FetchPatientsQuery, FetchPatientsQueryVariables>(FetchPatientsDocument, baseOptions);
        }
export type FetchPatientsQueryHookResult = ReturnType<typeof useFetchPatientsQuery>;
export type FetchPatientsLazyQueryHookResult = ReturnType<typeof useFetchPatientsLazyQuery>;
export type FetchPatientsQueryResult = Apollo.QueryResult<FetchPatientsQuery, FetchPatientsQueryVariables>;
export const FetchIndividualPatientDocument = gql`
    query fetchIndividualPatient($id: ID!) {
  patient(id: $id) {
    id
    given_name
    family_name
    email
    phone_number
    dni
    dni_type
    sex
    comuna
    address
    workplace
    diseases
    comments
    institution
    appointments {
      id
      datetime
      comuna
      address
    }
    samples {
      id
      epivigila_code
      sample_code
      laboratory
      staff {
        given_name
        family_name
        email
      }
      product {
        id
        description
      }
      sample_type
      result
    }
    boletas {
      id
      file_url
      created_at
    }
    ordenes {
      file_url
      created_at
    }
    results {
      id
      file_url
      created_at
    }
  }
}
    `;

/**
 * __useFetchIndividualPatientQuery__
 *
 * To run a query within a React component, call `useFetchIndividualPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchIndividualPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchIndividualPatientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchIndividualPatientQuery(baseOptions: Apollo.QueryHookOptions<FetchIndividualPatientQuery, FetchIndividualPatientQueryVariables>) {
        return Apollo.useQuery<FetchIndividualPatientQuery, FetchIndividualPatientQueryVariables>(FetchIndividualPatientDocument, baseOptions);
      }
export function useFetchIndividualPatientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchIndividualPatientQuery, FetchIndividualPatientQueryVariables>) {
          return Apollo.useLazyQuery<FetchIndividualPatientQuery, FetchIndividualPatientQueryVariables>(FetchIndividualPatientDocument, baseOptions);
        }
export type FetchIndividualPatientQueryHookResult = ReturnType<typeof useFetchIndividualPatientQuery>;
export type FetchIndividualPatientLazyQueryHookResult = ReturnType<typeof useFetchIndividualPatientLazyQuery>;
export type FetchIndividualPatientQueryResult = Apollo.QueryResult<FetchIndividualPatientQuery, FetchIndividualPatientQueryVariables>;
export const FetchStaffDocument = gql`
    query fetchStaff {
  users(role: staff) {
    id
    given_name
    family_name
    email
  }
}
    `;

/**
 * __useFetchStaffQuery__
 *
 * To run a query within a React component, call `useFetchStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchStaffQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchStaffQuery(baseOptions?: Apollo.QueryHookOptions<FetchStaffQuery, FetchStaffQueryVariables>) {
        return Apollo.useQuery<FetchStaffQuery, FetchStaffQueryVariables>(FetchStaffDocument, baseOptions);
      }
export function useFetchStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchStaffQuery, FetchStaffQueryVariables>) {
          return Apollo.useLazyQuery<FetchStaffQuery, FetchStaffQueryVariables>(FetchStaffDocument, baseOptions);
        }
export type FetchStaffQueryHookResult = ReturnType<typeof useFetchStaffQuery>;
export type FetchStaffLazyQueryHookResult = ReturnType<typeof useFetchStaffLazyQuery>;
export type FetchStaffQueryResult = Apollo.QueryResult<FetchStaffQuery, FetchStaffQueryVariables>;
export const FetchProductsDocument = gql`
    query fetchProducts {
  availableProducts {
    id
    description
  }
}
    `;

/**
 * __useFetchProductsQuery__
 *
 * To run a query within a React component, call `useFetchProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchProductsQuery(baseOptions?: Apollo.QueryHookOptions<FetchProductsQuery, FetchProductsQueryVariables>) {
        return Apollo.useQuery<FetchProductsQuery, FetchProductsQueryVariables>(FetchProductsDocument, baseOptions);
      }
export function useFetchProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchProductsQuery, FetchProductsQueryVariables>) {
          return Apollo.useLazyQuery<FetchProductsQuery, FetchProductsQueryVariables>(FetchProductsDocument, baseOptions);
        }
export type FetchProductsQueryHookResult = ReturnType<typeof useFetchProductsQuery>;
export type FetchProductsLazyQueryHookResult = ReturnType<typeof useFetchProductsLazyQuery>;
export type FetchProductsQueryResult = Apollo.QueryResult<FetchProductsQuery, FetchProductsQueryVariables>;
export const FetchPrestacionesDocument = gql`
    query fetchPrestaciones {
  availablePrestaciones {
    id
    description
    fonasa_code
    division
    valor_particular
    valor_fonasa
    aporte_benef
    only_particular
  }
}
    `;

/**
 * __useFetchPrestacionesQuery__
 *
 * To run a query within a React component, call `useFetchPrestacionesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPrestacionesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPrestacionesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchPrestacionesQuery(baseOptions?: Apollo.QueryHookOptions<FetchPrestacionesQuery, FetchPrestacionesQueryVariables>) {
        return Apollo.useQuery<FetchPrestacionesQuery, FetchPrestacionesQueryVariables>(FetchPrestacionesDocument, baseOptions);
      }
export function useFetchPrestacionesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchPrestacionesQuery, FetchPrestacionesQueryVariables>) {
          return Apollo.useLazyQuery<FetchPrestacionesQuery, FetchPrestacionesQueryVariables>(FetchPrestacionesDocument, baseOptions);
        }
export type FetchPrestacionesQueryHookResult = ReturnType<typeof useFetchPrestacionesQuery>;
export type FetchPrestacionesLazyQueryHookResult = ReturnType<typeof useFetchPrestacionesLazyQuery>;
export type FetchPrestacionesQueryResult = Apollo.QueryResult<FetchPrestacionesQuery, FetchPrestacionesQueryVariables>;
export const CreateAppointmentDocument = gql`
    mutation createAppointment($appointment: AppointmentCreateInput!) {
  createAppointment(appointment: $appointment) {
    message
    appointment {
      id
      datetime
      status
      address
      comuna
      amount_paid
      cost
      comments
      patients {
        id
        given_name
        family_name
        date_of_birth
        email
        phone_number
        dni
        dni_type
        sex
        nationality
        comuna
        address
        workplace
        diseases
        comments
        institution
      }
    }
  }
}
    `;
export type CreateAppointmentMutationFn = Apollo.MutationFunction<CreateAppointmentMutation, CreateAppointmentMutationVariables>;

/**
 * __useCreateAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentMutation, { data, loading, error }] = useCreateAppointmentMutation({
 *   variables: {
 *      appointment: // value for 'appointment'
 *   },
 * });
 */
export function useCreateAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAppointmentMutation, CreateAppointmentMutationVariables>) {
        return Apollo.useMutation<CreateAppointmentMutation, CreateAppointmentMutationVariables>(CreateAppointmentDocument, baseOptions);
      }
export type CreateAppointmentMutationHookResult = ReturnType<typeof useCreateAppointmentMutation>;
export type CreateAppointmentMutationResult = Apollo.MutationResult<CreateAppointmentMutation>;
export type CreateAppointmentMutationOptions = Apollo.BaseMutationOptions<CreateAppointmentMutation, CreateAppointmentMutationVariables>;
export const UpdateIndividualAppointmentDocument = gql`
    mutation updateIndividualAppointment($id: ID!, $appointment: AppointmentUpdateInput!) {
  updateAppointment(id: $id, appointment: $appointment) {
    message
    appointment {
      id
      datetime
      status
      address
      comuna
      amount_paid
      cost
      comments
      patients {
        id
        given_name
        family_name
        date_of_birth
        email
        phone_number
        dni
        dni_type
        sex
        nationality
        comuna
        address
        workplace
        diseases
        comments
        institution
      }
      staff {
        id
        email
      }
    }
  }
}
    `;
export type UpdateIndividualAppointmentMutationFn = Apollo.MutationFunction<UpdateIndividualAppointmentMutation, UpdateIndividualAppointmentMutationVariables>;

/**
 * __useUpdateIndividualAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateIndividualAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIndividualAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIndividualAppointmentMutation, { data, loading, error }] = useUpdateIndividualAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      appointment: // value for 'appointment'
 *   },
 * });
 */
export function useUpdateIndividualAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIndividualAppointmentMutation, UpdateIndividualAppointmentMutationVariables>) {
        return Apollo.useMutation<UpdateIndividualAppointmentMutation, UpdateIndividualAppointmentMutationVariables>(UpdateIndividualAppointmentDocument, baseOptions);
      }
export type UpdateIndividualAppointmentMutationHookResult = ReturnType<typeof useUpdateIndividualAppointmentMutation>;
export type UpdateIndividualAppointmentMutationResult = Apollo.MutationResult<UpdateIndividualAppointmentMutation>;
export type UpdateIndividualAppointmentMutationOptions = Apollo.BaseMutationOptions<UpdateIndividualAppointmentMutation, UpdateIndividualAppointmentMutationVariables>;
export const EmailConfirmationDocument = gql`
    mutation emailConfirmation($patient_id: ID!, $appointment_id: ID!) {
  emailConfirmation(patient_id: $patient_id, appointment_id: $appointment_id) {
    message
  }
}
    `;
export type EmailConfirmationMutationFn = Apollo.MutationFunction<EmailConfirmationMutation, EmailConfirmationMutationVariables>;

/**
 * __useEmailConfirmationMutation__
 *
 * To run a mutation, you first call `useEmailConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailConfirmationMutation, { data, loading, error }] = useEmailConfirmationMutation({
 *   variables: {
 *      patient_id: // value for 'patient_id'
 *      appointment_id: // value for 'appointment_id'
 *   },
 * });
 */
export function useEmailConfirmationMutation(baseOptions?: Apollo.MutationHookOptions<EmailConfirmationMutation, EmailConfirmationMutationVariables>) {
        return Apollo.useMutation<EmailConfirmationMutation, EmailConfirmationMutationVariables>(EmailConfirmationDocument, baseOptions);
      }
export type EmailConfirmationMutationHookResult = ReturnType<typeof useEmailConfirmationMutation>;
export type EmailConfirmationMutationResult = Apollo.MutationResult<EmailConfirmationMutation>;
export type EmailConfirmationMutationOptions = Apollo.BaseMutationOptions<EmailConfirmationMutation, EmailConfirmationMutationVariables>;
export const EmailPaymentRequestDocument = gql`
    mutation emailPaymentRequest($patient_id: ID!, $appointment_id: ID!) {
  emailPaymentRequest(patient_id: $patient_id, appointment_id: $appointment_id) {
    message
  }
}
    `;
export type EmailPaymentRequestMutationFn = Apollo.MutationFunction<EmailPaymentRequestMutation, EmailPaymentRequestMutationVariables>;

/**
 * __useEmailPaymentRequestMutation__
 *
 * To run a mutation, you first call `useEmailPaymentRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailPaymentRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailPaymentRequestMutation, { data, loading, error }] = useEmailPaymentRequestMutation({
 *   variables: {
 *      patient_id: // value for 'patient_id'
 *      appointment_id: // value for 'appointment_id'
 *   },
 * });
 */
export function useEmailPaymentRequestMutation(baseOptions?: Apollo.MutationHookOptions<EmailPaymentRequestMutation, EmailPaymentRequestMutationVariables>) {
        return Apollo.useMutation<EmailPaymentRequestMutation, EmailPaymentRequestMutationVariables>(EmailPaymentRequestDocument, baseOptions);
      }
export type EmailPaymentRequestMutationHookResult = ReturnType<typeof useEmailPaymentRequestMutation>;
export type EmailPaymentRequestMutationResult = Apollo.MutationResult<EmailPaymentRequestMutation>;
export type EmailPaymentRequestMutationOptions = Apollo.BaseMutationOptions<EmailPaymentRequestMutation, EmailPaymentRequestMutationVariables>;
export const RegenerateMercadoPagoLinkDocument = gql`
    mutation regenerateMercadoPagoLink($appointment_id: ID!) {
  regenerateMercadoPagoLink(appointment_id: $appointment_id) {
    message
  }
}
    `;
export type RegenerateMercadoPagoLinkMutationFn = Apollo.MutationFunction<RegenerateMercadoPagoLinkMutation, RegenerateMercadoPagoLinkMutationVariables>;

/**
 * __useRegenerateMercadoPagoLinkMutation__
 *
 * To run a mutation, you first call `useRegenerateMercadoPagoLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateMercadoPagoLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateMercadoPagoLinkMutation, { data, loading, error }] = useRegenerateMercadoPagoLinkMutation({
 *   variables: {
 *      appointment_id: // value for 'appointment_id'
 *   },
 * });
 */
export function useRegenerateMercadoPagoLinkMutation(baseOptions?: Apollo.MutationHookOptions<RegenerateMercadoPagoLinkMutation, RegenerateMercadoPagoLinkMutationVariables>) {
        return Apollo.useMutation<RegenerateMercadoPagoLinkMutation, RegenerateMercadoPagoLinkMutationVariables>(RegenerateMercadoPagoLinkDocument, baseOptions);
      }
export type RegenerateMercadoPagoLinkMutationHookResult = ReturnType<typeof useRegenerateMercadoPagoLinkMutation>;
export type RegenerateMercadoPagoLinkMutationResult = Apollo.MutationResult<RegenerateMercadoPagoLinkMutation>;
export type RegenerateMercadoPagoLinkMutationOptions = Apollo.BaseMutationOptions<RegenerateMercadoPagoLinkMutation, RegenerateMercadoPagoLinkMutationVariables>;
export const RequestEpivigilaDocument = gql`
    mutation requestEpivigila($appointment_id: ID!) {
  requestEpivigila(appointment_id: $appointment_id) {
    message
  }
}
    `;
export type RequestEpivigilaMutationFn = Apollo.MutationFunction<RequestEpivigilaMutation, RequestEpivigilaMutationVariables>;

/**
 * __useRequestEpivigilaMutation__
 *
 * To run a mutation, you first call `useRequestEpivigilaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestEpivigilaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestEpivigilaMutation, { data, loading, error }] = useRequestEpivigilaMutation({
 *   variables: {
 *      appointment_id: // value for 'appointment_id'
 *   },
 * });
 */
export function useRequestEpivigilaMutation(baseOptions?: Apollo.MutationHookOptions<RequestEpivigilaMutation, RequestEpivigilaMutationVariables>) {
        return Apollo.useMutation<RequestEpivigilaMutation, RequestEpivigilaMutationVariables>(RequestEpivigilaDocument, baseOptions);
      }
export type RequestEpivigilaMutationHookResult = ReturnType<typeof useRequestEpivigilaMutation>;
export type RequestEpivigilaMutationResult = Apollo.MutationResult<RequestEpivigilaMutation>;
export type RequestEpivigilaMutationOptions = Apollo.BaseMutationOptions<RequestEpivigilaMutation, RequestEpivigilaMutationVariables>;
export const CalculatePriceBasedOnPrestacionesDocument = gql`
    mutation calculatePriceBasedOnPrestaciones($appointment_id: ID!, $prestaciones: [prestacionesPriceDetermination!]!) {
  calculatePriceBasedOnPrestaciones(
    appointment_id: $appointment_id
    prestaciones: $prestaciones
  ) {
    message
  }
}
    `;
export type CalculatePriceBasedOnPrestacionesMutationFn = Apollo.MutationFunction<CalculatePriceBasedOnPrestacionesMutation, CalculatePriceBasedOnPrestacionesMutationVariables>;

/**
 * __useCalculatePriceBasedOnPrestacionesMutation__
 *
 * To run a mutation, you first call `useCalculatePriceBasedOnPrestacionesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalculatePriceBasedOnPrestacionesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calculatePriceBasedOnPrestacionesMutation, { data, loading, error }] = useCalculatePriceBasedOnPrestacionesMutation({
 *   variables: {
 *      appointment_id: // value for 'appointment_id'
 *      prestaciones: // value for 'prestaciones'
 *   },
 * });
 */
export function useCalculatePriceBasedOnPrestacionesMutation(baseOptions?: Apollo.MutationHookOptions<CalculatePriceBasedOnPrestacionesMutation, CalculatePriceBasedOnPrestacionesMutationVariables>) {
        return Apollo.useMutation<CalculatePriceBasedOnPrestacionesMutation, CalculatePriceBasedOnPrestacionesMutationVariables>(CalculatePriceBasedOnPrestacionesDocument, baseOptions);
      }
export type CalculatePriceBasedOnPrestacionesMutationHookResult = ReturnType<typeof useCalculatePriceBasedOnPrestacionesMutation>;
export type CalculatePriceBasedOnPrestacionesMutationResult = Apollo.MutationResult<CalculatePriceBasedOnPrestacionesMutation>;
export type CalculatePriceBasedOnPrestacionesMutationOptions = Apollo.BaseMutationOptions<CalculatePriceBasedOnPrestacionesMutation, CalculatePriceBasedOnPrestacionesMutationVariables>;
export const CreatePatientDocument = gql`
    mutation createPatient($patients: [PatientCreateInput!]!, $check_rut_duplicate: Boolean) {
  createPatient(patients: $patients, check_rut_duplicate: $check_rut_duplicate) {
    message
  }
}
    `;
export type CreatePatientMutationFn = Apollo.MutationFunction<CreatePatientMutation, CreatePatientMutationVariables>;

/**
 * __useCreatePatientMutation__
 *
 * To run a mutation, you first call `useCreatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientMutation, { data, loading, error }] = useCreatePatientMutation({
 *   variables: {
 *      patients: // value for 'patients'
 *      check_rut_duplicate: // value for 'check_rut_duplicate'
 *   },
 * });
 */
export function useCreatePatientMutation(baseOptions?: Apollo.MutationHookOptions<CreatePatientMutation, CreatePatientMutationVariables>) {
        return Apollo.useMutation<CreatePatientMutation, CreatePatientMutationVariables>(CreatePatientDocument, baseOptions);
      }
export type CreatePatientMutationHookResult = ReturnType<typeof useCreatePatientMutation>;
export type CreatePatientMutationResult = Apollo.MutationResult<CreatePatientMutation>;
export type CreatePatientMutationOptions = Apollo.BaseMutationOptions<CreatePatientMutation, CreatePatientMutationVariables>;
export const UpdateIndividualPatientDocument = gql`
    mutation updateIndividualPatient($id: ID!, $patient: PatientUpdateInput!) {
  updatePatient(id: $id, patient: $patient) {
    message
    patient {
      id
      given_name
      family_name
      date_of_birth
      email
      phone_number
      dni
      dni_type
      sex
      nationality
      comuna
      address
      workplace
      diseases
      comments
    }
  }
}
    `;
export type UpdateIndividualPatientMutationFn = Apollo.MutationFunction<UpdateIndividualPatientMutation, UpdateIndividualPatientMutationVariables>;

/**
 * __useUpdateIndividualPatientMutation__
 *
 * To run a mutation, you first call `useUpdateIndividualPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIndividualPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIndividualPatientMutation, { data, loading, error }] = useUpdateIndividualPatientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patient: // value for 'patient'
 *   },
 * });
 */
export function useUpdateIndividualPatientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIndividualPatientMutation, UpdateIndividualPatientMutationVariables>) {
        return Apollo.useMutation<UpdateIndividualPatientMutation, UpdateIndividualPatientMutationVariables>(UpdateIndividualPatientDocument, baseOptions);
      }
export type UpdateIndividualPatientMutationHookResult = ReturnType<typeof useUpdateIndividualPatientMutation>;
export type UpdateIndividualPatientMutationResult = Apollo.MutationResult<UpdateIndividualPatientMutation>;
export type UpdateIndividualPatientMutationOptions = Apollo.BaseMutationOptions<UpdateIndividualPatientMutation, UpdateIndividualPatientMutationVariables>;
export const DeletePatientDocument = gql`
    mutation deletePatient($id: ID!) {
  deletePatient(id: $id) {
    message
  }
}
    `;
export type DeletePatientMutationFn = Apollo.MutationFunction<DeletePatientMutation, DeletePatientMutationVariables>;

/**
 * __useDeletePatientMutation__
 *
 * To run a mutation, you first call `useDeletePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientMutation, { data, loading, error }] = useDeletePatientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePatientMutation(baseOptions?: Apollo.MutationHookOptions<DeletePatientMutation, DeletePatientMutationVariables>) {
        return Apollo.useMutation<DeletePatientMutation, DeletePatientMutationVariables>(DeletePatientDocument, baseOptions);
      }
export type DeletePatientMutationHookResult = ReturnType<typeof useDeletePatientMutation>;
export type DeletePatientMutationResult = Apollo.MutationResult<DeletePatientMutation>;
export type DeletePatientMutationOptions = Apollo.BaseMutationOptions<DeletePatientMutation, DeletePatientMutationVariables>;
export const BoletaDocument = gql`
    query boleta($id: ID!) {
  boleta(id: $id) {
    id
    file_url
    appointment_id
    patient_id
    created_at
    storage_location
  }
}
    `;

/**
 * __useBoletaQuery__
 *
 * To run a query within a React component, call `useBoletaQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoletaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoletaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBoletaQuery(baseOptions: Apollo.QueryHookOptions<BoletaQuery, BoletaQueryVariables>) {
        return Apollo.useQuery<BoletaQuery, BoletaQueryVariables>(BoletaDocument, baseOptions);
      }
export function useBoletaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BoletaQuery, BoletaQueryVariables>) {
          return Apollo.useLazyQuery<BoletaQuery, BoletaQueryVariables>(BoletaDocument, baseOptions);
        }
export type BoletaQueryHookResult = ReturnType<typeof useBoletaQuery>;
export type BoletaLazyQueryHookResult = ReturnType<typeof useBoletaLazyQuery>;
export type BoletaQueryResult = Apollo.QueryResult<BoletaQuery, BoletaQueryVariables>;
export const BoletasDocument = gql`
    query boletas($begin_date: String, $end_date: String) {
  boletas(begin_date: $begin_date, end_date: $end_date) {
    id
    file_url
    appointment_id
    patient_id
    created_at
    storage_location
  }
}
    `;

/**
 * __useBoletasQuery__
 *
 * To run a query within a React component, call `useBoletasQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoletasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoletasQuery({
 *   variables: {
 *      begin_date: // value for 'begin_date'
 *      end_date: // value for 'end_date'
 *   },
 * });
 */
export function useBoletasQuery(baseOptions?: Apollo.QueryHookOptions<BoletasQuery, BoletasQueryVariables>) {
        return Apollo.useQuery<BoletasQuery, BoletasQueryVariables>(BoletasDocument, baseOptions);
      }
export function useBoletasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BoletasQuery, BoletasQueryVariables>) {
          return Apollo.useLazyQuery<BoletasQuery, BoletasQueryVariables>(BoletasDocument, baseOptions);
        }
export type BoletasQueryHookResult = ReturnType<typeof useBoletasQuery>;
export type BoletasLazyQueryHookResult = ReturnType<typeof useBoletasLazyQuery>;
export type BoletasQueryResult = Apollo.QueryResult<BoletasQuery, BoletasQueryVariables>;
export const EmailBoletasDocument = gql`
    mutation emailBoletas($patient_id: ID!, $appointment_id: ID!) {
  emailBoletas(patient_id: $patient_id, appointment_id: $appointment_id) {
    message
  }
}
    `;
export type EmailBoletasMutationFn = Apollo.MutationFunction<EmailBoletasMutation, EmailBoletasMutationVariables>;

/**
 * __useEmailBoletasMutation__
 *
 * To run a mutation, you first call `useEmailBoletasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailBoletasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailBoletasMutation, { data, loading, error }] = useEmailBoletasMutation({
 *   variables: {
 *      patient_id: // value for 'patient_id'
 *      appointment_id: // value for 'appointment_id'
 *   },
 * });
 */
export function useEmailBoletasMutation(baseOptions?: Apollo.MutationHookOptions<EmailBoletasMutation, EmailBoletasMutationVariables>) {
        return Apollo.useMutation<EmailBoletasMutation, EmailBoletasMutationVariables>(EmailBoletasDocument, baseOptions);
      }
export type EmailBoletasMutationHookResult = ReturnType<typeof useEmailBoletasMutation>;
export type EmailBoletasMutationResult = Apollo.MutationResult<EmailBoletasMutation>;
export type EmailBoletasMutationOptions = Apollo.BaseMutationOptions<EmailBoletasMutation, EmailBoletasMutationVariables>;
export const UploadBoletaDocument = gql`
    mutation uploadBoleta($boleta: BoletaFileInput!) {
  submitBoleta(boleta: $boleta) {
    message
  }
}
    `;
export type UploadBoletaMutationFn = Apollo.MutationFunction<UploadBoletaMutation, UploadBoletaMutationVariables>;

/**
 * __useUploadBoletaMutation__
 *
 * To run a mutation, you first call `useUploadBoletaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBoletaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBoletaMutation, { data, loading, error }] = useUploadBoletaMutation({
 *   variables: {
 *      boleta: // value for 'boleta'
 *   },
 * });
 */
export function useUploadBoletaMutation(baseOptions?: Apollo.MutationHookOptions<UploadBoletaMutation, UploadBoletaMutationVariables>) {
        return Apollo.useMutation<UploadBoletaMutation, UploadBoletaMutationVariables>(UploadBoletaDocument, baseOptions);
      }
export type UploadBoletaMutationHookResult = ReturnType<typeof useUploadBoletaMutation>;
export type UploadBoletaMutationResult = Apollo.MutationResult<UploadBoletaMutation>;
export type UploadBoletaMutationOptions = Apollo.BaseMutationOptions<UploadBoletaMutation, UploadBoletaMutationVariables>;
export const EmitirBoletaPcrDocument = gql`
    mutation emitirBoletaPCR($boletas: [BoletaInput!]!) {
  emitirBoletaPCR(boletas: $boletas) {
    message
  }
}
    `;
export type EmitirBoletaPcrMutationFn = Apollo.MutationFunction<EmitirBoletaPcrMutation, EmitirBoletaPcrMutationVariables>;

/**
 * __useEmitirBoletaPcrMutation__
 *
 * To run a mutation, you first call `useEmitirBoletaPcrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmitirBoletaPcrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emitirBoletaPcrMutation, { data, loading, error }] = useEmitirBoletaPcrMutation({
 *   variables: {
 *      boletas: // value for 'boletas'
 *   },
 * });
 */
export function useEmitirBoletaPcrMutation(baseOptions?: Apollo.MutationHookOptions<EmitirBoletaPcrMutation, EmitirBoletaPcrMutationVariables>) {
        return Apollo.useMutation<EmitirBoletaPcrMutation, EmitirBoletaPcrMutationVariables>(EmitirBoletaPcrDocument, baseOptions);
      }
export type EmitirBoletaPcrMutationHookResult = ReturnType<typeof useEmitirBoletaPcrMutation>;
export type EmitirBoletaPcrMutationResult = Apollo.MutationResult<EmitirBoletaPcrMutation>;
export type EmitirBoletaPcrMutationOptions = Apollo.BaseMutationOptions<EmitirBoletaPcrMutation, EmitirBoletaPcrMutationVariables>;
export const CompressBoletasDocument = gql`
    mutation compressBoletas($boletas: [ID!]!) {
  compressBoletas(ids: $boletas) {
    file_url
  }
}
    `;
export type CompressBoletasMutationFn = Apollo.MutationFunction<CompressBoletasMutation, CompressBoletasMutationVariables>;

/**
 * __useCompressBoletasMutation__
 *
 * To run a mutation, you first call `useCompressBoletasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompressBoletasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [compressBoletasMutation, { data, loading, error }] = useCompressBoletasMutation({
 *   variables: {
 *      boletas: // value for 'boletas'
 *   },
 * });
 */
export function useCompressBoletasMutation(baseOptions?: Apollo.MutationHookOptions<CompressBoletasMutation, CompressBoletasMutationVariables>) {
        return Apollo.useMutation<CompressBoletasMutation, CompressBoletasMutationVariables>(CompressBoletasDocument, baseOptions);
      }
export type CompressBoletasMutationHookResult = ReturnType<typeof useCompressBoletasMutation>;
export type CompressBoletasMutationResult = Apollo.MutationResult<CompressBoletasMutation>;
export type CompressBoletasMutationOptions = Apollo.BaseMutationOptions<CompressBoletasMutation, CompressBoletasMutationVariables>;
export const DeleteBoletaDocument = gql`
    mutation deleteBoleta($id: ID!) {
  deleteBoleta(id: $id) {
    message
  }
}
    `;
export type DeleteBoletaMutationFn = Apollo.MutationFunction<DeleteBoletaMutation, DeleteBoletaMutationVariables>;

/**
 * __useDeleteBoletaMutation__
 *
 * To run a mutation, you first call `useDeleteBoletaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBoletaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBoletaMutation, { data, loading, error }] = useDeleteBoletaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBoletaMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBoletaMutation, DeleteBoletaMutationVariables>) {
        return Apollo.useMutation<DeleteBoletaMutation, DeleteBoletaMutationVariables>(DeleteBoletaDocument, baseOptions);
      }
export type DeleteBoletaMutationHookResult = ReturnType<typeof useDeleteBoletaMutation>;
export type DeleteBoletaMutationResult = Apollo.MutationResult<DeleteBoletaMutation>;
export type DeleteBoletaMutationOptions = Apollo.BaseMutationOptions<DeleteBoletaMutation, DeleteBoletaMutationVariables>;
export const FacturaDocument = gql`
    query factura($id: ID!) {
  factura(id: $id) {
    id
    file_url
    appointment_id
    created_at
    storage_location
  }
}
    `;

/**
 * __useFacturaQuery__
 *
 * To run a query within a React component, call `useFacturaQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacturaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacturaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFacturaQuery(baseOptions: Apollo.QueryHookOptions<FacturaQuery, FacturaQueryVariables>) {
        return Apollo.useQuery<FacturaQuery, FacturaQueryVariables>(FacturaDocument, baseOptions);
      }
export function useFacturaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FacturaQuery, FacturaQueryVariables>) {
          return Apollo.useLazyQuery<FacturaQuery, FacturaQueryVariables>(FacturaDocument, baseOptions);
        }
export type FacturaQueryHookResult = ReturnType<typeof useFacturaQuery>;
export type FacturaLazyQueryHookResult = ReturnType<typeof useFacturaLazyQuery>;
export type FacturaQueryResult = Apollo.QueryResult<FacturaQuery, FacturaQueryVariables>;
export const FacturasDocument = gql`
    query facturas($begin_date: String, $end_date: String) {
  facturas(begin_date: $begin_date, end_date: $end_date) {
    id
    file_url
    appointment_id
    created_at
    storage_location
  }
}
    `;

/**
 * __useFacturasQuery__
 *
 * To run a query within a React component, call `useFacturasQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacturasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacturasQuery({
 *   variables: {
 *      begin_date: // value for 'begin_date'
 *      end_date: // value for 'end_date'
 *   },
 * });
 */
export function useFacturasQuery(baseOptions?: Apollo.QueryHookOptions<FacturasQuery, FacturasQueryVariables>) {
        return Apollo.useQuery<FacturasQuery, FacturasQueryVariables>(FacturasDocument, baseOptions);
      }
export function useFacturasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FacturasQuery, FacturasQueryVariables>) {
          return Apollo.useLazyQuery<FacturasQuery, FacturasQueryVariables>(FacturasDocument, baseOptions);
        }
export type FacturasQueryHookResult = ReturnType<typeof useFacturasQuery>;
export type FacturasLazyQueryHookResult = ReturnType<typeof useFacturasLazyQuery>;
export type FacturasQueryResult = Apollo.QueryResult<FacturasQuery, FacturasQueryVariables>;
export const EmailFacturasDocument = gql`
    mutation emailFacturas($appointment_id: ID!) {
  emailFacturas(appointment_id: $appointment_id) {
    message
  }
}
    `;
export type EmailFacturasMutationFn = Apollo.MutationFunction<EmailFacturasMutation, EmailFacturasMutationVariables>;

/**
 * __useEmailFacturasMutation__
 *
 * To run a mutation, you first call `useEmailFacturasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailFacturasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailFacturasMutation, { data, loading, error }] = useEmailFacturasMutation({
 *   variables: {
 *      appointment_id: // value for 'appointment_id'
 *   },
 * });
 */
export function useEmailFacturasMutation(baseOptions?: Apollo.MutationHookOptions<EmailFacturasMutation, EmailFacturasMutationVariables>) {
        return Apollo.useMutation<EmailFacturasMutation, EmailFacturasMutationVariables>(EmailFacturasDocument, baseOptions);
      }
export type EmailFacturasMutationHookResult = ReturnType<typeof useEmailFacturasMutation>;
export type EmailFacturasMutationResult = Apollo.MutationResult<EmailFacturasMutation>;
export type EmailFacturasMutationOptions = Apollo.BaseMutationOptions<EmailFacturasMutation, EmailFacturasMutationVariables>;
export const UploadFacturaDocument = gql`
    mutation uploadFactura($factura: FacturaFileInput!) {
  submitFactura(factura: $factura) {
    message
  }
}
    `;
export type UploadFacturaMutationFn = Apollo.MutationFunction<UploadFacturaMutation, UploadFacturaMutationVariables>;

/**
 * __useUploadFacturaMutation__
 *
 * To run a mutation, you first call `useUploadFacturaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFacturaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFacturaMutation, { data, loading, error }] = useUploadFacturaMutation({
 *   variables: {
 *      factura: // value for 'factura'
 *   },
 * });
 */
export function useUploadFacturaMutation(baseOptions?: Apollo.MutationHookOptions<UploadFacturaMutation, UploadFacturaMutationVariables>) {
        return Apollo.useMutation<UploadFacturaMutation, UploadFacturaMutationVariables>(UploadFacturaDocument, baseOptions);
      }
export type UploadFacturaMutationHookResult = ReturnType<typeof useUploadFacturaMutation>;
export type UploadFacturaMutationResult = Apollo.MutationResult<UploadFacturaMutation>;
export type UploadFacturaMutationOptions = Apollo.BaseMutationOptions<UploadFacturaMutation, UploadFacturaMutationVariables>;
export const DeleteFacturaDocument = gql`
    mutation deleteFactura($id: ID!) {
  deleteFactura(id: $id) {
    message
  }
}
    `;
export type DeleteFacturaMutationFn = Apollo.MutationFunction<DeleteFacturaMutation, DeleteFacturaMutationVariables>;

/**
 * __useDeleteFacturaMutation__
 *
 * To run a mutation, you first call `useDeleteFacturaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFacturaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFacturaMutation, { data, loading, error }] = useDeleteFacturaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFacturaMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFacturaMutation, DeleteFacturaMutationVariables>) {
        return Apollo.useMutation<DeleteFacturaMutation, DeleteFacturaMutationVariables>(DeleteFacturaDocument, baseOptions);
      }
export type DeleteFacturaMutationHookResult = ReturnType<typeof useDeleteFacturaMutation>;
export type DeleteFacturaMutationResult = Apollo.MutationResult<DeleteFacturaMutation>;
export type DeleteFacturaMutationOptions = Apollo.BaseMutationOptions<DeleteFacturaMutation, DeleteFacturaMutationVariables>;
export const UploadOrdenDocument = gql`
    mutation uploadOrden($orden: OrdenInput!) {
  submitOrden(orden: $orden) {
    message
  }
}
    `;
export type UploadOrdenMutationFn = Apollo.MutationFunction<UploadOrdenMutation, UploadOrdenMutationVariables>;

/**
 * __useUploadOrdenMutation__
 *
 * To run a mutation, you first call `useUploadOrdenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadOrdenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadOrdenMutation, { data, loading, error }] = useUploadOrdenMutation({
 *   variables: {
 *      orden: // value for 'orden'
 *   },
 * });
 */
export function useUploadOrdenMutation(baseOptions?: Apollo.MutationHookOptions<UploadOrdenMutation, UploadOrdenMutationVariables>) {
        return Apollo.useMutation<UploadOrdenMutation, UploadOrdenMutationVariables>(UploadOrdenDocument, baseOptions);
      }
export type UploadOrdenMutationHookResult = ReturnType<typeof useUploadOrdenMutation>;
export type UploadOrdenMutationResult = Apollo.MutationResult<UploadOrdenMutation>;
export type UploadOrdenMutationOptions = Apollo.BaseMutationOptions<UploadOrdenMutation, UploadOrdenMutationVariables>;
export const GenerateOrdenDocument = gql`
    mutation generateOrden($orden: OrdenGeneratorInput!) {
  generateOrden(orden: $orden) {
    message
  }
}
    `;
export type GenerateOrdenMutationFn = Apollo.MutationFunction<GenerateOrdenMutation, GenerateOrdenMutationVariables>;

/**
 * __useGenerateOrdenMutation__
 *
 * To run a mutation, you first call `useGenerateOrdenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateOrdenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateOrdenMutation, { data, loading, error }] = useGenerateOrdenMutation({
 *   variables: {
 *      orden: // value for 'orden'
 *   },
 * });
 */
export function useGenerateOrdenMutation(baseOptions?: Apollo.MutationHookOptions<GenerateOrdenMutation, GenerateOrdenMutationVariables>) {
        return Apollo.useMutation<GenerateOrdenMutation, GenerateOrdenMutationVariables>(GenerateOrdenDocument, baseOptions);
      }
export type GenerateOrdenMutationHookResult = ReturnType<typeof useGenerateOrdenMutation>;
export type GenerateOrdenMutationResult = Apollo.MutationResult<GenerateOrdenMutation>;
export type GenerateOrdenMutationOptions = Apollo.BaseMutationOptions<GenerateOrdenMutation, GenerateOrdenMutationVariables>;
export const UploadResultsDocument = gql`
    mutation uploadResults($results: ResultInput!) {
  submitResults(result: $results) {
    message
    result {
      file_url
    }
  }
}
    `;
export type UploadResultsMutationFn = Apollo.MutationFunction<UploadResultsMutation, UploadResultsMutationVariables>;

/**
 * __useUploadResultsMutation__
 *
 * To run a mutation, you first call `useUploadResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadResultsMutation, { data, loading, error }] = useUploadResultsMutation({
 *   variables: {
 *      results: // value for 'results'
 *   },
 * });
 */
export function useUploadResultsMutation(baseOptions?: Apollo.MutationHookOptions<UploadResultsMutation, UploadResultsMutationVariables>) {
        return Apollo.useMutation<UploadResultsMutation, UploadResultsMutationVariables>(UploadResultsDocument, baseOptions);
      }
export type UploadResultsMutationHookResult = ReturnType<typeof useUploadResultsMutation>;
export type UploadResultsMutationResult = Apollo.MutationResult<UploadResultsMutation>;
export type UploadResultsMutationOptions = Apollo.BaseMutationOptions<UploadResultsMutation, UploadResultsMutationVariables>;
export const EmailResultsDocument = gql`
    mutation emailResults($patient_id: ID!, $appointment_id: ID!) {
  emailResults(patient_id: $patient_id, appointment_id: $appointment_id) {
    message
  }
}
    `;
export type EmailResultsMutationFn = Apollo.MutationFunction<EmailResultsMutation, EmailResultsMutationVariables>;

/**
 * __useEmailResultsMutation__
 *
 * To run a mutation, you first call `useEmailResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailResultsMutation, { data, loading, error }] = useEmailResultsMutation({
 *   variables: {
 *      patient_id: // value for 'patient_id'
 *      appointment_id: // value for 'appointment_id'
 *   },
 * });
 */
export function useEmailResultsMutation(baseOptions?: Apollo.MutationHookOptions<EmailResultsMutation, EmailResultsMutationVariables>) {
        return Apollo.useMutation<EmailResultsMutation, EmailResultsMutationVariables>(EmailResultsDocument, baseOptions);
      }
export type EmailResultsMutationHookResult = ReturnType<typeof useEmailResultsMutation>;
export type EmailResultsMutationResult = Apollo.MutationResult<EmailResultsMutation>;
export type EmailResultsMutationOptions = Apollo.BaseMutationOptions<EmailResultsMutation, EmailResultsMutationVariables>;
export const RequestResultDocument = gql`
    mutation requestResult($samples: [ID!]!) {
  requestResult(samples: $samples) {
    message
  }
}
    `;
export type RequestResultMutationFn = Apollo.MutationFunction<RequestResultMutation, RequestResultMutationVariables>;

/**
 * __useRequestResultMutation__
 *
 * To run a mutation, you first call `useRequestResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResultMutation, { data, loading, error }] = useRequestResultMutation({
 *   variables: {
 *      samples: // value for 'samples'
 *   },
 * });
 */
export function useRequestResultMutation(baseOptions?: Apollo.MutationHookOptions<RequestResultMutation, RequestResultMutationVariables>) {
        return Apollo.useMutation<RequestResultMutation, RequestResultMutationVariables>(RequestResultDocument, baseOptions);
      }
export type RequestResultMutationHookResult = ReturnType<typeof useRequestResultMutation>;
export type RequestResultMutationResult = Apollo.MutationResult<RequestResultMutation>;
export type RequestResultMutationOptions = Apollo.BaseMutationOptions<RequestResultMutation, RequestResultMutationVariables>;
export const EmailResultsGroupDocument = gql`
    mutation emailResultsGroup($appointment_id: ID!) {
  emailResultsGroup(appointment_id: $appointment_id) {
    message
  }
}
    `;
export type EmailResultsGroupMutationFn = Apollo.MutationFunction<EmailResultsGroupMutation, EmailResultsGroupMutationVariables>;

/**
 * __useEmailResultsGroupMutation__
 *
 * To run a mutation, you first call `useEmailResultsGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailResultsGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailResultsGroupMutation, { data, loading, error }] = useEmailResultsGroupMutation({
 *   variables: {
 *      appointment_id: // value for 'appointment_id'
 *   },
 * });
 */
export function useEmailResultsGroupMutation(baseOptions?: Apollo.MutationHookOptions<EmailResultsGroupMutation, EmailResultsGroupMutationVariables>) {
        return Apollo.useMutation<EmailResultsGroupMutation, EmailResultsGroupMutationVariables>(EmailResultsGroupDocument, baseOptions);
      }
export type EmailResultsGroupMutationHookResult = ReturnType<typeof useEmailResultsGroupMutation>;
export type EmailResultsGroupMutationResult = Apollo.MutationResult<EmailResultsGroupMutation>;
export type EmailResultsGroupMutationOptions = Apollo.BaseMutationOptions<EmailResultsGroupMutation, EmailResultsGroupMutationVariables>;
export const DeleteResultDocument = gql`
    mutation deleteResult($id: ID!) {
  deleteResult(id: $id) {
    message
  }
}
    `;
export type DeleteResultMutationFn = Apollo.MutationFunction<DeleteResultMutation, DeleteResultMutationVariables>;

/**
 * __useDeleteResultMutation__
 *
 * To run a mutation, you first call `useDeleteResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResultMutation, { data, loading, error }] = useDeleteResultMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteResultMutation(baseOptions?: Apollo.MutationHookOptions<DeleteResultMutation, DeleteResultMutationVariables>) {
        return Apollo.useMutation<DeleteResultMutation, DeleteResultMutationVariables>(DeleteResultDocument, baseOptions);
      }
export type DeleteResultMutationHookResult = ReturnType<typeof useDeleteResultMutation>;
export type DeleteResultMutationResult = Apollo.MutationResult<DeleteResultMutation>;
export type DeleteResultMutationOptions = Apollo.BaseMutationOptions<DeleteResultMutation, DeleteResultMutationVariables>;
export const SubmitSampleDocument = gql`
    mutation submitSample($sample: SampleInput!) {
  submitSample(sample: $sample) {
    message
  }
}
    `;
export type SubmitSampleMutationFn = Apollo.MutationFunction<SubmitSampleMutation, SubmitSampleMutationVariables>;

/**
 * __useSubmitSampleMutation__
 *
 * To run a mutation, you first call `useSubmitSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSampleMutation, { data, loading, error }] = useSubmitSampleMutation({
 *   variables: {
 *      sample: // value for 'sample'
 *   },
 * });
 */
export function useSubmitSampleMutation(baseOptions?: Apollo.MutationHookOptions<SubmitSampleMutation, SubmitSampleMutationVariables>) {
        return Apollo.useMutation<SubmitSampleMutation, SubmitSampleMutationVariables>(SubmitSampleDocument, baseOptions);
      }
export type SubmitSampleMutationHookResult = ReturnType<typeof useSubmitSampleMutation>;
export type SubmitSampleMutationResult = Apollo.MutationResult<SubmitSampleMutation>;
export type SubmitSampleMutationOptions = Apollo.BaseMutationOptions<SubmitSampleMutation, SubmitSampleMutationVariables>;
export const UpdateSampleDocument = gql`
    mutation updateSample($id: ID!, $sample: SampleInput!) {
  updateSample(id: $id, sample: $sample) {
    message
  }
}
    `;
export type UpdateSampleMutationFn = Apollo.MutationFunction<UpdateSampleMutation, UpdateSampleMutationVariables>;

/**
 * __useUpdateSampleMutation__
 *
 * To run a mutation, you first call `useUpdateSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSampleMutation, { data, loading, error }] = useUpdateSampleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sample: // value for 'sample'
 *   },
 * });
 */
export function useUpdateSampleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSampleMutation, UpdateSampleMutationVariables>) {
        return Apollo.useMutation<UpdateSampleMutation, UpdateSampleMutationVariables>(UpdateSampleDocument, baseOptions);
      }
export type UpdateSampleMutationHookResult = ReturnType<typeof useUpdateSampleMutation>;
export type UpdateSampleMutationResult = Apollo.MutationResult<UpdateSampleMutation>;
export type UpdateSampleMutationOptions = Apollo.BaseMutationOptions<UpdateSampleMutation, UpdateSampleMutationVariables>;
export const DeleteSampleDocument = gql`
    mutation deleteSample($id: ID!) {
  deleteSample(id: $id) {
    message
  }
}
    `;
export type DeleteSampleMutationFn = Apollo.MutationFunction<DeleteSampleMutation, DeleteSampleMutationVariables>;

/**
 * __useDeleteSampleMutation__
 *
 * To run a mutation, you first call `useDeleteSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSampleMutation, { data, loading, error }] = useDeleteSampleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSampleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSampleMutation, DeleteSampleMutationVariables>) {
        return Apollo.useMutation<DeleteSampleMutation, DeleteSampleMutationVariables>(DeleteSampleDocument, baseOptions);
      }
export type DeleteSampleMutationHookResult = ReturnType<typeof useDeleteSampleMutation>;
export type DeleteSampleMutationResult = Apollo.MutationResult<DeleteSampleMutation>;
export type DeleteSampleMutationOptions = Apollo.BaseMutationOptions<DeleteSampleMutation, DeleteSampleMutationVariables>;