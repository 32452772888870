import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import { useFirebaseApp } from 'reactfire';
import Logo from 'src/components/Logo';
import { Store } from 'pullstate';
import { BusinessLine } from 'src/generated/graphql';
import { createStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {},
    avatar: {
      width: 60,
      height: 60
    },
    formControl: {
      marginLeft: 20,
      color: 'white'
      // backgroundColor: 'white',
    }
  })
);

export const BusinessLineStore = new Store<{ business_line: BusinessLine }>({
  business_line: BusinessLine.Covid
});

const TopBar: React.FC<{
  className?: string;
  onMobileNavOpen?: () => void;
}> = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const [notifications] = useState([]);
  // Import firebase
  const firebase = useFirebaseApp();

  const handleLogout = () => {
    // Log out function
    firebase.auth().signOut();
  };

  const businessLine = BusinessLineStore.useState(r => r.business_line);

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit" onClick={handleLogout}>
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <FormControl className={classes.formControl}>
          <InputLabel className={classes.formControl}>Línea</InputLabel>
          <Select
            variant="outlined"
            value={businessLine}
            fullWidth
            onChange={item => {
              BusinessLineStore.update(state => {
                state.business_line = item.target.value as BusinessLine;
              });
            }}
            inputProps={{
              className: classes.formControl
            }}
          >
            <MenuItem value={BusinessLine.Covid}>COVID</MenuItem>
            <MenuItem value={BusinessLine.Lab}>Laboratorio</MenuItem>
          </Select>
        </FormControl>
        <Box flexGrow={1} />
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
