import {
  Card,
  CardHeader,
  Button,
  Divider,
  CardContent,
  ListItemText,
  List,
  Grid,
  IconButton
} from '@material-ui/core';
import { Delete, Send } from '@material-ui/icons';
import dayjs from 'dayjs';
import { ClipLoader, ScaleLoader } from 'react-spinners';

import { ListItemLink } from '../ListItemLink';

import {
  Boleta,
  useDeleteBoletaMutation,
  useEmailBoletasMutation
} from 'src/generated/graphql';

interface IBoletasComponentProps {
  patient_id: string;
  boletas: Boleta[];
  appointment_id?: string;
}

export const BoletasComponent = ({
  patient_id,
  appointment_id,
  boletas
}: IBoletasComponentProps) => {
  /* ------------------------------ GraphQL Hooks ----------------------------- */

  const [
    emailBoletas,
    { loading: emailingBoletas }
  ] = useEmailBoletasMutation();

  const [deleteBoleta, { loading: deletingBoleta }] = useDeleteBoletaMutation({
    notifyOnNetworkStatusChange: true,
    refetchQueries: ['fetchIndividualAppointment']
  });

  /* -------------------------------------------------------------------------- */

  return (
    <Card>
      <CardHeader
        title="Boletas"
        subheader={`ID paciente: ${patient_id}`}
        action={
          appointment_id &&
          (emailingBoletas ? (
            <ScaleLoader />
          ) : (
            <Button
              size="medium"
              color="primary"
              variant="outlined"
              onClick={() =>
                emailBoletas({
                  variables: {
                    appointment_id: appointment_id,
                    patient_id: patient_id
                  }
                })
              }
            >
              <Send />
              Enviar boletas y órdenes por email
            </Button>
          ))
        }
      />
      <Divider />
      <CardContent>
        {boletas.map((boleta, index) => (
          <List
            component="nav"
            aria-label="secondary mailbox folders"
            key={`boletas_patient_${boleta.id}`}
          >
            <Grid container>
              <Grid item md={10}>
                <ListItemLink href={boleta?.file_url || ''}>
                  <ListItemText
                    primary={
                      boleta?.created_at
                        ? dayjs(boleta?.created_at)
                            .tz('America/Santiago')
                            .format('DD/MM/YYYY - HH:mm')
                        : 'Fecha indefinida'
                    }
                  />
                </ListItemLink>
              </Grid>

              <Grid item md={2}>
                {deletingBoleta ? (
                  <ClipLoader />
                ) : (
                  <IconButton
                    color="primary"
                    onClick={() =>
                      deleteBoleta({
                        variables: {
                          id: boleta.id
                        }
                      })
                    }
                  >
                    <Delete />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </List>
        ))}
      </CardContent>
    </Card>
  );
};
