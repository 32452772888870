import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'firebase/firestore';
import 'firebase/auth';
import { FirebaseAppProvider } from 'reactfire';
import * as serviceWorker from './serviceWorker';
import App from './App';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css files
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_DB_URL,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_APP_ID
// };

const firebaseConfig = {
  apiKey: 'AIzaSyDx5sAGiSiYvdCTUlL4JhOq5gNL_C9g8-o',
  authDomain: 'portal.midiagnostico.cl',
  projectId: 'midiagnostico-cl',
  storageBucket: 'midiagnostico-cl.appspot.com',
  messagingSenderId: '313501459593',
  appId: '1:313501459593:web:c8b64825a8d3eb57121258',
  measurementId: 'G-00BH7HTHGL'
};

// const client = new ApolloClient({
//   cache: new InMemoryCache(),
//   uri: 'http://127.0.0.1:5002/midiagnostico-cl/us-central1/api/graphql'
// });

ReactDOM.render(
  <BrowserRouter>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <Suspense fallback={<h3>Loading...</h3>}>
        <App />
      </Suspense>
    </FirebaseAppProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.unregister();
