import { Dispatch, SetStateAction, useState } from 'react';
import { Add, Refresh } from '@material-ui/icons';
import MaterialTable, { Action, Column } from 'material-table';
import { BounceLoader, RingLoader } from 'react-spinners';
import { tableIcons, tableLocalization } from 'src/theme/tableSettings';
import {
  PrestacionesPriceDetermination,
  useFetchPrestacionesQuery
} from 'src/generated/graphql';

export interface IPatientsSelectTableProps {
  finishedCallback?: () => void;
  prestaciones?: RenderedPrestacion[];
  setPrestaciones?: Dispatch<SetStateAction<RenderedPrestacion[]>>;
  refetch?: () => void;
  loading?: boolean;
  //   addingPatients?: boolean;
}
export interface RenderedPrestacion {
  id: string;
  fonasa_code: string;
  description: string;
  qty: number;
}
export default (props: IPatientsSelectTableProps) => {
  const columns: Column<RenderedPrestacion>[] = [
    { title: 'Nombre', field: 'description', editable: 'never' },
    { title: 'Código FONASA', field: 'fonasa_code', editable: 'never' },
    { title: 'Cantidad', field: 'qty', type: 'numeric' }
  ];

  const actions: Action<RenderedPrestacion>[] = [
    {
      icon: () => <Refresh />,
      tooltip: 'Refrescar',
      isFreeAction: true,
      onClick: () => props.refetch && props.refetch()
    }
  ];

  return (
    <>
      {!props.loading && props.prestaciones ? (
        <MaterialTable
          icons={tableIcons}
          localization={tableLocalization}
          options={{
            rowStyle: {
              fontFamily: 'Roboto'
            }
          }}
          actions={actions}
          title="Prestaciones"
          columns={columns}
          data={props.prestaciones}
          cellEditable={{
            onCellEditApproved: async (
              newValue: number,
              _oldValue,
              rowData,
              _columnDef
            ) => {
              const newState =
                (props.prestaciones &&
                  props.prestaciones.map(p =>
                    p.id === rowData.id ? { ...p, qty: newValue } : p
                  )) ||
                [];
              props.setPrestaciones && props.setPrestaciones(newState);
            }
          }}
        />
      ) : (
        <RingLoader />
      )}
    </>
  );
};
