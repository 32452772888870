import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  CardActions,
  Button,
  makeStyles
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { Controller, useForm } from 'react-hook-form';
import { ScaleLoader } from 'react-spinners';
import { Orden, Patient } from 'src/generated/graphql';
import { useUploadOrdenes } from 'src/utils/uploadOrdenes';
import { LinearProgressWithLabel } from '../LinearProgressWithLabel';

interface OrdenForm extends Orden {
  files: File[];
}

const useStyles = makeStyles(_theme => ({
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
  dropzone: {
    height: '50%',
    minHeight: 20
  }
}));

export const UploadOrdenesComponent = (props: {
  patient: Patient;
  appointment_id: string;
}) => {
  /* ------------------------------ General Hooks ----------------------------- */

  const classes = useStyles();

  /* ------------------------------ GraphQL Hooks ----------------------------- */

  const {
    progress: ordenUploadProgress,
    uploadFiles: uploadOrden,
    uploading: uploadingOrden
  } = useUploadOrdenes();

  /* ------------------------------- Form Hooks ------------------------------- */

  const { control: ordenControl, handleSubmit } = useForm<OrdenForm>();

  const onSubmitOrdenes = handleSubmit(data => {
    if (data.files && props.appointment_id) {
      uploadOrden(data.files, props.patient.id, props.appointment_id);
    }
  });
  return (
    <Card>
      <CardHeader title="Subir nueva orden" />
      <Divider />
      <CardContent>
        <Controller
          control={ordenControl}
          name="files"
          render={({ field }) => (
            <DropzoneArea
              {...field}
              filesLimit={4}
              acceptedFiles={['image/*', 'application/pdf']}
              dropzoneText="Arrastre archivos o haga click para seleccionar"
              getFileLimitExceedMessage={(value: number) =>
                `Número máximos de archivos excedido (${value})`
              }
              getFileAddedMessage={(fileName: string) =>
                `${fileName} añadido exitosamente`
              }
              getFileRemovedMessage={(fileName: string) =>
                `${fileName} removido exitosamente`
              }
              getDropRejectMessage={(rejectedFile: File) =>
                `${rejectedFile.name} rechazado`
              }
              showPreviews={true}
              showPreviewsInDropzone={false}
              useChipsForPreview
              previewGridProps={{
                container: {
                  spacing: 1,
                  direction: 'row'
                }
              }}
              previewChipProps={{
                classes: {
                  root: classes.previewChip
                }
              }}
              previewText="Archivos seleccionados"
              dropzoneClass={classes.dropzone}
            />
          )}
        />
        <LinearProgressWithLabel value={ordenUploadProgress} />
      </CardContent>
      <CardActions>
        {uploadingOrden ? (
          <ScaleLoader />
        ) : (
          <Button
            size="medium"
            color="primary"
            variant="outlined"
            onClick={onSubmitOrdenes}
          >
            Subir archivos
          </Button>
        )}
      </CardActions>
    </Card>
  );
};
