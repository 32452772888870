import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
  DialogActions,
  Button,
  Grid
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Laboratories,
  Sampletypes,
  useCalculatePriceBasedOnPrestacionesMutation,
  useFetchPrestacionesQuery
} from 'src/generated/graphql';
import PrestacionesSelectTable, {
  RenderedPrestacion
} from '../Prestaciones/PrestacionesSelectTable';

interface ICalculatePriceBasedOnPrestacionesParams {
  open: boolean;
  handleClose(): void;
  appointment_id: string;
  refetchAppointment(): void;
}

export default (props: ICalculatePriceBasedOnPrestacionesParams) => {
  const [lol, setLol] = useState<RenderedPrestacion[]>([]);
  /* -------------------------------------------------------------------------- */
  /*                                 Form Hooks                                 */
  /* -------------------------------------------------------------------------- */

  // const {} = useForm<>()
  /* -------------------------------------------------------------------------- */
  /*                                GraphQL Hooks                               */
  /* -------------------------------------------------------------------------- */

  /* --------------------------------- Queries -------------------------------- */

  const { data, loading, refetch } = useFetchPrestacionesQuery();

  /* -------------------------------- Mutations ------------------------------- */
  const [submitCalculation] = useCalculatePriceBasedOnPrestacionesMutation();

  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (!loading)
      data?.availablePrestaciones &&
        setLol(data?.availablePrestaciones.map(p => ({ ...p, qty: 0 })));
  }, [loading]);

  /* -------------------------------------------------------------------------- */
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="lg"
      PaperProps={{
        style: {
          backgroundColor: 'whitesmoke'
        }
      }}
    >
      <DialogTitle id="form-dialog-title">Calcular Precio de Cita</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Aquí puedes agregar prestaciones para calcular el precio de la cita
        </DialogContentText>
        {/* <Grid container spacing={3}> */}
        {/* {JSON.stringify(availablePrestaciones)} */}
        <PrestacionesSelectTable
          prestaciones={lol}
          setPrestaciones={setLol}
          refetch={refetch}
          loading={loading}
        />
        {/* </Grid> */}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={async () => {
            const dataToSend = lol
              .filter(p => p.qty !== 0)
              .map(p => ({
                id: p.id,
                qty: p.qty
              }));
            await submitCalculation({
              variables: {
                appointment_id: props.appointment_id,
                prestaciones: dataToSend
              }
            });
            props.handleClose();
            props.refetchAppointment();
          }}
        >
          Enviar
        </Button>
        {/* <Button
          onClick={() => {
            resetForm();
            props.handleClose();
          }}
          color="primary"
        >
          Cancelar
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};
