import React, { useEffect, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  makeStyles,
  Grid,
  Card,
  CardHeader,
  Divider,
  CardContent,
  TextField,
  MenuItem,
  CardActions,
  Button,
  Container,
  Box,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  CircularProgress
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { ScaleLoader, PacmanLoader } from 'react-spinners';
import { useHistory } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useUser } from 'reactfire';
import {
  AppointmentCreateInput,
  AppointmentStatus,
  PaymentStatus,
  useCreateAppointmentMutation,
  useFetchProductsQuery,
  useFetchStaffQuery,
  UserRole
} from 'src/generated/graphql';
import Page from 'src/components/Page';
import {
  PatientsSelectTable,
  RenderedPatient
} from 'src/components/Patients/PatientsSelectTable';
import { Autocomplete } from '@material-ui/lab';
const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  }
}));
interface AppointmentForm
  extends Omit<
    AppointmentCreateInput,
    'patients' | 'datetime' | 'staff' | 'number_of_people'
  > {
  datetime: Dayjs;
  staff_email?: string;
  number_of_people: string;
}

const AppointmentCreateView = () => {
  /* ---------------------- General Hooks and Definitions --------------------- */

  const classes = useStyles();
  const history = useHistory();
  const { data: user } = useUser();

  const selectedPatientsRef = useRef<string[]>([]);
  const onPatientsSelectionChange = (
    selectedPatients: RenderedPatient | RenderedPatient[]
  ) => {
    if (Array.isArray(selectedPatients)) {
      selectedPatientsRef.current = selectedPatients.map(patient => patient.id);
    }
  };

  const [userRole, setUserRole] = useState<UserRole>(UserRole.Staff);

  const determineRole = async () => {
    const token = await user.getIdTokenResult();
    setUserRole(token.claims.role);
  };

  useEffect(() => {
    determineRole();
  }, [user]);

  const onSubmit = (data: AppointmentForm) => {
    const parsedDate = dayjs
      .tz(data.datetime.format('YYYY-MM-DD HH:mm'), 'America/Santiago')
      .toISOString();
    createAppointment({
      variables: {
        appointment: {
          ...data,
          datetime: parsedDate,
          patients: selectedPatientsRef.current,
          number_of_people: parseInt(data.number_of_people)
        }
      }
    });
    history.go(-1);
  };

  /* ------------------------------ GraphQL Hooks ----------------------------- */

  const [
    createAppointment,
    { loading: creatingAppointment }
  ] = useCreateAppointmentMutation({
    notifyOnNetworkStatusChange: true,
    refetchQueries: ['fetchAppointments']
  });

  const { data: staff } = useFetchStaffQuery();
  const {
    data: availableProducts,
    loading: loadingProducts,
    refetch: refetchProducts
  } = useFetchProductsQuery({
    notifyOnNetworkStatusChange: true
  });

  /* ------------------------------- Form Hooks ------------------------------- */

  const { control, handleSubmit, watch } = useForm<AppointmentForm>({
    defaultValues: {
      datetime: dayjs()
    }
  });

  const is_institution = watch('is_institution');

  /* -------------------------------------------------------------------------- */
  return (
    <Page className={classes.root} title={`Nueva Cita`}>
      <Container maxWidth={false}>
        <Box mt={3}>
          {creatingAppointment ? (
            <PacmanLoader />
          ) : (
            <>
              <IconButton color="primary" onClick={() => history.go(-1)}>
                <ArrowBack />
              </IconButton>
              {/* @ts-expect-error dayjs incompatibilities */}
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <Card>
                      <CardHeader
                        title="Nueva Cita"
                        subheader={`Nota: No se sincroniza con Zoho`}
                      />
                      <Divider />
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item md={3}>
                            <Controller
                              control={control}
                              name="datetime"
                              render={({ field }) => (
                                <KeyboardDateTimePicker
                                  fullWidth
                                  disableToolbar
                                  variant="inline"
                                  inputVariant="outlined"
                                  format="DD/MM/YYYY HH:mm"
                                  placeholder="10/10/2010"
                                  margin="normal"
                                  id="date-picker-inline"
                                  openTo="date"
                                  ampm={false}
                                  minutesStep={5}
                                  views={['date', 'hours', 'minutes']}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                  }}
                                  label="Fecha y Hora"
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md={3}>
                            <Controller
                              control={control}
                              name="number_of_people"
                              rules={{
                                required: 'Debes introducir número de personas',
                                // setValueAs: value => parseInt(value),
                                minLength: 1
                              }}
                              render={({ field, fieldState: { error } }) => (
                                <TextField
                                  fullWidth
                                  label="Número de Personas"
                                  variant="outlined"
                                  margin="normal"
                                  InputLabelProps={{ shrink: true }}
                                  type="number"
                                  {...field}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md={3}>
                            <Controller
                              name="status"
                              control={control}
                              defaultValue={AppointmentStatus.Processing}
                              render={({ field }) => (
                                <TextField
                                  fullWidth
                                  select
                                  label="Estado"
                                  variant="outlined"
                                  margin="normal"
                                  {...field}
                                >
                                  {[
                                    {
                                      value: AppointmentStatus.Processing,
                                      label: 'Procesando'
                                    },
                                    {
                                      value: AppointmentStatus.ResultsDelivered,
                                      label: 'Resultados Entregados'
                                    },
                                    {
                                      value: AppointmentStatus.Completed,
                                      label: 'Completado'
                                    },
                                    {
                                      value: AppointmentStatus.Cancelled,
                                      label: 'Anulada'
                                    }
                                  ].map(option => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                            />
                          </Grid>
                          <Grid item md={3}>
                            <Controller
                              name="payment_status"
                              control={control}
                              defaultValue={PaymentStatus.Pending}
                              render={({ field }) => (
                                <TextField
                                  fullWidth
                                  select
                                  label="Pago"
                                  variant="outlined"
                                  margin="normal"
                                  {...field}
                                >
                                  {[
                                    {
                                      value: PaymentStatus.Completed,
                                      label: 'Completado'
                                    },
                                    {
                                      value: PaymentStatus.Pending,
                                      label: 'Pendiente'
                                    },
                                    {
                                      value: PaymentStatus.Partial,
                                      label: 'Parcial'
                                    },
                                    {
                                      value: PaymentStatus.Cancelled,
                                      label: 'Anulado'
                                    }
                                  ].map(option => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                            />
                          </Grid>
                          <Grid item md={3}>
                            <Controller
                              name="staff_email"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <TextField
                                  fullWidth
                                  select
                                  disabled={userRole !== 'admin'}
                                  label="Staff"
                                  variant="outlined"
                                  margin="normal"
                                  {...field}
                                >
                                  {staff?.users
                                    .map(staffer => ({
                                      value: staffer.email,
                                      label: `${staffer.given_name} ${staffer.family_name}`
                                    }))
                                    .map(option => (
                                      <MenuItem
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                </TextField>
                              )}
                            />
                          </Grid>
                          <Grid item md={3}>
                            <Controller
                              control={control}
                              name="comuna"
                              rules={{
                                required: 'Debes introducir una comuna',
                                minLength: 1
                              }}
                              render={({ field, fieldState: { error } }) => (
                                <TextField
                                  fullWidth
                                  label="Comuna"
                                  variant="outlined"
                                  margin="normal"
                                  InputLabelProps={{ shrink: true }}
                                  {...field}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              )}
                            />
                          </Grid>

                          <Grid item md={4}>
                            <Controller
                              control={control}
                              name="address"
                              rules={{
                                required: 'Debes introducir una dirección',
                                minLength: 1
                              }}
                              render={({ field, fieldState: { error } }) => (
                                <TextField
                                  fullWidth
                                  label="Dirección"
                                  variant="outlined"
                                  margin="normal"
                                  InputLabelProps={{ shrink: true }}
                                  {...field}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md={2}>
                            <Controller
                              control={control}
                              name="confirmation_code"
                              render={({ field, fieldState: { error } }) => (
                                <TextField
                                  fullWidth
                                  label="Código transacción"
                                  variant="outlined"
                                  margin="normal"
                                  InputLabelProps={{ shrink: true }}
                                  {...field}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              )}
                            />
                          </Grid>
                          {[
                            { name: 'cost', label: 'Precio' },
                            { name: 'discount', label: 'Descuento' },
                            { name: 'amount_paid', label: 'Monto Pagado' }
                          ].map(data => (
                            <Grid item md={3}>
                              <Controller
                                control={control}
                                //@ts-ignore
                                name={data.name}
                                render={({ field }) => (
                                  <NumberFormat
                                    customInput={TextField}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      )
                                    }}
                                    label={data.label}
                                    //@ts-ignore
                                    value={field.value}
                                    onValueChange={v =>
                                      field.onChange(v.floatValue)
                                    }
                                  />
                                )}
                              />
                            </Grid>
                          ))}

                          <Grid item md={3}>
                            <Controller
                              control={control}
                              name="product"
                              rules={{
                                required: 'Debes seleccionar un producto',
                                minLength: 1
                              }}
                              render={({ field: { value, onChange } }) => (
                                <Autocomplete
                                  options={
                                    availableProducts?.availableProducts || []
                                  }
                                  getOptionLabel={option => option.description}
                                  value={{
                                    id: value || '',
                                    description:
                                      availableProducts?.availableProducts.filter(
                                        val => val.id === value
                                      )[0]?.description || ''
                                  }}
                                  onChange={(_event, newValue) => {
                                    onChange(newValue?.id);
                                  }}
                                  loading={loadingProducts}
                                  onOpen={() => refetchProducts()}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      label="Producto"
                                      margin="normal"
                                      variant="outlined"
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <>
                                            {loadingProducts ? (
                                              <CircularProgress
                                                color="inherit"
                                                size={20}
                                              />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </>
                                        )
                                      }}
                                    />
                                  )}
                                />
                              )}
                            />
                          </Grid>

                          <Grid item md={3}>
                            <FormControlLabel
                              label="Institución?"
                              labelPlacement="start"
                              control={
                                <Controller
                                  control={control}
                                  // inputRef={register}
                                  name="is_institution"
                                  render={props => (
                                    <Checkbox
                                      onChange={e =>
                                        props.field.onChange(e.target.checked)
                                      }
                                      checked={Boolean(props.field.value)}
                                    />
                                  )}
                                />
                              }
                            />
                          </Grid>

                          {is_institution && (
                            <>
                              <Grid item md={3}>
                                <Controller
                                  control={control}
                                  name="institution"
                                  rules={{
                                    required: (() => {
                                      if (is_institution) {
                                        return 'Debes introducir nombre de institución';
                                      }
                                      return false;
                                    })()
                                  }}
                                  render={({
                                    field,
                                    fieldState: { error }
                                  }) => (
                                    <TextField
                                      fullWidth
                                      label="Institución"
                                      variant="outlined"
                                      margin="normal"
                                      InputLabelProps={{ shrink: true }}
                                      {...field}
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item md={3}>
                                <Controller
                                  control={control}
                                  name="institution_email"
                                  rules={{
                                    required: (() => {
                                      if (is_institution) {
                                        return 'Debes introducir email de institución';
                                      }
                                      return false;
                                    })(),
                                    pattern: {
                                      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                      message: 'Debe ser email válido'
                                    }
                                  }}
                                  render={({
                                    field,
                                    fieldState: { error }
                                  }) => (
                                    <TextField
                                      fullWidth
                                      label="Email Institución"
                                      variant="outlined"
                                      margin="normal"
                                      InputLabelProps={{ shrink: true }}
                                      {...field}
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                    />
                                  )}
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>
                        <Divider />
                        <Grid container spacing={3}>
                          <Grid item md={12}>
                            <PatientsSelectTable
                              onSelectionChange={onPatientsSelectionChange}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                      <Divider />
                      <CardActions>
                        {creatingAppointment ? (
                          <ScaleLoader />
                        ) : (
                          <Button
                            size="medium"
                            color="primary"
                            variant="outlined"
                            type="submit"
                          >
                            Crear Cita Nueva
                          </Button>
                        )}
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              </form>
            </>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default AppointmentCreateView;
