import { Store } from 'pullstate';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import {
  Add,
  Cached,
  Clear,
  Done,
  ExpandMore,
  HelpOutline,
  Refresh
} from '@material-ui/icons';
import { DateRange, Range } from 'react-date-range';
import MaterialTable, { Column } from 'material-table';
import { RingLoader } from 'react-spinners';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import { es } from 'date-fns/locale';
import { clean, validate } from 'rut.js';
import { Download } from 'react-feather';
//@ts-expect-error No typings
import csvDownload from 'json-to-csv-export';
import { tableIcons, tableLocalization } from 'src/theme/tableSettings';
import Page from 'src/components/Page';
import {
  AppointmentStatus,
  PaymentStatus,
  useFetchAppointmentsQuery
} from 'src/generated/graphql';
import { BusinessLineStore } from 'src/layouts/DashboardLayout/TopBar';
import { generatePlanilla } from 'src/utils/generatePlanilla';

interface RenderedApppointment {
  id: string;
  datetime: string;
  status: string;
  payment_status: string;
  address: string;
  given_name: string;
  family_name: string;
  dni: string;
  phone: string;
  email: string;
  comuna: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const parseStatusText = (status: string) => {
  return status === AppointmentStatus.Completed
    ? 'Completada'
    : status === AppointmentStatus.Processing
    ? 'Procesando'
    : status === AppointmentStatus.ResultsDelivered
    ? 'Resultados Entregados'
    : status === AppointmentStatus.Cancelled
    ? 'Anulada'
    : 'Desconocido';
};

const parseStatusColor = (status: string) => {
  return status === AppointmentStatus.Completed
    ? 'green'
    : status === AppointmentStatus.Processing
    ? 'orange'
    : status === AppointmentStatus.ResultsDelivered
    ? 'blue'
    : status === AppointmentStatus.Cancelled
    ? 'red'
    : 'black';
};

const parseStatusIcon = (status: string) => {
  return status === AppointmentStatus.Completed ? (
    <Done />
  ) : status === AppointmentStatus.Processing ? (
    <Cached />
  ) : status === AppointmentStatus.ResultsDelivered ? (
    <Cached />
  ) : status === AppointmentStatus.Cancelled ? (
    <Clear />
  ) : (
    <HelpOutline />
  );
};

const parseStatusElement = (rowData: RenderedApppointment) => {
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item>{parseStatusIcon(rowData.status)}</Grid>
      <Grid item>
        <p
          style={{
            color: parseStatusColor(rowData.status),
            fontWeight: 600
          }}
        >
          {parseStatusText(rowData.status)}
        </p>
      </Grid>
    </Grid>
  );
};

const parsePaymentStatusText = (status: string) => {
  return status === PaymentStatus.Completed
    ? 'Pagado'
    : status === PaymentStatus.Pending
    ? 'Pago Pendiente'
    : status === PaymentStatus.Partial
    ? 'Pago Parcial'
    : status === PaymentStatus.Cancelled
    ? 'Anulado'
    : 'Desconocido';
};

const parsePaymentStatusColor = (status: string) => {
  return status === PaymentStatus.Completed
    ? 'green'
    : status === PaymentStatus.Pending
    ? 'orange'
    : status === PaymentStatus.Partial
    ? 'blue'
    : status === PaymentStatus.Cancelled
    ? 'red'
    : 'black';
};

const parsePaymentStatusElement = (rowData: RenderedApppointment) => {
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item>
        <p
          style={{
            color: parsePaymentStatusColor(rowData.payment_status),
            fontWeight: 600
          }}
        >
          {parsePaymentStatusText(rowData.payment_status)}
        </p>
      </Grid>
    </Grid>
  );
};

interface IAppointmentListStore {
  dateRange: Range[];
  show_institution_only?: boolean;
  show_cancelled: boolean;
}

const AppointmentListStore = new Store<IAppointmentListStore>({
  dateRange: [
    {
      startDate: dayjs()
        .subtract(1, 'day')
        .startOf('day')
        .toDate(),
      endDate: dayjs()
        .endOf('week')
        .toDate(),
      key: 'selection'
    }
  ],
  show_cancelled: false
});

// interface IAppointmentListFiltersStore {
//   filters: Filter<RenderedApppointment>[];
// }

// const AppointmentListFiltersStore = new Store<IAppointmentListFiltersStore>({
//   filters: []
// });

const AppointmentList = () => {
  const classes = useStyles();
  const history = useHistory();

  const dateRange = AppointmentListStore.useState(r => r.dateRange);
  const show_cancelled = AppointmentListStore.useState(r => r.show_cancelled);
  const show_institution_only = AppointmentListStore.useState(
    r => r.show_institution_only
  );
  // const appointmentListFilters = AppointmentListFiltersStore.useState(
  //   r => r.filters
  // );
  const businessLine = BusinessLineStore.useState(r => r.business_line);
  const { loading, data: appointmentData, refetch } = useFetchAppointmentsQuery(
    {
      variables: {
        begin_date: dateRange[0].startDate?.toISOString(),
        end_date: dateRange[0].endDate?.toISOString(),
        show_cancelled: show_cancelled,
        business_line: businessLine,
        show_institution_only: show_institution_only
      },
      notifyOnNetworkStatusChange: true,
      //! Determine if no-cache is appropriate
      fetchPolicy: 'no-cache'
    }
  );

  const parsedData =
    appointmentData?.appointments?.map(val => ({
      id: val?.id || '',
      datetime: val?.datetime
        ? dayjs(val.datetime)
            .tz('America/Santiago')
            .format('DD/MM/YYYY HH:mm')
        : '',
      // status: val?.status ? parseStatusText(val.status) : '',
      status: val?.status || '',
      payment_status: val?.payment_status || '',
      staff: val.staff
        ? `${val.staff?.given_name} ${val.staff?.family_name}`
        : '',
      address: val?.address ? val.address : '',
      given_name: val?.patients?.[0]?.given_name || '',
      family_name: val.patients?.[0]?.family_name || '',
      dni: val?.patients[0]?.dni
        ? validate(val.patients[0].dni)
          ? clean(val.patients[0].dni)
          : val.patients[0].dni
        : '',
      phone: val?.patients?.[0]?.phone_number || '',
      email: val?.patients?.[0]?.email || '',
      patient_number: val?.number_of_people || '',
      comuna: val?.comuna || '',
      institution: val?.institution || '',
      product: val.product?.description || ''
    })) || [];

  const columns: Column<RenderedApppointment>[] = [
    {
      title: 'Estado',
      field: 'status',
      render: parseStatusElement,
      lookup: {
        [AppointmentStatus.Completed]: 'Completada',
        [AppointmentStatus.Processing]: 'Procesando',
        [AppointmentStatus.ResultsDelivered]: 'Resultados Entregados',
        [AppointmentStatus.Cancelled]: 'Anulada'
      }
      // defaultFilter: 'Completada'
      // customFilterAndSearch: (term, rowData) => lol(term, rowData, 'status')
      // defaultFilter: appointmentListFilters.map(filter => {
      //   if (filter.column.field === 'status') {
      //     console.log(filter.value);
      //     return filter.value;
      //   }
      // })
    },
    {
      title: 'Pago',
      field: 'payment_status',
      render: parsePaymentStatusElement,
      lookup: {
        [PaymentStatus.Completed]: 'Pagado',
        [PaymentStatus.Pending]: 'Pendiente',
        [PaymentStatus.Partial]: 'Pago Parcial',
        [PaymentStatus.Cancelled]: 'Anulado'
      }
    },
    { title: 'Staff', field: 'staff' },
    { title: 'Fecha', field: 'datetime' },
    { title: 'Producto', field: 'product' },
    { title: 'Nombres', field: 'given_name' },
    { title: 'Apellidos', field: 'family_name' },
    { title: 'Comuna', field: 'comuna' },
    { title: 'Email', field: 'email' },
    { title: 'Teléfono', field: 'phone' },
    { title: 'RUT', field: 'dni' },
    { title: 'Institución', field: 'institution' },
    { title: '# de Pacientes', field: 'patient_number' },
    { title: 'ID', field: 'id' }
  ];

  return (
    <Page className={classes.root} title="Citas">
      <Container maxWidth={false}>
        <Grid container spacing={5}>
          <Grid item md={8}>
            <Box mt={3}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Seleccionar Fecha</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <DateRange
                    editableDateInputs={true}
                    onChange={item => {
                      AppointmentListStore.update(state => {
                        //@ts-ignore
                        state.dateRange = [item.selection];
                      });
                    }}
                    moveRangeOnFirstSelection={false}
                    ranges={dateRange}
                    locale={es}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box mt={3}>
              <Card>
                <CardContent>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={show_cancelled}
                          onChange={e =>
                            AppointmentListStore.update(state => {
                              state.show_cancelled = e.target.checked;
                            })
                          }
                        />
                      }
                      label="Mostrar Canceladas"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={show_institution_only}
                          onChange={e =>
                            AppointmentListStore.update(state => {
                              state.show_institution_only = e.target.checked;
                            })
                          }
                        />
                      }
                      label="Institución"
                    />
                  </FormGroup>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>

        <Box mt={3}>
          {!loading && appointmentData && appointmentData.appointments ? (
            <MaterialTable
              icons={tableIcons}
              localization={tableLocalization}
              options={{
                rowStyle: {
                  fontFamily: 'Roboto'
                },
                filtering: true,
                pageSize: 10
              }}
              actions={[
                {
                  icon: () => <Refresh />,
                  tooltip: 'Refrescar',
                  isFreeAction: true,
                  onClick: () => refetch()
                },
                {
                  icon: () => <Add />,
                  tooltip: 'Nueva Cita',
                  isFreeAction: true,
                  onClick: () => history.push('/app/nueva-cita')
                },
                {
                  icon: () => <Download />,
                  tooltip: 'Descargar planilla',
                  isFreeAction: true,
                  onClick: () =>
                    csvDownload(
                      generatePlanilla(appointmentData),
                      'planilla.csv'
                    )
                }
              ]}
              title="Citas"
              columns={columns}
              onRowClick={(e, row) => {
                if (row && row.id) {
                  history.push(`/app/citas/${row.id}`);
                }
              }}
              data={parsedData}

              // onFilterChange={filters => {
              //   const correctedFilters = filters.map(filter => {
              //     const [firstElement, ...newFilterValue] = filter.value;
              //     return {
              //       column: {
              //         ...filter.column,
              //         //@ts-ignore
              //         tableData: {
              //           //@ts-ignore
              //           ...filter.column.tableData,
              //           filterValue: newFilterValue
              //         }
              //       },
              //       operator: filter.operator,
              //       value: newFilterValue
              //     };
              //   });
              //   AppointmentListFiltersStore.replace({
              //     filters: correctedFilters
              //   });
              //   console.log(filters);
              // }}
            />
          ) : (
            <RingLoader />
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default AppointmentList;
