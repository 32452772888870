import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  CardActions,
  Button,
  makeStyles
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { Controller, useForm } from 'react-hook-form';
import { ScaleLoader } from 'react-spinners';
import { Boleta, Patient } from 'src/generated/graphql';
import { useUploadBoletas } from 'src/utils/uploadBoletas';
import { LinearProgressWithLabel } from '../LinearProgressWithLabel';

interface BoletaUploadForm extends Boleta {
  files: File[];
}

const useStyles = makeStyles(theme => ({
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
  dropzone: {
    height: '50%',
    minHeight: 20
  }
}));

export const UploadBoletasComponent = (props: {
  patient: Patient;
  appointment_id: string;
}) => {
  /* ------------------------------ General Hooks ----------------------------- */

  const classes = useStyles();
  /* ------------------------------ Upload Hooks ------------------------------ */
  const {
    progress: boletasUploadProgress,
    uploadFiles: uploadBoletas,
    uploading: uploadingBoletas
  } = useUploadBoletas();

  /* ------------------------------- Form Hooks ------------------------------- */

  const {
    control: boletaUploadControl,
    handleSubmit: handleBoletaUpload
  } = useForm<BoletaUploadForm>();
  const submitBoletas = handleBoletaUpload(data => {
    if (data.files && props.appointment_id) {
      uploadBoletas(data.files, props.patient.id, props.appointment_id);
    }
  });
  return (
    <Card>
      <CardHeader title="Subir nuevas boletas" />
      <Divider />
      <CardContent>
        <Controller
          control={boletaUploadControl}
          name="files"
          render={({ field }) => (
            <DropzoneArea
              filesLimit={4}
              acceptedFiles={['image/*', 'application/pdf']}
              dropzoneText="Arrastre archivos o haga click para seleccionar"
              getFileLimitExceedMessage={(value: number) =>
                `Número máximos de archivos excedido (${value})`
              }
              getFileAddedMessage={(fileName: string) =>
                `${fileName} añadido exitosamente`
              }
              getFileRemovedMessage={(fileName: string) =>
                `${fileName} removido exitosamente`
              }
              getDropRejectMessage={(rejectedFile: File) =>
                `${rejectedFile.name} rechazado`
              }
              {...field}
              showPreviews={true}
              showPreviewsInDropzone={false}
              useChipsForPreview
              previewGridProps={{
                container: {
                  spacing: 1,
                  direction: 'row'
                }
              }}
              previewChipProps={{
                classes: {
                  root: classes.previewChip
                }
              }}
              previewText="Archivos seleccionados"
              dropzoneClass={classes.dropzone}
            />
          )}
        />
        <LinearProgressWithLabel value={boletasUploadProgress} />
      </CardContent>
      <CardActions>
        {uploadingBoletas ? (
          <ScaleLoader />
        ) : (
          <Button
            size="medium"
            color="primary"
            variant="outlined"
            onClick={submitBoletas}
          >
            Subir archivos
          </Button>
        )}
      </CardActions>
    </Card>
  );
};
