import { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  Add,
  ArrowBack,
  AttachMoney,
  CloudUpload,
  Colorize,
  Description,
  ExpandMore,
  Refresh,
  Send
} from '@material-ui/icons';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { useForm, Controller } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import { ScaleLoader, PacmanLoader } from 'react-spinners';
import NumberFormat from 'react-number-format';
import { useUser } from 'reactfire';
import {
  Patient,
  useEmailConfirmationMutation,
  useFetchIndividualAppointmentQuery,
  useRequestEpivigilaMutation,
  useUpdateIndividualAppointmentMutation,
  useEmailResultsGroupMutation,
  AppointmentStatus,
  PaymentStatus,
  useFetchStaffQuery,
  UserRole,
  AppointmentUpdateInput,
  useRequestResultMutation,
  Factura,
  useEmailFacturasMutation,
  useFetchProductsQuery,
  useEmailPaymentRequestMutation,
  useRegenerateMercadoPagoLinkMutation
} from 'src/generated/graphql';
import Page from 'src/components/Page';
import AttachPatientDialogue from 'src/components/Patients/AttachPatientDialogue';
import IndividualPatientComponent from 'src/components/Patients/IndividualPatientComponent';
import { IndividualSampleComponent } from 'src/components/Samples/IndividualSampleComponent';
import { RenderedPatient } from 'src/components/Patients/PatientsSelectTable';
import AddSampleDialogue from 'src/components/Samples/AddSampleDialogue';
import BulkSampleDialogue from 'src/components/Samples/BulkSampleDialogue';
import { FacturasComponent } from 'src/components/Facturas/FacturasComponent';
import { Autocomplete } from '@material-ui/lab';
import CalculatePriceBasedOnPrestaciones from 'src/components/Appointments/CalculatePriceBasedOnPrestaciones';
import { ResultsComponent } from 'src/components/Results/ResultsComponent';
import { OrdenesComponent } from 'src/components/Ordenes/OrdenesComponent';
import { BoletasComponent } from 'src/components/Boletas/BoletasComponent';
import { AddFilesToPatient } from 'src/components/Appointments/AddPatientFiles';
import { EmitirBoletaComponent } from 'src/components/Boletas/EmitiBoletaComponent';

interface AppointmentForm
  extends Omit<
    AppointmentUpdateInput,
    'datetime' | 'number_of_people' | 'product'
  > {
  datetime: Dayjs;
  staff_email?: string;
  number_of_people: string;
  facturas: Factura[];
  product?: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
  tooltip: {
    fontSize: '1em'
  }
}));

const AppointmentFillOut = () => {
  const classes = useStyles();
  const { id: appointmentId } = useParams<{ id: string }>();
  const history = useHistory();
  const { data: user } = useUser();

  /* ------------------------------ GraphQL Hooks ------------------------------ */

  // Queries
  const {
    data: appointmentData,
    loading,
    refetch
  } = useFetchIndividualAppointmentQuery({
    variables: {
      id: appointmentId,
      appointment_id: appointmentId
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  });

  const { data: staff } = useFetchStaffQuery();
  const {
    data: availableProducts,
    loading: loadingProducts,
    refetch: refetchProducts
  } = useFetchProductsQuery({
    notifyOnNetworkStatusChange: true
  });

  // Mutations

  const [
    updateAppointment,
    { loading: updateAppointmentLoading }
  ] = useUpdateIndividualAppointmentMutation({
    refetchQueries: ['fetchIndividualAppointment']
  });

  const [
    requestEpivigila,
    { loading: requestingEpivigila }
  ] = useRequestEpivigilaMutation({
    refetchQueries: ['fetchIndividualAppointment']
  });

  const [
    emailPaymentRequest,
    { loading: emailingPaymentRequest, error: emailingPaymentRequestError }
  ] = useEmailPaymentRequestMutation();

  const [
    emailConfirmation,
    { loading: emailingConfirmation, error: emailingConfirmationError }
  ] = useEmailConfirmationMutation();

  const [
    emailResultsGroup,
    { loading: emailingResultsGroup, error: emailingResultsGroupError }
  ] = useEmailResultsGroupMutation();

  const [
    emailFacturas,
    { loading: emailingFacturas, error: emailingFacturasError }
  ] = useEmailFacturasMutation();

  const [
    requestResults,
    { loading: requestingResults, error: requestingResultsError }
  ] = useRequestResultMutation({
    refetchQueries: ['fetchIndividualAppointment']
  });

  const [
    regenerateMercadoPagoLink,
    {
      loading: regeneratingMercadoPagoLink,
      error: regenerateMercadoPagoLinkError
    }
  ] = useRegenerateMercadoPagoLinkMutation();

  useEffect(() => {
    if (!loading && appointmentData?.appointment) {
      const {
        id,
        __typename,
        patients,
        staff,
        ...appointmentDataNoPatients
      } = appointmentData.appointment;
      reset({
        ...appointmentDataNoPatients,
        status: appointmentDataNoPatients.status,
        number_of_people: appointmentDataNoPatients?.number_of_people?.toString(),
        datetime: dayjs.utc(
          dayjs(appointmentDataNoPatients.datetime)
            .tz('America/Santiago')
            .format('YYYY-MM-DD HH:mm')
        ),
        staff_email: appointmentData.appointment?.staff?.email || '',
        product: appointmentData.appointment?.product?.id
      });
      if (appointmentData.appointment?.patients?.[0]) {
        setPatients(appointmentData.appointment.patients);
      }

      if (appointmentData.appointment.facturas) {
        setFacturas(appointmentData.appointment.facturas);
      }
    }
  }, [loading]);

  /* ---------------------------- Appointment Form ---------------------------- */

  const { reset, handleSubmit, watch, control } = useForm<AppointmentForm>();
  const is_institution = watch('is_institution');
  const product = watch('product');

  /* ----------------------------- General states ----------------------------- */

  const [patients, setPatients] = useState<any[]>([]);
  const [facturas, setFacturas] = useState<any[]>([]);
  const [attachPatientsModalOpen, setAttachPatientsModalOpen] = useState(false);
  const [bulkSamplesModalOpen, setBulkSamplesModalOpen] = useState(false);
  const [sampleDialogueOpen, setSampleDialogueOpen] = useState(false);
  const [calculatorDialogueOpen, setCaluclatorDialogueOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<Patient>();
  const [userRole, setUserRole] = useState<UserRole>(UserRole.Staff);

  /* ----------------------------- Patient dialogs ---------------------------- */

  const [uploadResultsDialogOpen, setUploadResultsDialogOpen] = useState(false);
  /* -------------------------------------------------------------------------- */

  const determineRole = async () => {
    const token = await user.getIdTokenResult();
    setUserRole(token.claims.role);
  };
  useEffect(() => {
    determineRole();
  }, [user]);

  const closeAttachPatientsModal = () => {
    setAttachPatientsModalOpen(false);
  };

  const closeBulkResultsModal = () => {
    setBulkSamplesModalOpen(false);
  };

  const onUpdateAppointment = handleSubmit(values => {
    const { facturas, ...appointmentNoPatient } = values;
    if (appointmentNoPatient && appointmentData?.appointment?.id) {
      updateAppointment({
        variables: {
          id: appointmentData?.appointment?.id,
          appointment: {
            ...appointmentNoPatient,
            number_of_people:
              parseInt(appointmentNoPatient?.number_of_people) || 1,
            datetime: dayjs
              .tz(
                //@ts-expect-error unknown dayjs incompat
                appointmentNoPatient.datetime.format('YYYY-MM-DD HH:mm'),
                'America/Santiago'
              )
              .toISOString()
          }
        }
      });
    }
  });

  const addPatientsToAppointment = (
    previousPatients: string[],
    selectedPatients: RenderedPatient | RenderedPatient[]
  ) => {
    if (Array.isArray(selectedPatients)) {
      const newPatients = selectedPatients.map(row => row.id);
      const uniqueIDs = new Set([...previousPatients, ...newPatients]);
      const patientIDs = [...uniqueIDs];
      updateAppointment({
        variables: {
          id: appointmentId,
          appointment: {
            patients: patientIDs
          }
        }
      });
    }
  };

  return (
    <Page className={classes.root} title={`Cita ${appointmentId}`}>
      <Container maxWidth={false}>
        <Box mt={3}>
          <Tooltip classes={{ tooltip: classes.tooltip }} title="Volver atrás">
            <IconButton color="primary" onClick={() => history.go(-1)}>
              <ArrowBack />
            </IconButton>
          </Tooltip>
          <Tooltip classes={{ tooltip: classes.tooltip }} title="Refrescar">
            <IconButton color="primary" onClick={() => refetch()}>
              <Refresh />
            </IconButton>
          </Tooltip>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Agregar pacientes"
          >
            <IconButton
              color="primary"
              onClick={() => setAttachPatientsModalOpen(true)}
            >
              <Add />
            </IconButton>
          </Tooltip>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Agregar muestras"
          >
            <IconButton
              color="primary"
              onClick={() => setBulkSamplesModalOpen(true)}
            >
              <Colorize />
            </IconButton>
          </Tooltip>
          {/* <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Descargar excel con resultados (antígenos)"
          >
            <IconButton
              color="primary"
              onClick={() => {
                let samples: any[] = [];
                appointmentData?.appointment?.patients.forEach(patient =>
                  patient?.samples.forEach(sample => {
                    if (sample.sample_type === Sampletypes.Antigen) {
                      samples.push({
                        RUT:
                          patient.dni && patient.dni_type === Dni_Type.Rut
                            ? formatRUT(patient.dni)
                            : patient.dni,
                        Nombres: patient?.given_name,
                        Apellidos: patient?.family_name,
                        Email: patient.email,
                        Resultado: sample.result,
                        Fecha: dayjs(
                          appointmentData.appointment?.datetime
                        ).format('DD.MM.YYYY')
                      });
                    }
                  })
                );
                samples[0] && writeSamplesExcelFile(samples);
              }}
            >
              <Download />
            </IconButton>
          </Tooltip> */}
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title="Calcular precio"
          >
            <IconButton
              color="primary"
              onClick={() => setCaluclatorDialogueOpen(true)}
            >
              <AttachMoney />
            </IconButton>
          </Tooltip>
          {patients && (
            <>
              <AttachPatientDialogue
                handleClose={closeAttachPatientsModal}
                open={attachPatientsModalOpen}
                tableProps={{
                  previousPatients: patients.map(patient => patient.id),
                  finishedCallback: refetch,
                  onAddPatients: addPatientsToAppointment,
                  addingPatients: updateAppointmentLoading
                }}
              />
              {staff && (
                <BulkSampleDialogue
                  handleClose={closeBulkResultsModal}
                  open={bulkSamplesModalOpen}
                  // samples={patients
                  //   .map(patient => {
                  //     return patient.samples.map(
                  //       (sample: Sample, sample_index: number) => ({
                  //         patient_name: patient.family_name,
                  //         sample_number: sample_index + 1,
                  //         sample_type: sample.sample_type,
                  //         sample_result: sample.result
                  //       })
                  //     );
                  //   })
                  //   .flat()}
                  appointment_id={appointmentId}
                  patients={patients}
                  userRole={userRole}
                  staff={staff}
                  refetch={refetch}
                />
              )}
            </>
          )}

          <CalculatePriceBasedOnPrestaciones
            open={calculatorDialogueOpen}
            handleClose={() => setCaluclatorDialogueOpen(false)}
            appointment_id={appointmentId}
            refetchAppointment={refetch}
          />

          {selectedPatient && staff && (
            <AddSampleDialogue
              appointmentID={appointmentId}
              patient={selectedPatient}
              open={sampleDialogueOpen}
              handleClose={() => {
                setSampleDialogueOpen(false);
                setSelectedPatient(undefined);
              }}
              userRole={userRole}
              staff={staff}
            />
          )}

          {selectedPatient && (
            <AddFilesToPatient
              appointment_id={appointmentId}
              patient={selectedPatient}
              open={uploadResultsDialogOpen}
              onClose={() => {
                setUploadResultsDialogOpen(false);
                setSelectedPatient(undefined);
              }}
            />
          )}

          {!loading ? (
            <Grid container spacing={4}>
              <Grid item md={12}>
                <form>
                  <Card>
                    <CardHeader
                      title="Agenda"
                      subheader={`${appointmentId || ''}`}
                    />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={3}>
                          <Controller
                            control={control}
                            name="datetime"
                            render={({ field: { ref, ...rest } }) => (
                              <KeyboardDateTimePicker
                                fullWidth
                                disableToolbar
                                variant="inline"
                                inputVariant="outlined"
                                format="DD/MM/YYYY HH:mm"
                                placeholder="10/10/2010"
                                margin="normal"
                                id="date-picker-inline"
                                openTo="date"
                                ampm={false}
                                views={['date', 'hours', 'minutes']}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date'
                                }}
                                label="Fecha y Hora"
                                {...rest}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={3}>
                          <Controller
                            name="number_of_people"
                            control={control}
                            rules={{
                              required: 'Debes introducir número de personas',
                              // setValueAs: value => parseInt(value),
                              minLength: 1
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                fullWidth
                                label="Número de Personas"
                                variant="outlined"
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                                type="number"
                                {...field}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={3}>
                          <Controller
                            name="status"
                            control={control}
                            //@ts-ignore
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                fullWidth
                                select
                                label="Estado"
                                {...field}
                                variant="outlined"
                                margin="normal"
                              >
                                {[
                                  {
                                    value: AppointmentStatus.Processing,
                                    label: 'Procesando'
                                  },
                                  {
                                    value: AppointmentStatus.ResultsDelivered,
                                    label: 'Resultados Entregados'
                                  },
                                  {
                                    value: AppointmentStatus.Completed,
                                    label: 'Completado'
                                  },
                                  {
                                    value: AppointmentStatus.Cancelled,
                                    label: 'Anulada'
                                  }
                                ].map(option => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>
                        <Grid item md={3}>
                          <Controller
                            name="payment_status"
                            control={control}
                            //@ts-ignore
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                fullWidth
                                select
                                label="Pago"
                                {...field}
                                variant="outlined"
                                margin="normal"
                              >
                                {[
                                  {
                                    value: PaymentStatus.Completed,
                                    label: 'Completado'
                                  },
                                  {
                                    value: PaymentStatus.Pending,
                                    label: 'Pendiente'
                                  },
                                  {
                                    value: PaymentStatus.Partial,
                                    label: 'Parcial'
                                  },
                                  {
                                    value: PaymentStatus.Cancelled,
                                    label: 'Anulado'
                                  }
                                ].map(option => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>

                        <Grid item md={3}>
                          <Controller
                            name="staff_email"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                fullWidth
                                select
                                disabled={userRole !== 'admin'}
                                label="Staff"
                                variant="outlined"
                                margin="normal"
                                {...field}
                              >
                                {staff?.users
                                  .map(staffer => ({
                                    value: staffer.email,
                                    label: `${staffer.given_name} ${staffer.family_name}`
                                  }))
                                  .map(option => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            )}
                          />
                        </Grid>

                        {[
                          {
                            name: 'comuna',
                            label: 'Comuna',
                            size: 3
                          },
                          {
                            name: 'address',
                            label: 'Dirección',
                            size: 4
                          },
                          {
                            name: 'confirmation_code',
                            label: 'Código transacción',
                            size: 2
                          }
                        ].map(data => (
                          //@ts-ignore
                          <Grid key={data.name} item md={data.size}>
                            <Controller
                              control={control}
                              //@ts-ignore
                              name={data.name}
                              render={({ field }) => (
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  margin="normal"
                                  InputLabelProps={{ shrink: true }}
                                  label={data.label}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        ))}
                        {[
                          { name: 'cost', label: 'Precio' },
                          { name: 'discount', label: 'Descuento' },
                          { name: 'amount_paid', label: 'Monto Pagado' }
                        ].map(data => (
                          <Grid item md={2}>
                            <Controller
                              control={control}
                              //@ts-ignore
                              name={data.name}
                              render={({ field }) => (
                                <NumberFormat
                                  customInput={TextField}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  fullWidth
                                  variant="outlined"
                                  margin="normal"
                                  InputLabelProps={{ shrink: true }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    )
                                  }}
                                  label={data.label}
                                  //@ts-ignore
                                  value={field.value}
                                  onValueChange={v =>
                                    field.onChange(v.floatValue)
                                  }
                                />
                              )}
                            />
                          </Grid>
                        ))}
                        <Grid item md={3}>
                          <Controller
                            control={control}
                            name="product"
                            defaultValue={product}
                            render={({ field: { value, onChange } }) => (
                              <Autocomplete
                                options={
                                  availableProducts?.availableProducts || []
                                }
                                getOptionLabel={option => option.description}
                                value={{
                                  id: value || '',
                                  description:
                                    availableProducts?.availableProducts.filter(
                                      val => val.id === value
                                    )[0]?.description || ''
                                }}
                                onChange={(_event, newValue) => {
                                  onChange(newValue?.id);
                                }}
                                loading={loadingProducts}
                                onOpen={() => refetchProducts()}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    label="Producto"
                                    margin="normal"
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <>
                                          {loadingProducts ? (
                                            <CircularProgress
                                              color="inherit"
                                              size={20}
                                            />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </>
                                      )
                                    }}
                                  />
                                )}
                              />
                            )}
                          />
                        </Grid>

                        {is_institution !== undefined && (
                          <>
                            <Grid item md={3}>
                              <FormControlLabel
                                label="Institución?"
                                labelPlacement="start"
                                control={
                                  <Controller
                                    control={control}
                                    // inputRef={register}
                                    name="is_institution"
                                    render={({ field }) => (
                                      <Checkbox
                                        onChange={e =>
                                          field.onChange(e.target.checked)
                                        }
                                        checked={Boolean(field.value)}
                                      />
                                    )}
                                  />
                                }
                              />
                            </Grid>

                            {is_institution && (
                              <>
                                <Grid item md={3}>
                                  <Controller
                                    name="institution"
                                    control={control}
                                    rules={{
                                      required: (() => {
                                        if (is_institution) {
                                          return 'Debes introducir nombre de institución';
                                        }
                                        return false;
                                      })()
                                    }}
                                    render={({
                                      field,
                                      fieldState: { error }
                                    }) => (
                                      <TextField
                                        fullWidth
                                        label="Institución"
                                        variant="outlined"
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        {...field}
                                        error={!!error}
                                        helperText={
                                          error ? error.message : null
                                        }
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item md={3}>
                                  <Controller
                                    control={control}
                                    name="institution_email"
                                    rules={{
                                      required: (() => {
                                        if (is_institution) {
                                          return 'Debes introducir email de institución';
                                        }
                                        return false;
                                      })(),
                                      pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: 'Debe ser email válido'
                                      }
                                    }}
                                    render={({
                                      field,
                                      fieldState: { error }
                                    }) => (
                                      <TextField
                                        fullWidth
                                        label="Email Institución"
                                        variant="outlined"
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        {...field}
                                        error={!!error}
                                        helperText={
                                          error ? error.message : null
                                        }
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item md={6}>
                                  <Accordion>
                                    <AccordionSummary
                                      expandIcon={<ExpandMore />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography>Facturas</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <FacturasComponent
                                        appointment_id={appointmentId}
                                        facturas={facturas}
                                      />
                                    </AccordionDetails>
                                  </Accordion>
                                </Grid>
                              </>
                            )}
                          </>
                        )}
                      </Grid>
                    </CardContent>
                    <CardActions>
                      {updateAppointmentLoading ? (
                        <ScaleLoader />
                      ) : (
                        <Button
                          size="medium"
                          color="primary"
                          variant="outlined"
                          onClick={onUpdateAppointment}
                        >
                          Actualizar Cita
                        </Button>
                      )}
                      {requestingEpivigila ? (
                        <ScaleLoader />
                      ) : (
                        <Button
                          size="medium"
                          color="default"
                          variant="outlined"
                          onClick={() => {
                            appointmentData?.appointment?.id &&
                              requestEpivigila({
                                variables: {
                                  appointment_id:
                                    appointmentData?.appointment?.id
                                }
                              });
                          }}
                        >
                          Solicitar Epivigila
                        </Button>
                      )}
                      {requestingResults ? (
                        <ScaleLoader />
                      ) : requestingResultsError ? (
                        requestingResultsError.message
                      ) : (
                        <Button
                          size="medium"
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            const requestedSamples: string[] = [];

                            appointmentData?.appointment?.patients.forEach(
                              patient =>
                                patient?.samples.forEach(sample => {
                                  if (sample.product?.id === 'pcr_covid') {
                                    requestedSamples.push(sample.id);
                                  }
                                })
                            );
                            requestResults({
                              variables: {
                                samples: requestedSamples
                              }
                            });
                          }}
                        >
                          <Description />
                          Buscar Resultados en Laboratorio (Todos)
                        </Button>
                      )}
                      {regeneratingMercadoPagoLink ? (
                        <ScaleLoader />
                      ) : regenerateMercadoPagoLinkError ? (
                        regenerateMercadoPagoLinkError.message
                      ) : (
                        <Button
                          size="medium"
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            regenerateMercadoPagoLink({
                              variables: {
                                appointment_id: appointmentId
                              }
                            });
                          }}
                        >
                          <AttachMoney />
                          Regenerar Link MercadoPago
                        </Button>
                      )}
                      {is_institution &&
                        (emailingResultsGroup ? (
                          <ScaleLoader />
                        ) : emailingResultsGroupError ? (
                          emailingResultsGroupError.message
                        ) : (
                          <Button
                            size="medium"
                            color="primary"
                            variant="outlined"
                            onClick={() =>
                              emailResultsGroup({
                                variables: {
                                  appointment_id: appointmentId
                                }
                              })
                            }
                          >
                            <Send />
                            Enviar Resultados Institución por Email
                          </Button>
                        ))}

                      {is_institution &&
                        (emailingFacturas ? (
                          <ScaleLoader />
                        ) : emailingFacturasError ? (
                          emailingFacturasError.message
                        ) : (
                          <Button
                            size="medium"
                            color="primary"
                            variant="outlined"
                            onClick={() =>
                              emailFacturas({
                                variables: {
                                  appointment_id: appointmentId
                                }
                              })
                            }
                          >
                            <Send />
                            Enviar Facturas por Email
                          </Button>
                        ))}
                    </CardActions>
                  </Card>
                </form>
              </Grid>
              {patients?.map((patient: Patient, index) => (
                <Fragment key={`patient_card_${patient.id}`}>
                  <Box mt={3} mb={2}>
                    <Tooltip title="Agregar muestra">
                      <IconButton
                        onClick={() => {
                          setSelectedPatient(patient);
                          setSampleDialogueOpen(true);
                        }}
                      >
                        <Colorize />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Subir archivos">
                      <IconButton
                        onClick={() => {
                          setSelectedPatient(patient);
                          setUploadResultsDialogOpen(true);
                        }}
                      >
                        <CloudUpload />
                      </IconButton>
                    </Tooltip>
                    <Grid container spacing={2}>
                      <Grid item md={6}>
                        <IndividualPatientComponent
                          patient={patient}
                          appointment_id={appointmentId}
                          show_boletas={false}
                          show_ordenes={false}
                          show_results={false}
                          cardHeader={
                            <CardHeader
                              title="Paciente"
                              subheader={`ID: ${patient.id || ''}`}
                              action={
                                <>
                                  {emailingConfirmation ? (
                                    <ScaleLoader />
                                  ) : emailingConfirmationError ? (
                                    emailingConfirmationError.message
                                  ) : (
                                    <Button
                                      size="medium"
                                      color="primary"
                                      variant="outlined"
                                      onClick={() =>
                                        emailConfirmation({
                                          variables: {
                                            appointment_id: appointmentId,
                                            patient_id: patient.id
                                          }
                                        })
                                      }
                                    >
                                      <Send />
                                      Enviar confirmación
                                    </Button>
                                  )}
                                  {emailingPaymentRequest ? (
                                    <ScaleLoader />
                                  ) : emailingPaymentRequestError ? (
                                    emailingPaymentRequestError.message
                                  ) : (
                                    <Button
                                      size="medium"
                                      color="primary"
                                      variant="outlined"
                                      onClick={() =>
                                        emailPaymentRequest({
                                          variables: {
                                            appointment_id: appointmentId,
                                            patient_id: patient.id
                                          }
                                        })
                                      }
                                    >
                                      <Send />
                                      Enviar solicitud de pago
                                    </Button>
                                  )}
                                </>
                              }
                            />
                          }
                        />
                      </Grid>
                      <Grid item md={6}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={8}>
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <ResultsComponent
                                  patient_id={patient.id}
                                  appointment_id={appointmentId}
                                  results={patient.results}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <OrdenesComponent
                                  patient_id={patient.id}
                                  appointment_id={appointmentId}
                                  ordenes={patient.ordenes}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <BoletasComponent
                                  patient_id={patient.id}
                                  appointment_id={appointmentId}
                                  boletas={patient.boletas}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <EmitirBoletaComponent
                              appointment_id={appointmentId}
                              patient={patient}
                              product_id={product || ''}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          {staff &&
                            patient?.samples?.map((sample, sampleIndex) => (
                              <Grid item xs={12} md={6}>
                                <IndividualSampleComponent
                                  sample={sample}
                                  user_role={userRole}
                                  staff={staff}
                                  sampleIndex={sampleIndex}
                                />
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Fragment>
              ))}
            </Grid>
          ) : (
            <PacmanLoader />
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default AppointmentFillOut;
