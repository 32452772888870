import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  ListItemText,
  List,
  Grid
} from '@material-ui/core';
import dayjs from 'dayjs';

import { ListItemLink } from '../ListItemLink';

import { Appointment } from 'src/generated/graphql';

interface IAppointmentsComponentProps {
  patient_id: string;
  appointments: Appointment[];
}

export const AppointmentsComponent = ({
  patient_id,
  appointments
}: IAppointmentsComponentProps) => {
  /* ------------------------------ General Hooks ----------------------------- */

  const history = useHistory();

  /* -------------------------------------------------------------------------- */

  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Card>
          <CardHeader
            title="Boletas"
            subheader={`ID paciente: ${patient_id}`}
          />
          <Divider />
          <CardContent>
            {appointments.map((appointment, index) => (
              <List
                component="nav"
                aria-label="secondary mailbox folders"
                key={`appointments_patient_${index}`}
              >
                <ListItemLink
                  onClick={() => history.push(`/app/citas/${appointment.id}`)}
                >
                  <ListItemText
                    primary={`${
                      appointment?.datetime
                        ? dayjs(appointment?.datetime)
                            .tz('America/Santiago')
                            .format('DD/MM/YYYY - HH:mm')
                        : 'Fecha indefinida'
                    } | ${appointment.address}, ${appointment.comuna}`}
                  />
                </ListItemLink>
              </List>
            ))}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
