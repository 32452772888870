import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  // CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  TextField
} from '@material-ui/core';
import { RingLoader } from 'react-spinners';
import {
  FetchStaffQuery,
  Laboratories,
  Patient,
  Sampletypes,
  useFetchProductsQuery,
  useSubmitSampleMutation
} from 'src/generated/graphql';
import { useUser } from 'reactfire';
import { Controller, useForm } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
// import { Autocomplete } from '@material-ui/lab';

interface AttachPatientDialogueParams {
  open: boolean;
  handleClose(): void;
  appointmentID: string;
  patient: Patient;
  userRole?: string;
  staff: FetchStaffQuery;
}

interface SampleSubmission {
  patient_id: string;
  appointment_id: string;
  epivigila_code?: string;
  sample_code?: string;
  laboratory?: Laboratories;
  staff_email?: string | null;
  product?: string;
  sample_type?: Sampletypes;
  result?: string;
}

const AddSampleDialogue = (props: AttachPatientDialogueParams) => {
  /* -------------------------------- Auth Hook ------------------------------- */

  const { data: user } = useUser();

  /* ------------------------------ GraphQL Hooks ----------------------------- */

  const [createSample, { loading }] = useSubmitSampleMutation({
    refetchQueries: ['fetchIndividualAppointment']
  });

  const {
    data: availableProducts,
    loading: loadingProducts,
    refetch: refetchProducts
  } = useFetchProductsQuery({
    notifyOnNetworkStatusChange: true
  });

  /* ------------------------------- Form Hooks ------------------------------- */

  const { control, handleSubmit } = useForm<SampleSubmission>({
    defaultValues: {
      appointment_id: props.appointmentID,
      patient_id: props.patient.id,
      staff_email: user.email
    }
  });
  const submitForm = handleSubmit(values =>
    createSample({ variables: { sample: values } })
  );

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title">Adjuntar Nueva Muestra</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Añade una muestra para el paciente indicado
        </DialogContentText>
        {!loading ? (
          <Grid container spacing={4}>
            <Grid item md={12}>
              <Card>
                <CardHeader
                  title={`Paciente: ${props.patient.given_name} ${props.patient.family_name}`}
                />
                <Divider />
                <CardContent>
                  <Grid item md={6}>
                    <Controller
                      control={control}
                      name="product"
                      render={({ field: { value, onChange } }) => (
                        <Autocomplete
                          options={
                            availableProducts?.availableProducts.map(p => ({
                              id: p.id,
                              description: p.description
                            })) || []
                          }
                          getOptionLabel={option => option.description}
                          value={
                            availableProducts?.availableProducts
                              .map(p => ({
                                id: p.id,
                                description: p.description
                              }))
                              .filter(p => p.id === value)[0]
                          }
                          onChange={(_event, newValue) => {
                            onChange(newValue?.id);
                          }}
                          loading={loadingProducts}
                          renderInput={params => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Producto"
                              margin="normal"
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loadingProducts ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                )
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  {/* {sample.sample_type === Sampletypes.Antigen && (
                    <Grid item md={6}>
                      <Controller
                        name="result"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            select
                            label="Resultado"
                            variant="outlined"
                            margin="normal"
                            disabled={sampleType !== Sampletypes.Antigen}
                            {...field}
                          >
                            {[
                              {
                                value: 'Positivo',
                                label: 'Positivo'
                              },
                              { value: 'Negativo', label: 'Negativo' }
                            ].map(option => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                  )} */}
                  {[
                    { name: 'epivigila_code', label: 'Epivigila' },
                    { name: 'sample_code', label: 'Código de Muestra' }
                  ].map(data => (
                    <Grid key={data.name} item md={6}>
                      <Controller
                        control={control}
                        //@ts-ignore
                        name={data.name}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            InputLabelProps={{
                              shrink: true
                            }}
                            label={data.label}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  ))}

                  <Grid item md={6}>
                    <Controller
                      control={control}
                      name="laboratory"
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          select
                          label="Laboratorio"
                          variant="outlined"
                          margin="normal"
                          {...field}
                        >
                          {[
                            {
                              value: Laboratories.UChile,
                              label: 'Hospital Clínico U de Chile'
                            },
                            { value: Laboratories.Irp, label: 'IRP' },
                            { value: Laboratories.Redlab, label: 'Redlab' }
                          ].map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>

                  {props.userRole === 'admin' && (
                    <Grid item md={6}>
                      <Controller
                        name="staff_email"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            select
                            label="Staff"
                            variant="outlined"
                            margin="normal"
                            {...field}
                          >
                            {props.staff?.users
                              .map(staffer => ({
                                value: staffer.email,
                                label: `${staffer.given_name} ${staffer.family_name}`
                              }))
                              .map(option => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <RingLoader />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            submitForm();
            props.handleClose();
          }}
          color="primary"
        >
          Enviar
        </Button>
        <Button onClick={props.handleClose} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSampleDialogue;
