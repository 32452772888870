import React from 'react';
import { useHistory } from 'react-router';
import {
  makeStyles,
  Grid,
  Card,
  CardHeader,
  Divider,
  CardContent,
  TextField,
  MenuItem,
  CardActions,
  Button,
  Container,
  Box,
  IconButton
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { Controller, useForm } from 'react-hook-form';
import { ScaleLoader, PacmanLoader } from 'react-spinners';
import {
  PatientCreateInput,
  useCreatePatientMutation
} from 'src/generated/graphql';
import Page from 'src/components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  }
}));

const PatientCreateView = () => {
  /* ---------------------- General Hooks and Definitions --------------------- */

  const classes = useStyles();
  const history = useHistory();

  const onSubmit = (data: PatientCreateInput) => {
    createPatient({
      variables: {
        patients: [data]
      }
    });
    history.go(-1);
  };

  /* ------------------------------ GraphQL Hooks ----------------------------- */

  const [
    createPatient,
    { loading: creatingPatient }
  ] = useCreatePatientMutation({
    notifyOnNetworkStatusChange: true,
    refetchQueries: ['fetchPatients']
  });

  /* ------------------------------- Form Hooks ------------------------------- */

  const { register, control, handleSubmit } = useForm<PatientCreateInput>();

  /* -------------------------------------------------------------------------- */

  return (
    <Page className={classes.root} title={`Nuevo Paciente`}>
      <Container maxWidth={false}>
        <Box mt={3}>
          {creatingPatient ? (
            <PacmanLoader />
          ) : (
            <>
              <IconButton color="primary" onClick={() => history.go(-1)}>
                <ArrowBack />
              </IconButton>

              <Grid container spacing={3}>
                <Grid item md={12}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Card>
                      <CardHeader
                        title="Paciente"
                        subheader={`Crear nuevo paciente`}
                      />
                      <Divider />
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item md={6}>
                            <Controller
                              control={control}
                              name="given_name"
                              rules={{
                                required: 'Debes llenar nombres',
                                minLength: 1
                              }}
                              render={({ field, fieldState: { error } }) => (
                                <TextField
                                  fullWidth
                                  label="Nombres"
                                  variant="outlined"
                                  margin="normal"
                                  InputLabelProps={{ shrink: true }}
                                  {...field}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md={6}>
                            <Controller
                              control={control}
                              name="family_name"
                              rules={{
                                required: 'Debes llenar apellidos',
                                minLength: 1
                              }}
                              render={({ field, fieldState: { error } }) => (
                                <TextField
                                  fullWidth
                                  label="Apellidos"
                                  variant="outlined"
                                  margin="normal"
                                  InputLabelProps={{ shrink: true }}
                                  {...field}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md={6}>
                            <Controller
                              control={control}
                              name="email"
                              rules={{
                                required: 'Debes llenar email',
                                pattern: {
                                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message: 'Debe ser email válido'
                                }
                              }}
                              render={({ field, fieldState: { error } }) => (
                                <TextField
                                  fullWidth
                                  label="Email"
                                  variant="outlined"
                                  margin="normal"
                                  InputLabelProps={{ shrink: true }}
                                  {...field}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md={6}>
                            <Controller
                              control={control}
                              name="dni"
                              render={({ field }) => (
                                <TextField
                                  fullWidth
                                  label="RUT"
                                  variant="outlined"
                                  margin="normal"
                                  InputLabelProps={{ shrink: true }}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md={6}>
                            <Controller
                              control={control}
                              name="phone_number"
                              render={({ field }) => (
                                <TextField
                                  fullWidth
                                  label="Celular"
                                  variant="outlined"
                                  margin="normal"
                                  InputLabelProps={{ shrink: true }}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>

                          <Grid item md={6}>
                            <Controller
                              name="sex"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <TextField
                                  fullWidth
                                  select
                                  label="Sexo"
                                  variant="outlined"
                                  margin="normal"
                                  {...field}
                                >
                                  {[
                                    { value: 'male', label: 'Masculino' },
                                    {
                                      value: 'female',
                                      label: 'Femenino'
                                    },
                                    {
                                      value: 'other',
                                      label: 'Indeterminado'
                                    }
                                  ].map(option => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                            />
                          </Grid>

                          <Grid item md={6}>
                            <Controller
                              control={control}
                              name="comuna"
                              render={({ field }) => (
                                <TextField
                                  fullWidth
                                  label="Comuna"
                                  variant="outlined"
                                  margin="normal"
                                  InputLabelProps={{ shrink: true }}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md={6}>
                            <Controller
                              control={control}
                              name="address"
                              render={({ field }) => (
                                <TextField
                                  fullWidth
                                  label="Dirección"
                                  variant="outlined"
                                  margin="normal"
                                  InputLabelProps={{ shrink: true }}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md={6}>
                            <Controller
                              control={control}
                              name="pueblo_originario"
                              render={({ field }) => (
                                <TextField
                                  fullWidth
                                  label="Pueblo Originario"
                                  variant="outlined"
                                  margin="normal"
                                  InputLabelProps={{ shrink: true }}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md={6}>
                            <Controller
                              control={control}
                              name="workplace"
                              render={({ field }) => (
                                <TextField
                                  fullWidth
                                  label="Lugar de trabajo (si es trabajador de salud)"
                                  variant="outlined"
                                  margin="normal"
                                  InputLabelProps={{ shrink: true }}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md={6}>
                            <Controller
                              control={control}
                              name="diseases"
                              render={({ field }) => (
                                <TextField
                                  fullWidth
                                  multiline
                                  rows={4}
                                  label="Enfermedades u otros antecedentes"
                                  variant="outlined"
                                  margin="normal"
                                  InputLabelProps={{ shrink: true }}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md={6}>
                            <Controller
                              control={control}
                              name="comments"
                              render={({ field }) => (
                                <TextField
                                  fullWidth
                                  multiline
                                  rows={4}
                                  label="Comentarios"
                                  variant="outlined"
                                  margin="normal"
                                  InputLabelProps={{ shrink: true }}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions>
                        {creatingPatient ? (
                          <ScaleLoader />
                        ) : (
                          <Button
                            size="medium"
                            color="primary"
                            variant="outlined"
                            type="submit"
                          >
                            Crear Paciente Nuevo
                          </Button>
                        )}
                      </CardActions>
                    </Card>
                  </form>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default PatientCreateView;
