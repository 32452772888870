import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
  Snackbar
} from '@material-ui/core';
import firebase from 'firebase/app';
import { useAuth } from 'reactfire';
import Page from 'src/components/Page';
import { Alert } from '@material-ui/lab';
import { Controller, useForm } from 'react-hook-form';

interface LoginFormData {
  email: string;
  password: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    //@ts-ignore
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const { handleSubmit, control } = useForm<LoginFormData>();
  const [isSnackbarOpen, setSnackBarOpen] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const auth = useAuth();

  const onSubmit = handleSubmit(async values => {
    setIsLoggingIn(true);
    try {
      await auth.signInWithEmailAndPassword(values.email, values.password);
    } catch (error: any) {
      if (error.code === 'auth/wrong-password') {
        setSnackBarOpen(true);
      }
      console.log(error);
    }
    setIsLoggingIn(false);
  });

  const onGoogleLogin = async () => {
    try {
      await auth.signInWithRedirect(new firebase.auth.GoogleAuthProvider());
    } catch (error) {}
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarOpen(false);
  };

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <form onSubmit={onSubmit}>
            <Box mb={3}>
              <Typography color="textPrimary" variant="h2">
                Ingreso
              </Typography>
            </Box>
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  label="Email"
                  margin="normal"
                  type="email"
                  variant="outlined"
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  label="Contraseña"
                  margin="normal"
                  type="password"
                  variant="outlined"
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
            <Box my={2}>
              <Button
                color="primary"
                disabled={isLoggingIn}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Ingresar
              </Button>
            </Box>
            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                size="large"
                variant="contained"
                onClick={onGoogleLogin}
              >
                Ingresar con Google
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          Usuario o clave incorrectos
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default LoginView;
