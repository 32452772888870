import React from 'react';
import { Range } from 'react-date-range';
import { PacmanLoader } from 'react-spinners';
import MaterialTable from 'material-table';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { tableIcons, tableLocalization } from 'src/theme/tableSettings';

const weekdays = [
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado',
  'Domingo'
];

interface ISamplesByWeekdayComponentProps {
  data: { day: number; count: number }[];
  isLoading: boolean;
  dateRange: Range[];
  dateFormatter(item: string): string;
  colors: string[];
}

export const SamplesByWeekdayChart = (
  props: ISamplesByWeekdayComponentProps
) => {
  if (props.isLoading) {
    return <PacmanLoader />;
  }

  const data = props.data
    .sort((a, b) => a.day - b.day)
    .map(row => ({
      day: weekdays[row.day],
      count: row.count
    }));

  return (
    <ResponsiveContainer height={300}>
      <BarChart data={data} margin={{ bottom: 15, top: 10 }}>
        <CartesianGrid />
        <XAxis
          dataKey="day"
          label={{
            value: 'Día de Semana',
            position: 'insideBottom',
            offset: -10
          }}
        />
        <YAxis
          label={{
            value: 'No. de Muestras',
            angle: -90,
            position: 'insideLeft',
            offset: 5
          }}
        />
        <Tooltip />
        <Bar dataKey="count" fill="#665191" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export const SamplesByWeekdayTable = (
  props: ISamplesByWeekdayComponentProps
) => {
  if (props.isLoading) {
    return <PacmanLoader />;
  }
  return (
    <MaterialTable
      icons={tableIcons}
      localization={tableLocalization}
      title="Muestras por Día de Semana"
      data={props.data}
      columns={[
        {
          title: 'Dia',
          field: 'day',
          render: rowData => weekdays[rowData.day],
          defaultSort: 'asc'
        },
        { title: 'Muestras', field: 'count' }
      ]}
      options={{
        exportButton: true,
        pageSize: 7,
        paging: false,
        search: false
      }}
    />
  );
};
