import { Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Patient } from 'src/generated/graphql';
import { UploadBoletasComponent } from '../Boletas/UploadBoletasComponent';
import { UploadOrdenesComponent } from '../Ordenes/UploadOrdenesComponent';
import { AddResultsDialog } from '../Results/UploadResultsComponent';

export const AddFilesToPatient = (props: {
  open: boolean;
  onClose(): void;
  patient: Patient;
  appointment_id: string;
}) => {
  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="xl">
      <DialogTitle>
        Adjuntar archivos para paciente{' '}
        <strong>
          {props.patient.given_name} {props.patient.family_name}
        </strong>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <AddResultsDialog {...props} />
          </Grid>
          <Grid item xs={12} md={4}>
            <UploadBoletasComponent {...props} />
          </Grid>
          <Grid item xs={12} md={4}>
            <UploadOrdenesComponent {...props} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
