import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  TextField
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { ScaleLoader } from 'react-spinners';

import {
  FetchStaffQuery,
  Sample,
  useDeleteSampleMutation,
  UserRole,
  useUpdateSampleMutation,
  useRequestResultMutation,
  SampleInput,
  useFetchProductsQuery,
  Laboratories,
  Sample_Status
} from 'src/generated/graphql';
import { Description } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

interface SampleForm
  extends Omit<SampleInput, 'appointment_id' | 'patient_id'> {}

interface IIndividualSampleComponentProps {
  sample: Sample;
  user_role: UserRole;
  staff: FetchStaffQuery;
  sampleIndex: number;
}
export const IndividualSampleComponent = ({
  sample,
  user_role,
  staff,
  sampleIndex
}: IIndividualSampleComponentProps) => {
  /* ---------------------- General Hooks and Defintions ---------------------- */

  const [loadedData, setloadedData] = useState(false);

  const onSubmit = (data: SampleForm) => {
    updateSample({
      variables: {
        id: sample.id,
        sample: {
          ...data,
          appointment_id: sample.appointment_id,
          patient_id: sample.patient_id
        }
      }
    });
  };
  /* ------------------------------ GraphQL Hooks ----------------------------- */

  const [
    updateSample,
    { loading: updatingSample, error: updatingSampleError }
  ] = useUpdateSampleMutation({
    refetchQueries: ['fetchIndividualAppointment']
  });

  const [
    deleteSample,
    { loading: deletingSample, error: deletingSampleError }
  ] = useDeleteSampleMutation({
    refetchQueries: ['fetchIndividualAppointment']
  });

  const [
    requestResults,
    { loading: requestingResults, error: requestingResultsError }
  ] = useRequestResultMutation({
    refetchQueries: ['fetchIndividualAppointment']
  });

  const {
    data: availableProducts,
    loading: loadingProducts
    // refetch: refetchProducts
  } = useFetchProductsQuery({
    notifyOnNetworkStatusChange: true
  });
  /* ------------------------------- Form Hooks ------------------------------- */

  const { control, handleSubmit, reset, watch } = useForm<SampleForm>();
  const sampleType = watch('sample_type');
  useEffect(() => {
    if (sample) {
      const { __typename, id, staff, product, ...trueSample } = sample;
      reset({
        ...trueSample,
        staff_email: sample.staff?.email,
        product: product?.id
      });
      setloadedData(true);
    }
  }, [sample, reset]);

  /* -------------------------------------------------------------------------- */
  if (!loadedData) return <CircularProgress />;

  const cardColor = {
    [Sample_Status.Processing]: {
      label: 'Procesando',
      color: '#2222ff'
    },
    [Sample_Status.RutIssue]: {
      label: 'Problemas de RUT',
      color: '#d6bf33'
    },
    [Sample_Status.NeedsAttention]: {
      label: 'Requiere atencion',
      color: '#ff2222'
    },
    [Sample_Status.NeedsPayment]: {
      label: 'Requiere pago',
      color: '#ff2222'
    },
    [Sample_Status.Completed]: {
      label: 'Completado',
      color: '#00ff00'
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card
        style={{
          backgroundColor:
            (sample.status && `${cardColor[sample.status].color}22`) || 'white'
        }}
      >
        <CardHeader
          title={`Muestra ${sampleIndex + 1}`}
          subheader={`ID: ${sample.id}`}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4}>
              {/* <Controller
                name="sample_type"
                control={control}
                //@ts-ignore
                defaultValue=""
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    select
                    label="Tipo de Muestra"
                    variant="outlined"
                    margin="normal"
                    {...field}
                  >
                    {[
                      {
                        value: Sampletypes.Pcr,
                        label: 'PCR'
                      },
                      { value: Sampletypes.Antigen, label: 'Antígeno' }
                    ].map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              /> */}
              {availableProducts?.availableProducts && (
                <Controller
                  control={control}
                  name="product"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      options={availableProducts.availableProducts.map(p => ({
                        id: p.id,
                        label: p.description
                      }))}
                      getOptionLabel={option => option.label}
                      value={{
                        id: value || '',
                        label:
                          availableProducts.availableProducts.filter(
                            val => val.id === value
                          )?.[0]?.description || ''
                      }}
                      onChange={(_e, newValue) => onChange(newValue?.id)}
                      loading={loadingProducts}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Producto"
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingProducts ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            )
                          }}
                        />
                      )}
                    />
                  )}
                />
              )}
            </Grid>

            {/* <Grid item md={4}>
              <Controller
                name="result"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    fullWidth
                    select
                    label="Resultado"
                    variant="outlined"
                    margin="normal"
                    disabled={sampleType !== Sampletypes.Antigen}
                    {...field}
                  >
                    {[
                      {
                        value: 'Positivo',
                        label: 'Positivo'
                      },
                      { value: 'Negativo', label: 'Negativo' }
                    ].map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid> */}
            <Grid item md={4}>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    select
                    label="Estado"
                    variant="outlined"
                    margin="normal"
                    {...field}
                  >
                    {Object.keys(cardColor).map(key => (
                      <MenuItem
                        key={key}
                        value={key}
                        style={{
                          color: `${cardColor[key as Sample_Status].color}fe`,
                          backgroundColor: 'whitesmoke'
                        }}
                      >
                        <strong>{cardColor[key as Sample_Status].label}</strong>
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            {[
              { name: 'epivigila_code', label: 'Epivigila' },
              { name: 'sample_code', label: 'Código de Muestra' }
            ].map(data => (
              <Grid key={data.name} item md={4}>
                <Controller
                  control={control}
                  //@ts-ignore
                  name={data.name}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      label={data.label}
                      {...field}
                    />
                  )}
                />
              </Grid>
            ))}
            <Grid item md={4}>
              <Controller
                control={control}
                //@ts-ignore
                name="laboratory"
                render={({ field }) => (
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    margin="normal"
                    label="Laboratorio"
                    {...field}
                  >
                    {[
                      {
                        value: Laboratories.UChile,
                        label: 'Hospital Clínico U de Chile'
                      },
                      { value: Laboratories.Irp, label: 'IRP' },
                      { value: Laboratories.Redlab, label: 'Redlab' }
                    ].map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            {/* <Grid item md={6}>
              <TextField
                fullWidth
                label="Epivigila"
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                name="epivigila_code"
                inputRef={register}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                label="Código de Muestra"
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                name="sample_code"
                inputRef={register}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                label="Laboratorio"
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                name="laboratory"
                inputRef={register}
              />
            </Grid> */}
            <Grid item md={4}>
              <Controller
                name="staff_email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    fullWidth
                    select
                    disabled={user_role !== 'admin'}
                    label="Staff"
                    variant="outlined"
                    margin="normal"
                    {...field}
                  >
                    {staff?.users
                      .map(staffer => ({
                        value: staffer.email,
                        label: `${staffer.given_name} ${staffer.family_name}`
                      }))
                      .map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          {updatingSample ? (
            <ScaleLoader />
          ) : updatingSampleError ? (
            'Error actualizando muestra'
          ) : (
            <Button
              size="medium"
              color="primary"
              variant="outlined"
              type="submit"
            >
              Actualizar Muestra
            </Button>
          )}
          {deletingSample ? (
            <ScaleLoader />
          ) : deletingSampleError ? (
            'Error eliminando muestra'
          ) : (
            <Button
              size="medium"
              style={{
                color: 'red',
                borderColor: 'red'
              }}
              variant="outlined"
              onClick={() =>
                sample &&
                deleteSample({
                  variables: {
                    id: sample.id
                  }
                })
              }
            >
              Eliminar Muestra
            </Button>
          )}
          {requestingResults ? (
            <ScaleLoader />
          ) : requestingResultsError ? (
            requestingResultsError.message
          ) : (
            <Button
              size="medium"
              color="primary"
              variant="outlined"
              onClick={() =>
                requestResults({
                  variables: {
                    samples: [sample.id]
                  }
                })
              }
            >
              <Description />
              Buscar en Laboratorio
            </Button>
          )}
        </CardActions>
      </Card>
    </form>
  );
};
