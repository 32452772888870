import React from 'react';
import { useCubeQuery } from '@cubejs-client/react';
import { PacmanLoader } from 'react-spinners';
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import dayjs from 'dayjs';
import { Range } from 'react-date-range';
import MaterialTable from 'material-table';
import { tableIcons, tableLocalization } from 'src/theme/tableSettings';

interface ISampleByTypeComponentProps {
  dateRange: Range[];
  dateFormatter(item: string): string;
  colors: string[];
}
export const SamplesByTypeChart = (props: ISampleByTypeComponentProps) => {
  const { resultSet, isLoading, error, progress } = useCubeQuery({
    measures: ['samples.count'],
    timeDimensions: [
      {
        dimension: 'samples.createdAt',
        granularity: 'day',
        dateRange: [
          dayjs(props.dateRange[0].startDate?.toISOString()).format(
            'YYYY-MM-DD'
          ) || new Date(2021, 3, 1).toISOString(),
          dayjs(props.dateRange[0].endDate?.toISOString()).format(
            'YYYY-MM-DD'
          ) || new Date(2021, 3, 31).toISOString()
        ]
      }
    ],
    order: {
      'samples.count': 'desc'
    },
    dimensions: ['samples.sampleType']
  });
  if (isLoading) {
    return <PacmanLoader />;
  }
  return (
    <ResponsiveContainer height={300}>
      <BarChart data={resultSet?.chartPivot()} margin={{ bottom: 15, top: 10 }}>
        <XAxis
          dataKey="x"
          tickFormatter={props.dateFormatter}
          label={{
            value: 'Fecha',
            position: 'insideBottom',
            offset: -10
          }}
        />
        <YAxis
          label={{
            value: 'No. de Muestras',
            angle: -90,
            position: 'insideLeft',
            offset: 5
          }}
        />
        {resultSet?.seriesNames().map((series, i) => {
          return (
            <Bar
              key={series.key}
              stackId="a"
              dataKey={series.key}
              // name={series.title}
              name={series.yValues[0]}
              fill={props.colors[i]}
            />
          );
        })}
        <Tooltip labelFormatter={props.dateFormatter} />
        <Legend verticalAlign="top" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export const SamplesByTypeTable = (props: ISampleByTypeComponentProps) => {
  const { resultSet, isLoading, error } = useCubeQuery({
    measures: ['samples.count'],
    timeDimensions: [
      {
        dimension: 'samples.createdAt',
        dateRange: [
          dayjs(props.dateRange[0].startDate?.toISOString()).format(
            'YYYY-MM-DD'
          ) || new Date(2021, 3, 1).toISOString(),
          dayjs(props.dateRange[0].endDate?.toISOString()).format(
            'YYYY-MM-DD'
          ) || new Date(2021, 3, 31).toISOString()
        ]
      }
    ],
    order: {
      'samples.count': 'desc'
    },
    dimensions: ['samples.sampleType']
  });

  if (isLoading) {
    return <PacmanLoader />;
  }

  if (error) {
    return <>Error procesando datos</>;
  }

  const data =
    resultSet &&
    resultSet.tablePivot().map(result => {
      return {
        type: result['samples.sampleType'],
        count: result['samples.count']
      };
    });

  return (
    data && (
      <MaterialTable
        icons={tableIcons}
        localization={tableLocalization}
        title="Muestras por Tipo"
        data={data}
        columns={[
          { title: 'Tipo', field: 'type' },
          { title: 'Muestras', field: 'count' }
        ]}
        options={{
          exportButton: true
        }}
      />
    )
  );
};
