import React from 'react';
import { useHistory } from 'react-router';
import { Box, Container, makeStyles } from '@material-ui/core';
import { Add, Refresh, Publish } from '@material-ui/icons';
import MaterialTable, { Column } from 'material-table';
import { RingLoader } from 'react-spinners';
import Page from 'src/components/Page';
import { Dni_Type, useFetchPatientsQuery } from 'src/generated/graphql';
import { tableIcons, tableLocalization } from 'src/theme/tableSettings';
import { format } from 'rut.js';

interface RenderedPatient {
  id: string;
  comuna: string;
  address: string;
  given_name: string;
  family_name: string;
  dni: string;
  phone: string;
  email: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const PatientListView = () => {
  const classes = useStyles();
  const history = useHistory();

  const { loading, data, refetch } = useFetchPatientsQuery({
    notifyOnNetworkStatusChange: true
  });

  const patients =
    data?.patients?.map(val => ({
      id: val?.id || '',
      comuna: val?.comuna || '',
      address: val?.address || '',
      given_name: val?.given_name || '',
      family_name: val?.family_name || '',
      dni:
        (val?.dni &&
          (val.dni_type === Dni_Type.Rut ? format(val.dni) : val.dni)) ||
        '',
      phone: val?.phone_number || '',
      email: val?.email || '',
      institution: val?.institution || ''
    })) || [];

  const columns: Column<RenderedPatient>[] = [
    { title: 'RUT', field: 'dni' },
    { title: 'Nombres', field: 'given_name' },
    { title: 'Apellidos', field: 'family_name' },
    { title: 'Email', field: 'email' },
    { title: 'Teléfono', field: 'phone' },
    { title: 'Institución', field: 'institution' },
    { title: 'ID', field: 'id' }
  ];
  // usefetchPatientsQuery
  return (
    <Page className={classes.root} title="Pacientes">
      <Container maxWidth={false}>
        <Box mt={3}>
          {!loading && patients ? (
            <MaterialTable
              icons={tableIcons}
              localization={tableLocalization}
              options={{
                rowStyle: {
                  fontFamily: 'Roboto'
                },
                pageSize: 20
              }}
              actions={[
                {
                  icon: () => <Refresh />,
                  tooltip: 'Refrescar',
                  isFreeAction: true,
                  onClick: () => refetch()
                },
                {
                  icon: () => <Add />,
                  tooltip: 'Nuevo Paciente',
                  isFreeAction: true,
                  onClick: () => history.push('/app/nuevo-paciente')
                },
                {
                  icon: () => <Publish />,
                  tooltip: 'Subir Datos Grupo',
                  isFreeAction: true,
                  onClick: () => history.push('/app/pacientes/grupo')
                }
              ]}
              title="Pacientes"
              columns={columns}
              onRowClick={(e, row) => {
                if (row && row.id) {
                  history.push(`/app/pacientes/${row.id}`);
                }
              }}
              data={patients}
            />
          ) : (
            <RingLoader />
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default PatientListView;
