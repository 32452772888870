import React from 'react';
import { Helmet } from 'react-helmet';

// const Page = forwardRef(({ children, title = '', ...rest }, ref) => {
//   return (
//     <div ref={ref} {...rest}>
//       <Helmet>
//         <title>{title}</title>
//       </Helmet>
//       {children}
//     </div>
//   );
// });

const Page: React.FC<{ title: string; className?: string }> = ({
  children,
  title
}) => {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
};

export default Page;
