import React from 'react';

const Logo = (props: any) => {
  return (
    <img
      alt="Logo"
      src="/static/logo_cut.png"
      style={{
        maxHeight: 60
      }}
      {...props}
    />
  );
};

export default Logo;
