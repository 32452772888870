import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { makeStyles, Container, Box, IconButton } from '@material-ui/core';
import { ArrowBack, Refresh } from '@material-ui/icons';
import { PacmanLoader } from 'react-spinners';

import { useFetchIndividualPatientQuery } from 'src/generated/graphql';
import Page from 'src/components/Page';
import IndividualPatientComponent from 'src/components/Patients/IndividualPatientComponent';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  }
}));

const PatientIndividualView = () => {
  const classes = useStyles();
  const { id: patient_id } = useParams<{ id: string }>();
  const history = useHistory();

  const { loading, data, refetch } = useFetchIndividualPatientQuery({
    variables: {
      id: patient_id
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  });

  const [patient, setPatient] = useState<any>();

  useEffect(() => {
    if (!loading && data?.patient) {
      setPatient(data?.patient);
    }
  }, [loading]);

  return (
    <Page className={classes.root} title={`Paciente ${patient_id}`}>
      <Container maxWidth={false}>
        <Box mt={3}>
          {loading ? (
            <PacmanLoader />
          ) : (
            <>
              <IconButton color="primary" onClick={() => history.go(-1)}>
                <ArrowBack />
              </IconButton>
              <IconButton color="primary" onClick={() => refetch()}>
                <Refresh />
              </IconButton>
              {patient && (
                <IndividualPatientComponent
                  patient={patient}
                  show_results
                  show_ordenes
                  show_boletas
                />
              )}
            </>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default PatientIndividualView;
