import {
  Card,
  CardHeader,
  Button,
  Divider,
  CardContent,
  ListItemText,
  List,
  Grid
} from '@material-ui/core';
import { Send } from '@material-ui/icons';
import dayjs from 'dayjs';
import { ScaleLoader } from 'react-spinners';

import { ListItemLink } from '../ListItemLink';

import { Orden, useGenerateOrdenMutation } from 'src/generated/graphql';

interface IOrdenesComponentProps {
  patient_id: string;
  ordenes: Orden[];
  appointment_id?: string;
}

export const OrdenesComponent = ({
  patient_id,
  appointment_id,
  ordenes
}: IOrdenesComponentProps) => {
  const [
    generateOrden,
    { error: errorGeneratingOrden, loading: generatingOrden }
  ] = useGenerateOrdenMutation({
    notifyOnNetworkStatusChange: true,
    refetchQueries: ['fetchIndividualAppointment', 'fetchIndividualPatient']
  });

  /* -------------------------------------------------------------------------- */

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <Card>
          <CardHeader
            title="Ordenes Médicas (se deben enviar manualmente)"
            subheader={`ID paciente: ${patient_id}`}
            action={
              appointment_id &&
              (generatingOrden ? (
                <ScaleLoader />
              ) : errorGeneratingOrden ? (
                errorGeneratingOrden.message
              ) : (
                <Button
                  size="medium"
                  color="primary"
                  variant="outlined"
                  onClick={() =>
                    generateOrden({
                      variables: {
                        orden: {
                          appointment_id: appointment_id,
                          patient_id: patient_id
                        }
                      }
                    })
                  }
                >
                  <Send />
                  Generar orden
                </Button>
              ))
            }
          />
          <Divider />
          <CardContent>
            {ordenes.map((orden, index) => (
              <List
                component="nav"
                aria-label="secondary mailbox folders"
                key={`ordenes_patient_${index}`}
              >
                <ListItemLink href={orden?.file_url || ''}>
                  <ListItemText
                    primary={
                      orden?.created_at
                        ? dayjs(orden?.created_at)
                            .tz('America/Santiago')
                            .format('DD/MM/YYYY - HH:mm')
                        : 'Fecha indefinida'
                    }
                  />
                </ListItemLink>
              </List>
            ))}
          </CardContent>
        </Card>
      </Grid>
      {appointment_id && <Grid item md={12}></Grid>}
    </Grid>
  );
};
