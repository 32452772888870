import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import AppointmentFillOut from './views/appointments/IndividualAppointment';
import AppointmentList from './views/appointments/AppointmentList';
import NotFoundView from './views/errors/NotFoundView';
import PatientCreateView from './views/patient/PatientCreate';
import PatientIndividualView from './views/patient/PatientIndividualView';
import PatientListView from './views/patient/PatientListView';
import AppointmentCreateView from './views/appointments/AppointmentCreate';
import { PatientBulkUploadView } from './views/patient/PatientBulkUpload';
import { AnalyticsDahsboardView } from './views/analytics/AnalyticsDashboard';
import CotizacionesView from './views/cotizaciones';

const Routes = () => {
  return (
    <Switch>
      <Route path="/app">
        <DashboardLayout>
          <Switch>
            <Route path="/app/citas">
              <Route path="/app/citas/:id">
                <AppointmentFillOut />
              </Route>
              <Route path="/app/citas" exact>
                <AppointmentList />
              </Route>
            </Route>

            <Route path="/app/pacientes">
              <Route path="/app/pacientes/:id">
                <PatientIndividualView />
              </Route>

              <Route path="/app/pacientes/grupo" exact>
                <PatientBulkUploadView />
              </Route>

              <Route path="/app/pacientes" exact>
                <PatientListView />
              </Route>
            </Route>

            <Route path="/app/nueva-cita">
              <AppointmentCreateView />
            </Route>

            <Route path="/app/nuevo-paciente">
              <PatientCreateView />
            </Route>

            <Route path="/app/cotizar">
              <CotizacionesView />
            </Route>
            <Route path="*">
              <AnalyticsDahsboardView />
            </Route>
          </Switch>

          {/* <Redirect from="/app" to="/app/citas" exact /> */}
        </DashboardLayout>
      </Route>
      <Route path="/" exact>
        <MainLayout>
          <Route path="/404">
            <NotFoundView />
          </Route>
          <Redirect from="/" to="/app" exact />
        </MainLayout>
      </Route>
    </Switch>
  );
};

export default Routes;
