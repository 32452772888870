import dayjs from 'dayjs';
import { format, validate } from 'rut.js';
import { FetchAppointmentsQuery } from 'src/generated/graphql';

export const generatePlanilla = (data: FetchAppointmentsQuery) => {
  return data.appointments
    .map(appointment => {
      return appointment.patients.map(patient => {
        if (patient) {
          return {
            staff: appointment.staff
              ? `${appointment.staff?.given_name} ${appointment.staff?.family_name}`
              : '',
            rut:
              patient.dni &&
              (validate(patient.dni) ? format(patient?.dni) : patient.dni),

            nombres: patient.given_name,
            apellidos: patient.family_name,
            direccion: appointment.address || '',
            comuna: appointment.comuna || '',
            telefono: patient.phone_number || '',
            fecha_nacimiento: patient.date_of_birth
              ? dayjs(patient.date_of_birth).format('DD-MM-YYYY')
              : ''
          };
        }
        return {};
      });
    })
    .flat();
};
