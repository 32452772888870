import React, { useEffect, useState } from 'react';
import {
  Grid,
  Card,
  CardHeader,
  Divider,
  CardContent,
  TextField,
  MenuItem,
  CardActions,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ScaleLoader } from 'react-spinners';
import { Controller, useForm } from 'react-hook-form';
import {
  validate as validateRUT,
  clean as cleanRUT,
  format as formatRUT
} from 'rut.js';
import {
  Dni_Type,
  Patient,
  PatientUpdateInput,
  useDeletePatientMutation,
  useUpdateIndividualPatientMutation
} from 'src/generated/graphql';
import { BoletasComponent } from '../Boletas/BoletasComponent';
import { OrdenesComponent } from '../Ordenes/OrdenesComponent';
import { ResultsComponent } from '../Results/ResultsComponent';
import { AppointmentsComponent } from '../Appointments/AppointmentsComponent';

interface IIndividualPatientComponentProps {
  patient: Patient;
  cardHeader?: JSX.Element;
  appointment_id?: string;
  show_results: boolean;
  show_boletas: boolean;
  show_ordenes: boolean;
}
const IndividualPatientComponent = ({
  patient,
  cardHeader,
  appointment_id,
  show_results,
  show_boletas,
  show_ordenes
}: IIndividualPatientComponentProps) => {
  /* ---------------------- General Hooks and Definitions --------------------- */
  const [loading, setLoading] = useState(true);

  const onSubmit = (data: PatientUpdateInput) => {
    updatePatient({
      variables: {
        id: patient.id,
        patient: {
          ...data,
          dni:
            data.dni &&
            (dni_type === Dni_Type.Rut ? cleanRUT(data.dni) : data.dni)
        }
      }
    });
  };

  const validateRut = (rut: string | undefined | null) => {
    if (rut) {
      if (dni_type === Dni_Type.Rut) {
        return validateRUT(cleanRUT(rut)) || 'RUT Inválido';
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  /* ------------------------------ GraphQL Hooks ----------------------------- */

  const [
    updatePatient,
    { loading: updatePatientLoading }
  ] = useUpdateIndividualPatientMutation({
    refetchQueries: [
      'fetchIndividualPatient',
      'fetchIndividualAppointment',
      'fetchAppointments'
    ]
  });

  const [
    deletePatient,
    { loading: deletingPatient }
  ] = useDeletePatientMutation({
    refetchQueries: [
      'fetchIndividualPatient',
      'fetchIndividualAppointment',
      'fetchAppointments'
    ]
  });

  /* ------------------------------- Form Hooks ------------------------------- */

  const { handleSubmit, reset, control, watch } = useForm<PatientUpdateInput>();

  const dni_type = watch('dni_type');

  useEffect(() => {
    if (patient) {
      const {
        appointments,
        boletas,
        ordenes,
        samples,
        results,
        id,
        __typename,
        ...patientUpdate
      } = patient;
      reset({
        ...patientUpdate,
        sex: patientUpdate.sex || '',
        date_of_birth: new Date(patientUpdate.date_of_birth)
      });
      setLoading(false);
    }
  }, [patient, reset]);

  /* -------------------------------------------------------------------------- */
  if (loading) return <CircularProgress />;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Card>
            {cardHeader ? (
              cardHeader
            ) : (
              <CardHeader
                title="Paciente"
                subheader={`ID: ${patient?.id || ''}`}
              />
            )}
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <Controller
                    control={control}
                    name="given_name"
                    rules={{ required: 'Debe llenar nombres', minLength: 1 }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        fullWidth
                        label="Nombres"
                        variant="outlined"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Controller
                    control={control}
                    name="family_name"
                    rules={{
                      required: 'Debe llenar apellidos',
                      minLength: 1
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        fullWidth
                        label="Apellidos"
                        variant="outlined"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Controller
                    control={control}
                    name="email"
                    rules={{
                      required: 'Debe llenar email',
                      pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Debe ser email válido'
                      }
                    }}
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={3}>
                  <Controller
                    control={control}
                    name="dni_type"
                    defaultValue={Dni_Type.Rut}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        fullWidth
                        select
                        label="Tipo de ID"
                        variant="outlined"
                        margin="normal"
                        {...field}
                      >
                        {[
                          { value: 'RUT', label: 'RUT' },
                          {
                            value: 'passport',
                            label: 'Pasaporte'
                          }
                        ].map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item md={3}>
                  <Controller
                    control={control}
                    name="dni"
                    defaultValue=""
                    rules={{
                      validate: validateRut
                    }}
                    render={({ field, fieldState: { error } }) =>
                      dni_type === Dni_Type.Rut ? (
                        <TextField
                          fullWidth
                          label="RUT"
                          variant="outlined"
                          margin="normal"
                          InputLabelProps={{ shrink: true }}
                          {...field}
                          value={field.value ? formatRUT(field.value) : ''}
                          onChange={e =>
                            field.onChange(formatRUT(e.target.value))
                          }
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          label="Pasaporte"
                          variant="outlined"
                          margin="normal"
                          InputLabelProps={{ shrink: true }}
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )
                    }
                  />
                </Grid>
                <Grid item md={6}>
                  <Controller
                    control={control}
                    name="phone_number"
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        fullWidth
                        label="Celular"
                        variant="outlined"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>

                {/* <Grid item md={3}>
                  <Controller
                    name="sex"
                    control={control}
                    defaultValue="other"
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        select
                        label="Sexo"
                        variant="outlined"
                        margin="normal"
                        {...field}
                      >
                        {[
                          { value: 'male', label: 'Masculino' },
                          {
                            value: 'female',
                            label: 'Femenino'
                          },
                          {
                            value: 'other',
                            label: 'Indeterminado'
                          }
                        ].map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid> */}
                {[
                  {
                    name: 'institution',
                    label: 'Institución'
                  }
                  // { name: 'comuna', label: 'Comuna' },
                  // { name: 'address', label: 'Dirección' },
                  // { name: 'pueblo_originario', label: 'Pueblo Originario' },
                  // {
                  //   name: 'workplace',
                  //   label: 'Lugar de trabajo (si es trabajado de la salud)'
                  // },
                  // {
                  //   name: 'diseases',
                  //   label: 'Enfermedades u otros antecedentes'
                  // }
                ].map(data => (
                  <Grid key={data.name} item md={6}>
                    <Controller
                      control={control}
                      //@ts-ignore
                      name={data.name}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          InputLabelProps={{ shrink: true }}
                          label={data.label}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                ))}
                {/* <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Institución"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    name="institution"
                    inputRef={register}
                  />
                </Grid>

                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Comuna"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    name="comuna"
                    inputRef={register}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Dirección"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    name="address"
                    inputRef={register}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Pueblo Originario"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    name="pueblo_originario"
                    inputRef={register}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Lugar de trabajo (si es trabajador de salud)"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    name="workplace"
                    inputRef={register}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Enfermedades u otros antecedentes"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    name="diseases"
                    inputRef={register}
                  />
                </Grid> */}
                <Grid item md={6}>
                  <Controller
                    control={control}
                    name="date_of_birth"
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="DD/MM/YYYY"
                        margin="normal"
                        id="date-picker-inline"
                        label="Fecha de Nacimiento"
                        KeyboardButtonProps={{
                          'aria-label': 'fecha de nacimiento'
                        }}
                        inputVariant="outlined"
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Controller
                    control={control}
                    name="comments"
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        multiline
                        rows={2}
                        variant="outlined"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        label="Comentarios"
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              {updatePatientLoading ? (
                <ScaleLoader />
              ) : (
                <Button
                  size="medium"
                  color="primary"
                  variant="outlined"
                  type="submit"
                >
                  Actualizar Paciente
                </Button>
              )}
              {deletingPatient ? (
                <ScaleLoader />
              ) : (
                <Button
                  size="medium"
                  style={{
                    color: 'red',
                    borderColor: 'red'
                  }}
                  variant="outlined"
                  onClick={() =>
                    deletePatient({
                      variables: {
                        id: patient.id
                      }
                    })
                  }
                >
                  Eliminar paciente
                </Button>
              )}
            </CardActions>
          </Card>
        </Grid>
        {show_results && (
          <Grid item md={6}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Resultados</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ResultsComponent
                  patient_id={patient.id}
                  results={patient.results}
                  appointment_id={appointment_id}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}

        {show_boletas && (
          <Grid item md={6}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Boletas</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <BoletasComponent
                  patient_id={patient.id}
                  boletas={patient.boletas}
                  appointment_id={appointment_id}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}

        {show_ordenes && (
          <Grid item md={6}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Ordenes Médicas</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <OrdenesComponent
                  patient_id={patient.id}
                  ordenes={patient.ordenes}
                  appointment_id={appointment_id}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}

        {!appointment_id && (
          <Grid item md={6}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Citas</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AppointmentsComponent
                  patient_id={patient.id}
                  appointments={patient.appointments}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default IndividualPatientComponent;
