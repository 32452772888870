import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Container,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';

import {
  SamplesByTypeChart,
  SamplesByTypeTable
} from 'src/components/Analytics/SamplesByType';
import Page from 'src/components/Page';
import {
  SamplesByStaffChart,
  SamplesByStaffTable
} from 'src/components/Analytics/SamplesByStaff';
import { ExpandMore } from '@material-ui/icons';
import { es } from 'date-fns/locale';
import { DateRange, Range } from 'react-date-range';
import { Store } from 'pullstate';
import dayjs from 'dayjs';
import { useFetchStaffQuery } from 'src/generated/graphql';
import { useCubeQuery } from '@cubejs-client/react';
import {
  SamplesByWeekdayChart,
  SamplesByWeekdayTable
} from 'src/components/Analytics/SamplesByWeekday';
import { mapValues, flow, groupBy, sumBy, compose } from 'lodash/fp';
// import { SamplesByAppointmentTable } from 'src/components/Analytics/SamplesByAppointment';
const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));
interface IAnalyticsDashboardStore {
  dateRange: Range[];
}

const AnalyticsDashboardStore = new Store<IAnalyticsDashboardStore>({
  dateRange: [
    {
      startDate: dayjs()
        .subtract(1, 'day')
        .startOf('day')
        .toDate(),
      endDate: dayjs()
        .endOf('week')
        .toDate(),
      key: 'selection'
    }
  ]
});
export const AnalyticsDahsboardView = () => {
  const classes = useStyles();

  const dateRange = AnalyticsDashboardStore.useState(r => r.dateRange);
  const { data: staffData } = useFetchStaffQuery();

  const dateFormatter = (item: string) => dayjs(item).format('DD/MM/YYYY');
  const colors = [
    '#355070',
    '#f95d6a',
    '#ff7c43',
    '#665191',
    '#a05195',
    '#d45087',
    '#ffa600'
  ];

  const {
    resultSet: resultSetWeekday,
    isLoading: isLoadingWeekday,
    error,
    progress
  } = useCubeQuery({
    measures: ['samples.count'],
    timeDimensions: [
      {
        dimension: 'samples.createdAt',
        granularity: 'day',
        dateRange: [
          dayjs(dateRange[0].startDate?.toISOString()).format('YYYY-MM-DD') ||
            new Date(2021, 3, 1).toISOString(),
          dayjs(dateRange[0].endDate?.toISOString()).format('YYYY-MM-DD') ||
            new Date(2021, 3, 31).toISOString()
        ]
      }
    ],
    order: {
      'samples.count': 'desc'
    },
    dimensions: []
  });

  const dataWeekday = flow(
    mapValues((row: any) => {
      return {
        weekday: dayjs(row['samples.createdAt.day']).day(),
        count: row['samples.count']
      };
    }),
    groupBy('weekday'),
    mapValues(sumBy('count')),
    compose(object =>
      Object.keys(object).map((key, index) => ({
        day: (index + 6) % 7,
        count: object[key]
      }))
    )
  )(resultSetWeekday?.tablePivot());

  return (
    <Page className={classes.root} title={`Dashboard`}>
      <Container maxWidth={false}>
        <Box mt={3}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Seleccionar Fecha</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DateRange
                editableDateInputs={true}
                onChange={item => {
                  AnalyticsDashboardStore.update(state => {
                    //@ts-ignore
                    state.dateRange = [item.selection];
                  });
                }}
                moveRangeOnFirstSelection={false}
                ranges={dateRange}
                locale={es}
              />
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box mt={3}>
          <Grid container direction="row" spacing={3}>
            <Grid item md={4}>
              <Card>
                <SamplesByTypeTable
                  dateRange={dateRange}
                  dateFormatter={dateFormatter}
                  colors={colors}
                />
              </Card>
            </Grid>
            <Grid item md={8}>
              <Card>
                <Box p={3}>
                  <SamplesByTypeChart
                    dateRange={dateRange}
                    dateFormatter={dateFormatter}
                    colors={colors}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={3}>
            <Grid item md={4}>
              <SamplesByWeekdayTable
                data={dataWeekday}
                isLoading={isLoadingWeekday}
                dateRange={dateRange}
                dateFormatter={dateFormatter}
                colors={colors}
              />
            </Grid>
            <Grid item md={8}>
              <Card>
                <Box p={3}>
                  <SamplesByWeekdayChart
                    data={dataWeekday}
                    isLoading={isLoadingWeekday}
                    dateRange={dateRange}
                    dateFormatter={dateFormatter}
                    colors={colors}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={3}>
            <Grid item md={4}>
              <SamplesByStaffTable
                dateRange={dateRange}
                staff={staffData?.users}
                dateFormatter={dateFormatter}
                colors={colors}
              />
            </Grid>
            <Grid item md={8}>
              <Card>
                <Box p={3}>
                  <SamplesByStaffChart
                    dateRange={dateRange}
                    staff={staffData?.users}
                    dateFormatter={dateFormatter}
                    colors={colors}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={3}>
            <Grid item md={4}>
              {/* <SamplesByAppointmentTable
                dateRange={dateRange}
                dateFormatter={dateFormatter}
                colors={colors}
              /> */}
            </Grid>
            {/* <Grid item md={8}>
              <Card>
                <Box p={3}>
                  <SamplesByWeekdayChart
                    data={dataWeekday}
                    isLoading={isLoadingWeekday}
                    dateRange={dateRange}
                    dateFormatter={dateFormatter}
                    colors={colors}
                  />
                </Box>
              </Card>
            </Grid> */}
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};
