import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import NumberFormat from 'react-number-format';

import Page from 'src/components/Page';
import { useState } from 'react';
import {
  FetchPrestacionesQuery,
  useFetchPrestacionesQuery
} from 'src/generated/graphql';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles({
  table: {
    minWidth: 300
  },
  textField: {
    marginBottom: 20
  }
});

const CotizacionesView = () => {
  const classes = useStyles();

  /* ------------------------------ Graphql Hooks ----------------------------- */
  const { data, loading } = useFetchPrestacionesQuery();

  /* --------------------------- General state hooks -------------------------- */
  const [selectedPrestaciones, setSelectedPrestaciones] = useState<
    FetchPrestacionesQuery['availablePrestaciones']
  >([]);
  const [discount, setDiscount] = useState(0);
  const [isPatientFonasa, setIsPatientFonasa] = useState(false);

  const subtotal = selectedPrestaciones
    .map(p => {
      if (isPatientFonasa && !p.only_particular && p.aporte_benef) {
        return p.aporte_benef;
      }
      return p.valor_particular;
    })
    .reduce((prev, curr) => prev + curr, 0);
  /* -------------------------------------------------------------------------- */
  return (
    <Page title="Cotizar">
      <Container maxWidth={false}>
        <Box mt={3}>
          <Card>
            <CardHeader title="Cotizar" />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={5}>
                  {!loading && data?.availablePrestaciones ? (
                    <Autocomplete
                      multiple
                      className={classes.textField}
                      options={data?.availablePrestaciones}
                      disableCloseOnSelect
                      getOptionLabel={option =>
                        `${option.fonasa_code} | ${option.description}`
                      }
                      groupBy={option => `${option.division}`}
                      value={selectedPrestaciones}
                      onChange={(_e, value) => setSelectedPrestaciones(value)}
                      renderOption={(option, { selected }) => (
                        <>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.fonasa_code} | {option.description}
                        </>
                      )}
                      fullWidth
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Prestaciones"
                          placeholder="Prestaciones"
                        />
                      )}
                    />
                  ) : (
                    <CircularProgress />
                  )}
                  <NumberFormat
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=","
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                    label="Descuento"
                    //@ts-ignore
                    value={discount}
                    onValueChange={v => setDiscount(v.floatValue || 0)}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        value={isPatientFonasa}
                        onChange={e => setIsPatientFonasa(e.target.checked)}
                      />
                    }
                    label="Paciente FONASA"
                  />
                </Grid>

                <Grid item xs={12} md={7}>
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Prestación</TableCell>
                          <TableCell align="right">Codigo FONASA</TableCell>
                          <TableCell align="right">Particular/Isapre</TableCell>
                          <TableCell align="right">Fonasa</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedPrestaciones.map(row => (
                          <TableRow
                            key={row.id}
                            style={{
                              backgroundColor: row.only_particular
                                ? '#23404912'
                                : undefined
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.description}
                            </TableCell>
                            <TableCell align="right">
                              {row.fonasa_code}
                            </TableCell>
                            <TableCell align="right">
                              ${' '}
                              {row.valor_particular.toLocaleString('es-CL', {
                                currency: 'CLP'
                              })}
                            </TableCell>
                            <TableCell align="right">
                              ${' '}
                              {row?.aporte_benef?.toLocaleString('es-CL', {
                                currency: 'CLP'
                              }) || ''}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell rowSpan={5} colSpan={2} />
                          <TableCell align="right">
                            <strong>Subtotal</strong>
                          </TableCell>
                          <TableCell align="right">
                            ${' '}
                            {subtotal.toLocaleString('es-CL', {
                              currency: 'CLP'
                            })}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right">
                            <strong>Descuento</strong>
                          </TableCell>
                          <TableCell align="right">
                            ${' '}
                            {discount.toLocaleString('es-CL', {
                              currency: 'CLP'
                            })}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right">
                            <strong>Total</strong>
                          </TableCell>
                          <TableCell align="right">
                            ${' '}
                            {(subtotal - discount).toLocaleString('es-CL', {
                              currency: 'CLP'
                            })}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  Nota: Filas destacadas en color se cobra siempre valor
                  particular
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default CotizacionesView;
