import {
  Card,
  CardHeader,
  Button,
  Divider,
  CardContent,
  Grid,
  CardActions,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import { Patient, useEmitirBoletaPcrMutation } from 'src/generated/graphql';

interface BoletaEmisionForm {
  amount: number;
  anonymous: boolean;
  include_laboratory: boolean;
  is_domicilio: boolean;
}

export const EmitirBoletaComponent = (props: {
  patient: Patient;
  appointment_id: string;
  product_id: string;
}) => {
  const [
    emitBoleta,
    { loading: emittingBoleta, error: errorEmittingBoleta }
  ] = useEmitirBoletaPcrMutation({
    notifyOnNetworkStatusChange: true,
    refetchQueries: ['fetchIndividualAppointment']
  });

  const { register, handleSubmit, control } = useForm<BoletaEmisionForm>({
    defaultValues: {
      anonymous: false,
      is_domicilio: false
    }
  });

  const onSubmitEmision = handleSubmit(data => {
    if (props.appointment_id && props.patient.id) {
      emitBoleta({
        variables: {
          boletas: [
            {
              amount: data.amount,
              anonymous: data.anonymous,
              appointment_id: props.appointment_id,
              patient_id: props.patient.id,
              include_laboratory: data.include_laboratory,
              product_id: props.product_id,
              is_domicilio: data.is_domicilio
            }
          ]
        }
      });
    }
  });
  return (
    <form>
      <Card>
        <CardHeader
          title="Emitir Boleta"
          subheader="Emitir Boleta SII. Usar boleta anónima en caso de no disponer de RUT del paciente"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Controller
                control={control}
                name="amount"
                rules={{ required: 'Debes introducir un monto' }}
                render={({ field, fieldState: { error } }) => (
                  <NumberFormat
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=","
                    onValueChange={v => {
                      if (v.floatValue) field.onChange(v.floatValue);
                    }}
                    error={!!error}
                    helperText={error ? error.message : null}
                    fullWidth
                    label="Monto"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={12}>
              <Controller
                name="is_domicilio"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} />}
                    label="Domicilio?"
                  />
                )}
              />
            </Grid>
            <Grid item md={12}>
              <Controller
                name="anonymous"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} />}
                    label="Boleta Anónima"
                  />
                )}
              />
            </Grid>
            <Grid item md={12}>
              <Controller
                name="include_laboratory"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} />}
                    label="Incluir laboratorio"
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          {emittingBoleta ? (
            <ScaleLoader />
          ) : errorEmittingBoleta ? (
            errorEmittingBoleta.message
          ) : (
            <Button
              size="medium"
              color="primary"
              variant="outlined"
              onClick={onSubmitEmision}
              type="submit"
            >
              Emitir Boleta
            </Button>
          )}
        </CardActions>
      </Card>
    </form>
  );
};
