import React from 'react';
import { useHistory } from 'react-router';
import { Add, Refresh } from '@material-ui/icons';
import MaterialTable, { Action, Column } from 'material-table';
import { BounceLoader, RingLoader } from 'react-spinners';
import { tableIcons, tableLocalization } from 'src/theme/tableSettings';
import { useFetchPatientsQuery } from 'src/generated/graphql';

export interface IPatientsSelectTableProps {
  previousPatients?: string[];
  finishedCallback?: () => void;
  onAddPatients?: (
    previousPatients: string[],
    selectedPatients: RenderedPatient | RenderedPatient[]
  ) => void;
  onSelectionChange?: (
    selectedPatients: RenderedPatient | RenderedPatient[]
  ) => void;
  addingPatients?: boolean;
}

export interface RenderedPatient {
  id: string;
  comuna: string;
  address: string;
  given_name: string;
  family_name: string;
  dni: string;
  phone: string;
  email: string;
}

export const PatientsSelectTable = (props: IPatientsSelectTableProps) => {
  const history = useHistory();
  const { data, loading, refetch } = useFetchPatientsQuery();

  const patients =
    data?.patients?.map(val => ({
      id: val?.id || '',
      comuna: val?.comuna || '',
      address: val?.address || '',
      given_name: val?.given_name || '',
      family_name: val?.family_name || '',
      institution: val?.institution || '',
      dni: val?.dni || '',
      phone: val?.phone_number || '',
      email: val?.email || ''
    })) || [];

  const columns: Column<RenderedPatient>[] = [
    { title: 'RUT', field: 'dni' },
    { title: 'Nombres', field: 'given_name' },
    { title: 'Apellidos', field: 'family_name' },
    { title: 'Institución', field: 'institution' },
    { title: 'Email', field: 'email' },
    { title: 'Teléfono', field: 'phone' },
    { title: 'ID', field: 'id' }
  ];

  const handleAddPatients = (
    _evt: any,
    selectedPatients: RenderedPatient | RenderedPatient[]
  ) => {
    const previousPatients = [];
    if (props.previousPatients) {
      previousPatients.push(...props.previousPatients);
    }

    props.onAddPatients &&
      props.onAddPatients(previousPatients, selectedPatients);
    props.finishedCallback && props.finishedCallback();
  };

  const actions: Action<RenderedPatient>[] = [
    {
      icon: () => <Refresh />,
      tooltip: 'Refrescar',
      isFreeAction: true,
      onClick: () => refetch && refetch()
    },
    {
      icon: () => <Add />,
      tooltip: 'Nuevo Paciente',
      isFreeAction: true,
      onClick: () => history.push('/app/nuevo-paciente')
    }
  ];

  if (props.onAddPatients) {
    actions.push({
      tooltip: 'Agregar pacientes a cita',
      icon: () => (props.addingPatients ? <BounceLoader /> : <Add />),
      onClick: handleAddPatients
    });
  }
  return (
    <>
      {!loading && patients ? (
        <MaterialTable
          icons={tableIcons}
          localization={tableLocalization}
          options={{
            rowStyle: {
              fontFamily: 'Roboto'
            },
            selection: true
          }}
          actions={actions}
          title="Pacientes"
          columns={columns}
          data={patients}
          onSelectionChange={rows => {
            props.onSelectionChange && props.onSelectionChange(rows);
          }}
        />
      ) : (
        <RingLoader />
      )}
    </>
  );
};
