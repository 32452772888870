import { useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  TextField
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { read as readExcel, utils } from 'xlsx';
import {
  Dni_Type,
  PatientCreateInput,
  useCreatePatientMutation
} from 'src/generated/graphql';
import ReactJson from 'react-json-view';
import { ScaleLoader } from 'react-spinners';

const useStyles = makeStyles(theme => ({
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
  dropzone: {}
}));

interface PatientsSheet {
  'Nombre 1': string;
  'Nombre 2': string;
  'Apellido 1': string;
  'Apellido 2': string;
  RUT: string;
  Email: string;
  'Fecha Nacimiento': string;
  Dirección: string;
  Comuna: string;
  Sexo: string;
  Celular: string;
}

export const BulkUploadPatientsComponent = () => {
  /* ---------------------- General Hooks and Definitions --------------------- */

  const classes = useStyles();
  const [patients, setPatients] = useState<PatientCreateInput[]>([]);
  const [institution, setInstitution] = useState<string>('');

  const convertExcelFiletoJSON = (files: File[]) => {
    var reader = new FileReader();
    reader.onload = function(e) {
      if (e.target?.result as ArrayBuffer) {
        //@ts-ignore
        var data = new Uint8Array(e.target.result);
        var workbook = readExcel(data, { type: 'array' });
        const dataInJSON = utils.sheet_to_json<PatientsSheet>(
          workbook.Sheets[workbook.SheetNames[0]],
          {
            raw: false
          }
        );

        const parsedPatients: PatientCreateInput[] = dataInJSON.map(row => ({
          given_name: `${row['Nombre 1']} ${row['Nombre 2']}`,
          family_name: `${row['Apellido 1']} ${row['Apellido 2']}`,
          email: row.Email,
          dni: row.RUT,
          dni_type: Dni_Type.Rut,
          sex:
            row.Sexo === 'M' ? 'male' : row.Sexo === 'F' ? 'female' : 'other',
          comuna: row.Comuna,
          address: row.Dirección,
          phone_number: row.Celular,
          institution: institution
        }));

        setPatients(parsedPatients);
      }

      /* DO SOMETHING WITH workbook HERE */
    };
    reader.readAsArrayBuffer(files[0]);
  };

  /* ------------------------------ GraphQL Hooks ----------------------------- */

  const [
    createPatients,
    { loading: creatingPatients }
  ] = useCreatePatientMutation({
    notifyOnNetworkStatusChange: true,
    refetchQueries: ['fetchPatients']
  });

  const submitBulkPatientsCreation = () => {
    createPatients({
      variables: {
        patients: patients,
        check_rut_duplicate: true
      }
    });
  };

  /* -------------------------------------------------------------------------- */

  return (
    <>
      <Card>
        <CardHeader
          title="Subida Masiva de Pacientes"
          subheader="Puedes subir un archivo Excel con los datos de pacientes para enviar a la base de datos"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <CardHeader title="Institución" />
              <Card>
                <CardContent>
                  <TextField
                    variant="outlined"
                    label="Institución"
                    value={institution}
                    onChange={e => setInstitution(e.target.value)}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={12}>
              Puedes subir un archivo Excel en el siguiente formato:
              <pre>
                {`| RUT        | Nombre 1 | Nombre 2 | Apellido 1 | Apellido 2 | Fecha Nacimiento | Sexo | Dirección             | Comuna   | Email         | Celular      |
|------------|----------|----------|------------|------------|------------------|------|-----------------------|----------|---------------|--------------|
| 11111111-1 | Elsa     | Dolores  | Pallo      | Delano     | 29/05/1997       | F    | Calle Aillavillu 1030 | Santiago | test@test.com | +56999999999 |
| 11111111-1 | Elvis    | Rosamel  | Tec        | Fierro     | 29/05/1997       | M    | Calle Aillavillu 1030 | Santiago | test@test.com | +56999999999 |
| 11111111-1 | Aquiles  | Benito   | Baeza      | Camelo     | 29/05/1997       | M    | Calle Aillavillu 1030 | Santiago | test@test.com | +56999999999 |`}
              </pre>
            </Grid>
            <Grid item md={6}>
              <DropzoneArea
                dropzoneClass={classes.dropzone}
                filesLimit={1}
                onDrop={convertExcelFiletoJSON}
                acceptedFiles={['.xls', '.xlsx']}
                dropzoneText="Arrastre archivos o haga click para seleccionar"
                getFileLimitExceedMessage={(value: number) =>
                  `Número máximos de archivos excedido (${value})`
                }
                getFileAddedMessage={(fileName: string) =>
                  `${fileName} añadido exitosamente`
                }
                getFileRemovedMessage={(fileName: string) =>
                  `${fileName} removido exitosamente`
                }
                getDropRejectMessage={(rejectedFile: File) =>
                  `${rejectedFile.name} rechazado`
                }
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                // previewGridProps={{
                //   container: {
                //     spacing: 1,
                //     direction: 'row'
                //   }
                // }}
                previewChipProps={{
                  classes: {
                    root: classes.previewChip
                  }
                }}
                previewText="Archivos seleccionados"
              />
            </Grid>

            <Grid item md={6}>
              <Card>
                <CardHeader title="Vista Previa (editable)" />
                <ReactJson
                  src={patients}
                  displayDataTypes={false}
                  onEdit={data =>
                    setPatients(data.updated_src as PatientCreateInput[])
                  }
                />
              </Card>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />

        <CardActions>
          {creatingPatients ? (
            <ScaleLoader />
          ) : (
            <Button
              onClick={submitBulkPatientsCreation}
              variant="outlined"
              color="primary"
            >
              Enviar
            </Button>
          )}
        </CardActions>
      </Card>
    </>
  );
};
