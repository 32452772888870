import React from 'react';
import {
  Card,
  CardHeader,
  Button,
  Divider,
  CardContent,
  ListItemText,
  List,
  Grid,
  CardActions,
  makeStyles,
  IconButton
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import dayjs from 'dayjs';
import { ClipLoader, ScaleLoader } from 'react-spinners';
import { Controller, useForm } from 'react-hook-form';
import { DropzoneArea } from 'material-ui-dropzone';

import { ListItemLink } from '../ListItemLink';
import { LinearProgressWithLabel } from '../LinearProgressWithLabel';

import { Factura, useDeleteFacturaMutation } from 'src/generated/graphql';
import { useUploadFacturas } from 'src/utils/uploadFacturas';

interface FacturaForm extends Factura {
  files: File[];
}

interface IFacturasComponentProps {
  facturas: Factura[];
  appointment_id: string;
}

const useStyles = makeStyles(theme => ({
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  }
}));

export const FacturasComponent = ({
  appointment_id,
  facturas
}: IFacturasComponentProps) => {
  /* ------------------------------ General Hooks ----------------------------- */

  const classes = useStyles();

  /* ------------------------------ GraphQL Hooks ----------------------------- */

  const {
    progress: facturaUploadProgress,
    uploadFiles: uploadFactura,
    uploading: uploadingFactura
  } = useUploadFacturas();

  const [
    deleteFactura,
    { loading: deletingFactura }
  ] = useDeleteFacturaMutation({
    notifyOnNetworkStatusChange: true,
    refetchQueries: ['fetchIndividualAppointment']
  });
  /* ------------------------------- Form Hooks ------------------------------- */

  const { control: facturaControl, handleSubmit } = useForm<FacturaForm>();

  const onSubmitFacturas = handleSubmit(data => {
    if (data.files && appointment_id) {
      uploadFactura(data.files, appointment_id);
    }
  });

  /* -------------------------------------------------------------------------- */

  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Card>
          <CardHeader
            title="Facturas"
            // subheader={`ID paciente: ${patient_id}`}
          />
          <Divider />
          <CardContent>
            {facturas.map((factura, index) => (
              <List
                component="nav"
                aria-label="secondary mailbox folders"
                key={`factura_${factura.id}`}
              >
                <Grid container>
                  <Grid item md={10}>
                    <ListItemLink href={factura?.file_url || ''}>
                      <ListItemText
                        primary={
                          factura?.created_at
                            ? dayjs(factura?.created_at)
                                .tz('America/Santiago')
                                .format('DD/MM/YYYY - HH:mm')
                            : 'Fecha indefinida'
                        }
                      />
                    </ListItemLink>
                  </Grid>
                  <Grid item md={2}>
                    {deletingFactura ? (
                      <ClipLoader />
                    ) : (
                      <IconButton
                        color="primary"
                        onClick={() =>
                          deleteFactura({
                            variables: {
                              id: factura.id
                            }
                          })
                        }
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </List>
            ))}
          </CardContent>
        </Card>
      </Grid>
      {appointment_id && (
        <Grid item md={12}>
          <Card>
            <CardHeader title="Subir nueva Factura" />
            <Divider />
            <CardContent>
              <Controller
                control={facturaControl}
                name="files"
                render={({ field }) => (
                  <DropzoneArea
                    {...field}
                    filesLimit={4}
                    acceptedFiles={['image/*', 'application/pdf']}
                    dropzoneText="Arrastre archivos o haga click para seleccionar"
                    getFileLimitExceedMessage={(value: number) =>
                      `Número máximos de archivos excedido (${value})`
                    }
                    getFileAddedMessage={(fileName: string) =>
                      `${fileName} añadido exitosamente`
                    }
                    getFileRemovedMessage={(fileName: string) =>
                      `${fileName} removido exitosamente`
                    }
                    getDropRejectMessage={(rejectedFile: File) =>
                      `${rejectedFile.name} rechazado`
                    }
                    showPreviews={true}
                    showPreviewsInDropzone={false}
                    useChipsForPreview
                    previewGridProps={{
                      container: {
                        spacing: 1,
                        direction: 'row'
                      }
                    }}
                    previewChipProps={{
                      classes: {
                        root: classes.previewChip
                      }
                    }}
                    previewText="Archivos seleccionados"
                  />
                )}
              />
              <LinearProgressWithLabel value={facturaUploadProgress} />
            </CardContent>
            <CardActions>
              {uploadingFactura ? (
                <ScaleLoader />
              ) : (
                <Button
                  size="medium"
                  color="primary"
                  variant="outlined"
                  onClick={onSubmitFacturas}
                >
                  Subir archivos
                </Button>
              )}
            </CardActions>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};
