import {
  Card,
  CardHeader,
  Button,
  Divider,
  CardContent,
  ListItemText,
  List,
  Grid,
  IconButton
} from '@material-ui/core';
import { Delete, Send } from '@material-ui/icons';
import dayjs from 'dayjs';
import { ScaleLoader } from 'react-spinners';
import { ListItemLink } from '../ListItemLink';

import {
  Result,
  useDeleteResultMutation,
  useEmailResultsMutation
} from 'src/generated/graphql';

interface IResultsComponentProps {
  patient_id: string;
  results: Result[];
  appointment_id?: string;
}

export const ResultsComponent = ({
  patient_id,
  appointment_id,
  results
}: IResultsComponentProps) => {
  /* ------------------------------ General Hooks ----------------------------- */

  /* ------------------------------ Storage Hooks ----------------------------- */
  // const firebase = useFirebaseApp();
  // const storage = firebase.storage();
  // const [downloadUrls, setDownloadUrls] = useState<string[]>([]);
  // results.forEach(async result => {
  //   const storageRef = storage.refFromURL(result.file_url);
  //   const url = await storageRef.getDownloadURL();
  //   setDownloadUrls([...downloadUrls, url || '']);
  // });
  /* ------------------------------ GraphQL Hooks ----------------------------- */

  const [
    emailResults,
    { loading: emailingResults }
  ] = useEmailResultsMutation();

  const [deleteResult, { loading: deletingResult }] = useDeleteResultMutation({
    refetchQueries: ['fetchIndividualAppointment', 'fetchIndividualPatient']
  });

  /* -------------------------------------------------------------------------- */

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <Card>
          <CardHeader
            title="Resultados (nota: se envían automáticamente al subir resultados)"
            subheader={`ID paciente: ${patient_id}`}
            action={
              appointment_id &&
              (emailingResults ? (
                <ScaleLoader />
              ) : (
                <Button
                  size="medium"
                  color="primary"
                  variant="outlined"
                  onClick={() =>
                    emailResults({
                      variables: {
                        appointment_id: appointment_id,
                        patient_id: patient_id
                      }
                    })
                  }
                >
                  <Send />
                  Re-enviar resultados por email
                </Button>
              ))
            }
          />
          <Divider />
          <CardContent>
            {results.map((result, index) => (
              <List
                component="nav"
                aria-label="secondary mailbox folders"
                key={`results_patient_${index}`}
              >
                <Grid container>
                  <Grid item xs={10}>
                    <ListItemLink href={result?.file_url || ''}>
                      {/* <ListItemLink href={downloadUrls[index]}> */}
                      <ListItemText
                        primary={
                          result?.created_at
                            ? dayjs(result?.created_at)
                                .tz('America/Santiago')
                                .format('DD/MM/YYYY - HH:mm')
                            : 'Fecha indefinida'
                        }
                      />
                    </ListItemLink>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      color="secondary"
                      onClick={() =>
                        deleteResult({
                          variables: { id: result.id }
                        })
                      }
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              </List>
            ))}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
