import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { Suspense } from 'react';
import { ThemeProvider } from '@material-ui/core';
import { AuthCheck } from 'reactfire';
import Routes from './Routes';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import firebase from 'firebase';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import es from 'dayjs/locale/es';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import GlobalStyles from './components/GlobalStyles';
import './mixins/chartjs';
import theme from './theme';
import LoginView from './views/auth/LoginView';
import MainLayout from './layouts/MainLayout';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Santiago');

const httpLink = createHttpLink({
  uri:
    process.env.NODE_ENV === 'development'
      ? 'http://127.0.0.1:5001/midiagnostico-cl/southamerica-east1/api/graphql'
      : 'https://southamerica-east1-midiagnostico-cl.cloudfunctions.net/api/graphql'
});

const authLink = setContext(async (_, { headers }) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

const CUBEJS_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4000'
    : 'https://resultados.midiagnostico.cl';
const cubejsApi = cubejs(
  async () => {
    const token = await firebase.auth().currentUser?.getIdToken();
    return token || '';
  },
  {
    apiUrl: `${CUBEJS_URL}/cubejs-api/v1`
  }
);

const App = () => {
  // const routing = useRoutes(routes);
  // const user = useUser();
  // console.log(user);
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <CubeProvider cubejsApi={cubejsApi}>
          <MuiPickersUtilsProvider utils={DayjsUtils} locale={es}>
            <Suspense fallback="loading status...">
              <AuthCheck
                fallback={
                  <MainLayout>
                    <LoginView />
                  </MainLayout>
                }
              >
                <GlobalStyles />
                <Routes />
              </AuthCheck>
            </Suspense>
          </MuiPickersUtilsProvider>
        </CubeProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default App;
