import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  TextField
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useUser } from 'reactfire';
import {
  FetchStaffQuery,
  Laboratories,
  SampleInput,
  useFetchProductsQuery,
  useSubmitSampleMutation
} from 'src/generated/graphql';
import { Autocomplete } from '@material-ui/lab';

interface IBulkSample {
  number_of_samples: string;
  samples: SampleInput[];
}
interface IBulkResultsDialogueProps {
  open: boolean;
  handleClose(): void;
  appointment_id: string;
  patients: any[];
  staff: FetchStaffQuery;
  userRole?: string;
  refetch?(): void;
}

const BulkSampleDialogue = (props: IBulkResultsDialogueProps) => {
  /* ------------------------------- Setup Hooks ------------------------------ */
  const { data: user } = useUser();
  const patientOptions = props.patients.map(patient => ({
    id: patient.id,
    name: `${patient.given_name} ${patient.family_name}`
  }));

  /* ------------------------------ GraphQL Hooks ----------------------------- */

  const [submitSamples] = useSubmitSampleMutation();
  const {
    data: availableProducts,
    loading: loadingProducts
    // refetch: refetchProducts
  } = useFetchProductsQuery({
    notifyOnNetworkStatusChange: true
  });

  /* ------------------------------- Form Hooks ------------------------------- */
  const { handleSubmit, watch, control, reset: resetForm } = useForm<
    IBulkSample
  >({
    defaultValues: {
      number_of_samples: '1'
    }
  });

  const onSubmitBulkSamples = handleSubmit(data => {
    data.samples.forEach(sample => {
      submitSamples({
        variables: {
          sample: {
            ...sample,
            appointment_id: props.appointment_id
          }
        }
      });
    });

    resetForm();
    props.handleClose();
    props.refetch && props.refetch();
  });

  const watchNumberOfSamples = watch('number_of_samples');
  const sampleNumbers = () => [
    ...Array(parseInt(watchNumberOfSamples || '0')).keys()
  ];

  /* -------------------------------------------------------------------------- */
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="lg"
      PaperProps={{
        style: {
          backgroundColor: 'whitesmoke'
        }
      }}
    >
      <DialogTitle id="form-dialog-title">Agregar muestras</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Aquí puedes agregar muestras a la cita
        </DialogContentText>
        <Grid container spacing={3}>
          <Grid item md={4}>
            <Controller
              control={control}
              name="number_of_samples"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  label="Muestras"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  {...field}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          {sampleNumbers().map(i => {
            return (
              <Grid item md={12}>
                <Card>
                  <CardHeader title={`Muestra ${i + 1}`} />
                  <CardContent>
                    <Grid
                      container
                      item
                      md={12}
                      key={`sample_${i}`}
                      spacing={3}
                    >
                      <Grid item md={6}>
                        <Controller
                          control={control}
                          //@ts-ignore
                          name={`samples.${i}.patient_id`}
                          rules={{
                            required: 'Debes seleccionar un paciente'
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <TextField
                              fullWidth
                              select
                              label="Paciente"
                              variant="outlined"
                              margin="normal"
                              {...field}
                              error={!!error}
                              helperText={error ? error.message : null}
                            >
                              {patientOptions.map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <Controller
                          control={control}
                          name={`samples.${i}.product`}
                          render={({ field: { value, onChange } }) => (
                            <Autocomplete
                              options={
                                availableProducts?.availableProducts.map(p => ({
                                  id: p.id,
                                  description: p.description
                                })) || []
                              }
                              getOptionLabel={option => option.description}
                              value={
                                availableProducts?.availableProducts
                                  .map(p => ({
                                    id: p.id,
                                    description: p.description
                                  }))
                                  .filter(p => p.id === value)[0]
                              }
                              onChange={(_event, newValue) => {
                                onChange(newValue?.id);
                              }}
                              loading={loadingProducts}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="Producto"
                                  margin="normal"
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {loadingProducts ? (
                                          <CircularProgress
                                            color="inherit"
                                            size={20}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </>
                                    )
                                  }}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      {/* <Grid item md={3}>
                        <Controller
                          control={control}
                          //@ts-ignore
                          name={`samples.${i}.sample_type`}
                          rules={{
                            required: 'Debes seleccionar un tipo de muestra'
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <TextField
                              fullWidth
                              select
                              label="Tipo de Muestra"
                              variant="outlined"
                              margin="normal"
                              {...field}
                              error={!!error}
                              helperText={error ? error.message : null}
                            >
                              {[
                                {
                                  value: Sampletypes.Pcr,
                                  label: 'PCR'
                                },
                                {
                                  value: Sampletypes.Antigen,
                                  label: 'Antígeno'
                                }
                              ].map(option => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>

                      <Grid item md={3}>
                        <Controller
                          control={control}
                          //@ts-ignore
                          name={`samples.${i}.result`}
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              select
                              variant="outlined"
                              margin="normal"
                              label="Resultado"
                              disabled={sampleType !== Sampletypes.Antigen}
                              {...field}
                            >
                              {[
                                {
                                  value: 'Positivo',
                                  label: 'Positivo'
                                },
                                { value: 'Negativo', label: 'Negativo' }
                              ].map(option => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid> */}

                      {[
                        { name: 'epivigila_code', label: 'Epivigila' },
                        { name: 'sample_code', label: 'Código de Muestra' }
                      ].map(data => (
                        <Grid key={`samples[${i}].${data.name}`} item md={3}>
                          <Controller
                            control={control}
                            //@ts-ignore
                            name={`samples.${i}.${data.name}`}
                            render={({ field }) => (
                              <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                label={data.label}
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      ))}
                      <Grid item md={3}>
                        <Controller
                          control={control}
                          //@ts-ignore
                          name={`samples.${i}.laboratory`}
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              select
                              variant="outlined"
                              margin="normal"
                              label="Laboratorio"
                              {...field}
                            >
                              {[
                                {
                                  value: Laboratories.UChile,
                                  label: 'Hospital Clínico U de Chile'
                                },
                                { value: Laboratories.Irp, label: 'IRP' },
                                { value: Laboratories.Redlab, label: 'Redlab' }
                              ].map(option => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <Controller
                          control={control}
                          //@ts-ignore
                          name={`samples.${i}.staff_email`}
                          //@ts-ignore
                          defaultValue={user?.email}
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              select
                              variant="outlined"
                              margin="normal"
                              label="Staff"
                              disabled={props.userRole !== 'admin'}
                              {...field}
                            >
                              {props.staff.users
                                .map(staffer => ({
                                  value: staffer.email,
                                  label: `${staffer.given_name} ${staffer.family_name}`
                                }))
                                .map(option => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmitBulkSamples} color="primary">
          Enviar
        </Button>
        <Button
          onClick={() => {
            resetForm();
            props.handleClose();
          }}
          color="primary"
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkSampleDialogue;
